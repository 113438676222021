import React, { useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import 'select2/dist/css/select2.min.css';
import 'select2';
import { $ } from 'react-jquery-plugin';

const TeamsManager = () => {
    const [seriesList, setseriesList] = useState();
    const [teamsList, setteamsList] = useState([]);
    const [shortName, setshortName] = useState();
    const [logo, setlogo] = useState();
    const [cid, setcid] = useState();
    const [tid, settid] = useState();

    useEffect(() => {
        GetSeriesList();
    }, []);

    const GetSeriesList = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.GetSeriesList().then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setseriesList(result?.data);
                    GetTeamsList(result?.data[0]?.cid);
                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const GetTeamsList = async (id) => {
        setcid(id)
        LoaderHelper.loaderStatus(true);
        await AuthService.GetTeamsList(id).then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setteamsList(result?.data?.[0]?.teams)
                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const Teamdata = async (cid, tid, shortName, logo) => {
        LoaderHelper.loaderStatus(true);
        var formData = new FormData();
        formData.append('cid', cid);
        formData.append('tid', tid);
        formData.append('abbr', shortName);
        formData.append('logo_url', logo);
        await AuthService.Teamdata(formData).then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage('Edited Successfully')
                    GetTeamsList(cid)
                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const handleEdit = (data) => {
        settid(data?.tid)
        setshortName(data?.abbr)
    }

    /* Custom Pagination */
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };
    const pageCount = Math.ceil(teamsList?.length / itemsPerPage);
    const currentItems = teamsList?.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );


    // ****** Onchange Function For Selectbox Dropdown *********//
    const selectRef = useRef(null);
    useEffect(() => {
        $(selectRef.current).select2();
        $(selectRef.current).on('select2:select', (e) => {
            const selectedValue = e.params.data.id;
            GetTeamsList(selectedValue);
        });
        return () => {
            $(selectRef.current).off('select2:select');
        };
    }, []);

    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-football-line"></i></div>
                                            Teams List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className=" filter_bar mb-4 mb-md-5">
                                    <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form" action="/admin/users/referraldata">
                                        <div className="row gx-2 align-items-center">
                                            {/* <div  className="col-4">
                                        
                                    </div> */}
                                            <div className="form-group  mb-0 col-4">
                                                <div className="input text">

                                                    <select ref={selectRef} name="series_id" className="form-control form-select series" id="series-id" >
                                                        {seriesList ?
                                                            <>
                                                                {seriesList?.map((data) => {
                                                                    return (
                                                                        <option value={data?.cid}>{data?.SeriesName}</option>
                                                                    )
                                                                })} </> : ''}

                                                    </select>

                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>


                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>    Team Name</th>
                                            <th>Team Short Name</th>
                                            <th>Flag </th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Team Name</th>
                                            <th>Team Short Name</th>
                                            <th>Flag </th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {currentItems ? currentItems?.map((data, index) => {
                                            let objIndex;
                                            objIndex = teamsList.findIndex(check => check?._id == data._id);
                                            return (
                                                <tr>
                                                    <td>{objIndex + 1}</td>
                                                    <td>{data?.title}</td>
                                                    <td>{data?.abbr}</td>
                                                    <td className="text-center"> <img src={`${data?.logo_url}`} hight="150px" width="60px" alt="" /> </td>
                                                    <td>
                                                        <button className="btn btn-datatable btn-icon btn-transparent-dark me-1 p-4 " title="Edit User" data-bs-toggle="modal" data-bs-target="#editAdmin" onClick={() => handleEdit(data)}> <i className="fi-xl ri-edit-box-line" ></i>
                                                        </button>
                                                        {/* <button className="btn btn-datatable btn-icon btn-transparent-dark p-3"  title="View User"><i className="ri-eye-line"></i></button> */}
                                                        {/* <button className="btn btn-datatable btn-icon btn-transparent-dark p-3"  title="Delete"><i className="ri-delete-bin-line"></i></button> */}
                                                    </td>
                                                </tr>
                                            )

                                        }) : ''}

                                    </tbody>
                                </table>
                                {/* custom pagination */}
                                <div className="btn-group btn-group-mini justify-content-end " role="group" aria-label="Basic radio toggle button group">
                                <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    containerClassName={'customPagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                            </div>
                        </div>
                    </div>




                </main>
            </div>

            <div className="modal" id="editAdmin" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">Edit Details</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">

                            <form>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Short Name </label>
                                    <input type="text" name="first_name" maxLength="30" className="form-control" placeholder="Short Name" id="first-name"
                                        value={shortName}
                                        onChange={(e) => { setshortName(e.target.value) }}
                                    />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Logo</label>
                                    <input type="file" name="logo" className="form-control"
                                        onChange={(e) => { setlogo(e.target.files[0]) }}
                                    />
                                </div>



                                <div className="form-group  position-relative mt-4">
                                    <button data-bs-dismiss="modal" className="btn btn-primary   btn-block w-100" type="button" onClick={() => { Teamdata(cid, tid, shortName, logo) }} >Submit Details</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamsManager
