import React, { useEffect, useState } from 'react'
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import AuthService from '../../api/services/HomeService';
import Moment from 'react-moment';
import { ApiConfig } from '../../api/apiConfig/ApiConfig';

const BankRequest = () => {
    const [bank, setbank] = useState();
    const [bank2, setbank2] = useState();

    const [accNumber, setaccNumber] = useState();
    const [ifsc, setifsc] = useState();
    const [branch, setbranch] = useState();
    const [bankName, setbankName] = useState();
    const [bankImage, setbankImage] = useState();
    const [bankImage2, setbankImage2] = useState();
    const [bankStatus, setbankStatus] = useState();
    const [bankId, setbankId] = useState();
    const [userId, setuserId] = useState("");

    
console.log('bankData', bank)

    function searchFilter(userInput) {
        const keysToSearch = ["benificiary_name", "branch_name", "user_id", "ifsc", "account_number","bank_name"];
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = bank2.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setbank(matchingObjects);
    };


    useEffect(() => {
        listBank()
    }, []);
    const listBank = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.listBank().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setbank(result?.data)
                    setbank2(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    // console.log('bankUsers', bank?.users)

    // console.log('bank', bank)
    // console.log('bankBranch', bank.branch_name)

    const handleBankImput = (e) => {
        switch (e.target.name) {
            case "bankNumber":
                setaccNumber(e.target.value);
                break;
            case "bankCode":
                setifsc(e.target.value);
                break;
            case "branch":
                setbranch(e.target.value);
                break;
            case "bakName":
                setbankName(e.target.value);
                break;
            case "bankImage":
                setbankImage(e.target.files[0]);
                setbankImage2(URL.createObjectURL(e.target.files[0]));
                break;

            default:
                break;
        }
    };


    const Viewbankdetails = async (id) => {
        setuserId(id);
        setaccNumber("");
        setbankName("");
        setbranch("");
        setifsc("");
        setbankStatus("");
        setbankImage();
        setbankImage2();
        LoaderHelper.loaderStatus(true)
        await AuthService.Viewbankdetails(id).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false)
                try {
                    setaccNumber(result?.data[0]?.AccountNumber);
                    setbankName(result?.data[0]?.BankName);
                    setbranch(result?.data[0]?.Branch);
                    setifsc(result?.data[0]?.ifsc);
                    setbankStatus(result?.data[0]?.bankverified);
                    setbankImage(result?.data[0]?.Bankimagepath);
                    setbankId(result?.data[0]?._id);
                } catch { }
            } else {
                LoaderHelper.loaderStatus(false)
            }
        });
    };

    const verifyBank = async (id, status, userid) => {
        await AuthService.verifyBank(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Bank Status Updated Successfully!!");
                    listBank()

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                // alertErrorMessage(result.message);
            }
        });
    };

    const editbnkdtl = async (accNumber, ifsc, branch, bankName, bankImage) => {
        var formData = new FormData();
        formData.append("_id", bankId);
        formData.append("UserId", userId);
        formData.append("AccountNumber", accNumber);
        formData.append("ifsc", ifsc);
        formData.append("Branch", branch);
        formData.append("BankName", bankName);
        formData.append("Bankimagepath", bankImage);
        formData.append("bankverified", '0');

        await AuthService.editbnkdtl(formData).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Bank Details Updated Successfully!!');
                    Viewbankdetails(userId)
                } catch {
                    alertErrorMessage(result?.message);
                }
            } else {
                alertErrorMessage(result?.message);
                console.log("Something went wrong");
            }
        });
    };
    const dltbankdetails = async (id) => {
        await AuthService.dltbankdetails(id).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Bank Account Deleted Successfully!!");
                    setaccNumber("");
                    setifsc("");
                    setbranch("");
                    setbankName("");
                    setbankImage();
                    setbankStatus('');
                    listBank()

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                // alertErrorMessage(result.message);
            }
        });
    };


    const filter = (e) => {
        if (e.target.checked) {
            const filtered = bank2?.filter(data => data?.users.bank_verified === 2);
            setbank(filtered);
        } else {
            setbank(bank2);
        }
    };


    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-group-line"></i></div>
                                            Bank Request List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">

                        <div className="card mb-4">

                            <div className="card-body">
                                <div className=" filter_bar mb-4 mb-md-5">
                                    <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form" action="/admin/users/referraldata">
                                        <div className="row gx-2 align-items-center">
                                            <div className="input text">
                                                <input type="tel" name="phone" className="form-control mb-3" placeholder="Search name, bank, branch, ifsc, username, user id"
                                                    id="search" onChange={(e) => searchFilter(e.target.value)} />
                                            </div>
                                            <div className="form-group mb-0 col-sm-6 col-md-3">
                                                <div className=" form-check ms-2  " >
                                                    <input className="form-check-input" type="checkbox" id="unverified" onChange={filter} />
                                                    <label className="form-check-label" for="unverified">
                                                        Show Unverified Users
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>


                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>User Id</th>
                                            <th>Name</th>
                                            <th>Bank</th>
                                            <th>Branch</th>
                                            <th>Account Number</th>
                                            <th>Ifsc</th>
                                            <th>Reason</th>
                                            <th>Status</th>
                                            {/* <th>Actions</th> */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {bank ? bank?.map((data, index) => {

                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{data?.user_id}</td>
                                                    <td>{data?.benificiary_name}</td>
                                                    <td>{data?.bank_name}</td>
                                                    <td>{data?.branch_name}</td>
                                                    <td>{data?.account_number}</td>
                                                    <td>{data?.ifsc}</td>
                                                    <td>{data?.reason}</td>
                                                    <td>{data?.users.bank_verified == 2 ?
                                                        <button type="btn" className="btn badge bg-success text-white rounded-pill"
                                                            onClick={() => { verifyBank(data?._id, '1', data?.user_id) }}
                                                        >Approve</button> : <p className='text-success text-center'> Approved</p>

                                                    }</td>

                                                    {/* <td>
                                                        <div className="table_action_group">
                                                            <button type="btn" className="btn badge bg-success text-white rounded-pill"

                                                                data-bs-toggle="modal"
                                                                data-bs-target="#editBank"
                                                                onClick={() => {
                                                                    Viewbankdetails(data?.data?._id);
                                                                }}>Edit / View</button>

                                                            <button type="button" className="btn badge bg-danger text-white rounded-pill"
                                                                onClick={() => {
                                                                    dltbankdetails(data?.data?._id);
                                                                }}>Delete</button>
                                                        </div>
                                                    </td> */}
                                                </tr>
                                            )


                                        }) : ''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>




                </main>
            </div>
            {/* Edit Bank Details */}

            <div
                className="modal"
                id="editBank"
                tabIndex="-1"
                aria-labelledby="editwallrtlmodalTitle"
                style={{ display: "none" }}
                aria-hidden="true"
            >
                <div
                    className="modal-dialog  alert_modal modal-dialog-centered"
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">
                                Bank Details
                            </h5>
                            <button
                                className="btn-close"
                                type="button"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                            ></button>
                            <hr />
                        </div>
                        <div className="modal-body">
                            <form>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Account Number </label>
                                    <input
                                        type="number"
                                        name="bankNumber"
                                        maxLength="30"
                                        className="form-control"
                                        id="first-name"
                                        value={accNumber}
                                        onChange={(e) => handleBankImput(e)}
                                    />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> IFSC Code </label>
                                    <input
                                        type="text"
                                        name="bankCode"
                                        maxLength="30"
                                        className="form-control"
                                        id="first-name"
                                        value={ifsc}
                                        onChange={(e) => handleBankImput(e)}
                                    />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Branch </label>
                                    <input
                                        type="text"
                                        name="branch"
                                        maxLength="30"
                                        className="form-control"
                                        id="first-name"
                                        value={branch}
                                        onChange={(e) => handleBankImput(e)}
                                    />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Bank Name </label>
                                    <input
                                        type="text"
                                        name="bakName"
                                        maxLength="30"
                                        className="form-control"
                                        id="first-name"
                                        value={bankName}
                                        onChange={(e) => handleBankImput(e)}
                                    />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <div className="vs_team" style={{ alignItems: 'center' }}>
                                        <a href={ApiConfig?.appUrl + '/uploads/' + bankImage} target='_blank'>
                                            <img
                                                src={bankImage2 ? bankImage2 : `${ApiConfig?.appUrl + '/uploads/' + bankImage}`} width="50"
                                                height="50"
                                                alt=""
                                            /></a>

                                        <label className="small mb-1"></label>
                                        <input
                                            type="file"
                                            name="bankImage"
                                            maxLength="30"
                                            className="form-control"
                                            id="first-name"
                                            onChange={(e) => handleBankImput(e)}
                                        />
                                    </div>
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Status </label>
                                    <input
                                        type="text"
                                        name="bankStatus"
                                        maxLength="30"
                                        className="form-control"
                                        id="first-name"
                                        disabled
                                        value={bankStatus === 1 ? 'Active' : bankStatus === 0 ? 'Inactive' : ''}
                                    />
                                </div>
                                <div className="form-group  position-relative mt-4">
                                    <button
                                        className="btn btn-primary   btn-block w-100"
                                        type="button"
                                        onClick={() => {
                                            editbnkdtl(accNumber, ifsc, branch, bankName, bankImage);
                                        }}
                                    >
                                        Edit Details

                                    </button>


                                </div>
                                <button
                                    className="btn btn-Danger btn-block w-100"
                                    type="button"
                                    onClick={() => {
                                        dltbankdetails(userId);
                                    }}
                                >
                                    Delete
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BankRequest
