import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import AuthService from "../../api/services/HomeService";
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage, } from "../../Components/CustomAlertMessage";
import LoaderHelper from "../../Components/Loading/LoaderHelper";
import ReactPaginate from 'react-paginate';
import { ApiConfig } from "../../api/apiConfig/ApiConfig";
import { $ } from "react-jquery-plugin";
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import Papa from 'papaparse';
import 'jspdf-autotable';

const UsersList = () => {
  const [userList, setuserList] = useState([]);
  const [userList2, setuserList2] = useState([]);
  const [fName, setfName] = useState("");
  const [lName, setlName] = useState("");
  const [email, setemail] = useState("");
  const [number, setnumber] = useState("");
  const [userName, setuserName] = useState("");
  const [userId, setuserId] = useState("");

  const [panNumber, setpanNumber] = useState("");
  const [nameOnPan, setnameOnPan] = useState("");
  const [panDob, setpanDob] = useState("");
  const [panImage, setpanImage] = useState();
  const [panImage2, setpanImage2] = useState();
  const [panState, setpanState] = useState("");
  const [panStatus, setpanStatus] = useState("");
  const [panID, setpanID] = useState('');
  const [disablePan, setdisablePan] = useState(true);

  const [accNumber, setaccNumber] = useState('');
  const [ifsc, setifsc] = useState('');
  const [branch, setbranch] = useState('');
  const [bankName, setbankName] = useState('');
  const [bankImage, setbankImage] = useState();
  const [bankImage2, setbankImage2] = useState();
  const [bankStatus, setbankStatus] = useState('');
  const [bankId, setbankId] = useState('');

  const [transType, setTransType] = useState("Add");
  const [amount, setAmount] = useState("");


  useEffect(() => {
    UserList();
  }, []);


  console.log('userLISt', userList);
  console.log('userID', userId);
  console.log('bankStatus', bankStatus);
  console.log('bankId', bankId);

  const handlePanInput = (e) => {
    switch (e.target.name) {
      case "panNumber":
        setpanNumber(e.target.value);
        break;
      case "panState":
        setpanState(e.target.value);
        break;
      case "panImage":
        setpanImage(e.target.files[0]);
        setpanImage2(URL.createObjectURL(e.target.files[0]));
        break;
      case "panDob":
        setpanDob(e.target.value);
        break;
      case "panName":
        setnameOnPan(e.target.value);
        break;

      default:
        break;
    }
  };
  const handleBankImput = (e) => {
    switch (e.target.name) {
      case "bankNumber":
        setaccNumber(e.target.value);
        break;
      case "bankCode":
        setifsc(e.target.value);
        break;
      case "branch":
        setbranch(e.target.value);
        break;
      case "bakName":
        setbankName(e.target.value);
        break;
      case "bankImage":
        setbankImage(e.target.files[0]);
        setbankImage2(URL.createObjectURL(e.target.files[0]));
        break;

      default:
        break;
    }
  };

  const UserList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.UserList().then((result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setuserList(result?.data?.reverse());
          setuserList2(result?.data);
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage(result.message);
      }
    });
  };

  const UserStatus = async (status, id) => {
    await AuthService.UserStatus(status, id).then((result) => {
      if (result?.success) {
        try {
          UserList();
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const editUser = async (userId, fName, lName, email, userName) => {
    await AuthService.editUser(userId, fName, lName, email, userName).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage('User Data Updated Successfully!!')
          UserList();
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const handleEditDetails = (data) => {
    setuserId('');
    setfName('');
    setlName('');
    setemail('');
    setnumber('');
    setuserName('');
    addEditDetails(data);

  };
  const addEditDetails = (data) => {
    setuserId(data?._id);
    setfName(data?.first_name);
    setlName(data?.last_name);
    setemail(data?.email_or_phone);
    setnumber(data?.mobile_number);
    setuserName(data?.username);
  }

  const AddBankacc = async (accNumber, ifsc, branch, bankName, bankImage) => {
    var formData = new FormData();
    formData.append("UserId", userId);
    formData.append("AccountNumber", accNumber);
    formData.append("ifsc", ifsc);
    formData.append("Branch", branch);
    formData.append("BankName", bankName);
    formData.append("Bankimagepath", bankImage);
    formData.append("bankverified", '1');
    if (accNumber && ifsc && branch && bankName && bankImage) {
      LoaderHelper.loaderStatus(true)
      await AuthService.AddBankacc(formData).then((result) => {
        if (result?.success) {
          LoaderHelper.loaderStatus(false)
          try {
            Viewbankdetails(userId)
            alertSuccessMessage('Bank Details Added Successfully!!');

          } catch {
            alertErrorMessage(result?.message);
          }
        } else {
          LoaderHelper.loaderStatus(false)
          alertErrorMessage(result?.message);
        }
      })
    }
    else {
      alertWarningMessage('Please fill all required details')
    }
  };


  const handleBonusAmmount = async () => {
    await AuthService.setBonus(amount, transType, userId).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage('Winning Amount updated Successfully!!')
          setuserList({})
          setAmount("")
          setTransType("Add")
          UserList();
          $('#editbonusModal').modal('hide');
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });

  };

  const AddPan = async (panNumber, nameOnPan, panDob, panImage, panState) => {
    var formData = new FormData();
    formData.append("UserId", userId);
    formData.append("PanNumber", panNumber);
    formData.append("NameOnPan", nameOnPan);
    formData.append("dob", panDob);
    formData.append("Panimagepath", panImage);
    formData.append("State", panState);
    formData.append("panverified", '1');
    if (panNumber && nameOnPan && panDob && panImage && panState) {
      await AuthService.AddPan(formData).then((result) => {
        if (result?.success) {
          try {
            ViewPANdetails(userId)
            alertSuccessMessage('Pan Details Added Successfully!!');

          } catch {
            alertErrorMessage(result?.message);
          }
        } else {
          alertErrorMessage(result?.message);
        }
      })
    }
    else {
      alertWarningMessage('Please fill all required fileds')
    }
  };
  const editPANdtl = async (panNumber, nameOnPan, panDob, panImage, panState) => {
    var formData = new FormData();
    formData.append("_id", panID);
    formData.append("UserId", userId);
    formData.append("PanNumber", panNumber);
    formData.append("NameOnPan", nameOnPan);
    formData.append("dob", panDob);
    formData.append("Panimagepath", panImage);
    formData.append("State", panState);
    await AuthService.editPANdtl(formData).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage('Pan Details Updated Successfully!!');
          ViewPANdetails(userId)
        } catch {
          alertErrorMessage(result?.message);
        }
      } else {
        alertErrorMessage(result?.message);
      }
    });
  };
  const editbnkdtl = async (accNumber, ifsc, branch, bankName, bankImage) => {
    var formData = new FormData();
    formData.append("_id", bankId);
    formData.append("UserId", userId);
    formData.append("AccountNumber", accNumber);
    formData.append("ifsc", ifsc);
    formData.append("Branch", branch);
    formData.append("BankName", bankName);
    formData.append("Bankimagepath", bankImage);
    formData.append("bankverified", '0');

    await AuthService.editbnkdtl(formData).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage('Bank Details Updated Successfully!!');
          Viewbankdetails(userId)
        } catch {
          alertErrorMessage(result?.message);
        }
      } else {
        alertErrorMessage(result?.message);
      }
    });
  };

  const ViewPANdetails = async (id) => {
    setdisablePan(true)
    setuserId(id);
    setpanNumber('')
    setnameOnPan('')
    setpanState('')
    setpanDob('')
    setpanStatus('')
    setpanImage()
    setpanImage2()

    LoaderHelper.loaderStatus(true)
    await AuthService.ViewPANdetails(id).then((result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false)
        try {
          if (result?.data?.length > 0) {
            setpanNumber(result?.data?.[0]?.pan_number)
            setnameOnPan(result?.data?.[0]?.NameOnPan)
            setpanState(result?.data?.[0]?.State)
            setpanDob(result?.data?.[0]?.dob)
            setpanStatus(result?.data?.[0]?.pan_verified)
            setpanImage(result?.data?.[0]?.Panimagepath)
            setpanID(result?.data?.[0]?._id)
            $('#editPancard').modal('show');
          } else {
            alertWarningMessage('Pan Details not found')
          }
        } catch {
          LoaderHelper.loaderStatus(false)
          alertErrorMessage(result.message);
        }
      } else {
      }
    });
  };

  const Viewbankdetails = async (id) => {
    LoaderHelper.loaderStatus(true)
    await AuthService.Viewbankdetails(id).then((result) => {
      if (result?.success) {
        LoaderHelper.loaderStatus(false)
        try {
          let filteredData = result?.data?.filter((item) => item?.user_id === id)[0]
          if (filteredData) {
            setuserId(id);
            setaccNumber(filteredData?.account_number);
            setbankName(filteredData?.bank_name);
            setbranch(filteredData?.branch_name);
            setifsc(filteredData?.ifsc);
            setbankStatus(filteredData?.bank_verified);
            setbankImage(filteredData?.Bankimagepath);
            setbankId(filteredData?._id);
            $('#editBank').modal('show');
          }
          else {
            alertWarningMessage('Bank Details not found')
          }

        } catch { }
      } else {
        LoaderHelper.loaderStatus(false)
      }
    });
  };

  const dltbankdetails = async (id) => {
    await AuthService.dltbankdetails(id).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage("Bank Account Deleted Successfully!!");
          setaccNumber("");
          setifsc("");
          setbranch("");
          setbankName("");
          setbankImage();
          setbankStatus('');
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };
  const dltPandetails = async (id) => {
    await AuthService.dltPandetails(id).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage("Pan Deleted Successfully!!");
          setpanNumber("");
          setnameOnPan("");
          setpanDob("");
          setpanState("");
          setpanImage();
          setpanStatus();
        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };


  /* custom pagination */
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };
  const pageCount = Math.ceil(userList.length / itemsPerPage);

  const safeUserList = Array.isArray(userList) ? userList : [];
  const currentItems = safeUserList.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  // custom paginatio ends

  function searchFilter(userInput) {
    const keysToSearch = ["full_name", "email", "id", "mobile_number", "username"];
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = userList2.filter(obj => {
      return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
    });
    setuserList(matchingObjects);
    setCurrentPage(1)
  };


  // *********Export Trade Data In Excel Formats ************* // 
  const exportToExcel = (userList) => {
    const ws = XLSX.utils.json_to_sheet(userList);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Trades');
    XLSX.writeFile(wb, ' Traders List.xlsx');
  };
  // *********Export Trade Data In PDF Formats ************* // 
  const exportToPDF = (userList) => {
    const doc = new jsPDF();
    doc.text(' Traders List', 10, 10);
    const keys = Object.keys(userList[0]);
    const tableHead = keys.map(key => key.charAt(0).toUpperCase() + key.slice(1));
    const tableData = userList.map(trade => keys.map(key => trade[key]));

    doc.autoTable({
      head: [tableHead],
      body: tableData,
      styles: {
        fontSize: 7,
      },
    });
    doc.save(' Traders List.pdf');
  };
  // *********Export Trade Data In CSV Formats ************* // 
  const exportToCSV = (userList) => {

    const csv = Papa.unparse(userList);
    const blob = new Blob([csv], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = ' Traders List.csv';
    link.click();
  };

  const HandleExport = (exportData) => {
    let dataToExport = userList?.map((data) => (
      { "Registration Date": data?.createdAt, "UserId": data?._id, "User Name": data?.username, "Phone": data?.mobile_number, "Refer Code": data?.refercode, "Aadhar": data?.adhar_verified == 0 ? "Not Submitted" : data?.adhar_verified == 1 ? "Approved" : "Pending", "PAN": data?.pan_verified == 0 ? "Not Submitted" : data?.pan_verified == 1 ? "Approved" : "Pending", "Total Balance": data?.total_balance, "Total Withdrawal": data?.total_withdrawl, "Totdal Deposit": data?.totaldeposit, "Winning Cash": data?.winning_amount, "Cash Bonus": data?.cash_bonus, "Status": data?.status }
    ))
    console.log("🚀 ~ HandleExport ~ dataToExport:", dataToExport)
    if (exportData === 'EXCEL') {
      exportToExcel(dataToExport)
    }
    else if (exportData === 'PDF') {
      exportToPDF(dataToExport)
    }
    else if (exportData === 'CSV') {
      exportToCSV(dataToExport)
    }
  };

  const verifyBank = async (id, status, userid) => {
    await AuthService.verifyBank(id, status, userid).then((result) => {
      if (result?.success) {
        try {
          alertSuccessMessage("Bank Status Updated Successfully!!");
          AuthService.listBank()

        } catch {
          alertErrorMessage(result.message);
        }
      } else {
        // alertErrorMessage(result.message);
      }
    });
  };


  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="ri-group-line"></i>
                      </div>
                      Users List
                    </h1>
                  </div>

                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="card mb-42">
              <div className="card-body">
                <div className=" filter_bar mb-4 mb-md-5">
                  <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form" action="/admin/users/referraldata">
                    <div className="row gx-2 align-items-center">
                      <div className="form-group  mb-0 col-5 ">
                        <div className="input text">
                          <input type="tel" name="phone" className="form-control" placeholder="Search name, email, phone, username, user id" id="search" onChange={(e) => searchFilter(e.target.value)} />
                        </div>
                      </div>
                      <div className="dropdown col-7 d-flex justify-content-end">
                        <button className="btn btn-dark btn-md dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Export{" "}
                        </button>
                        <div class="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                          <button type="button" onClick={() => { HandleExport('EXCEL') }} class="dropdown-item">Export as EXCEL</button>
                          <button type="button" onClick={() => { HandleExport('CSV') }} class="dropdown-item">Export as CSV</button>
                          <button type="button" onClick={() => { HandleExport('PDF') }} class="dropdown-item">Export as PDF</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
                <table id="datatablesSimple">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Registration Date</th>
                      <th>User Id</th>
                      <th>Mobile</th>
                      <th>Total Balance</th>
                      <th>Total Withdrawal</th>
                      <th>Total Deposit</th>
                      <th>Winnning Amount</th>
                      <th>Cash Bonus</th>
                      <th>Aadhar Status</th>
                      <th>Pan Status</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems
                      ? currentItems?.map((data, index) => {
                        let objIndex;
                        objIndex = userList.findIndex(check => check?._id == data._id);
                        return (
                          <tr key={index}>
                            <td>{userList?.length - objIndex}</td>
                            <td><Moment date={data?.createdAt} format="YYYY/MM/DD HH:mm" /></td>
                            <td>{data?.id}</td>
                            <td>{data?.mobile_number}</td>
                            <td>{parseFloat((data?.totaldeposit + data?.winning_amount + data?.cash_bonus)?.toFixed(3))}</td>
                            <td>{parseFloat(data?.total_withdrawl?.toFixed(3))}</td>
                            <td>{parseFloat(data?.totaldeposit?.toFixed(3))}</td>
                            <td>{parseFloat(data?.winning_amount?.toFixed(3))} <br />
                              <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal"
                                data-bs-target="#editbonusModal" onClick={() => { setuserId(data?._id) }}>Edit</button>
                            </td>
                            <td>{parseFloat(data?.cash_bonus?.toFixed(3))} <br />
                              <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal"
                                data-bs-target="#editbonusModal" onClick={() => { setuserId(data?._id) }}>Edit</button></td>
                            <td>{data?.adhar_verified == 0 ? "Not Submitted" : data?.adhar_verified == 1 ? "Approved" : "Pending"}</td>
                            <td>{data?.pan_verified == 0 ? "Not Submitted" : data?.pan_verified == 1 ? "Approved" : "Pending"}</td>
                            {data?.status === "Active" ?
                              <td>
                                <button type="btn" className="btn badge bg-success text-white rounded-pill" onClick={() => { UserStatus("Inactive", data?._id); }}>
                                  {data?.status}
                                </button>
                              </td>
                              :
                              <td>
                                <button type="btn" className="btn badge bg-danger text-white rounded-pill" onClick={() => { UserStatus("Active", data?._id); }}>
                                  {data?.status}
                                </button>
                              </td>
                            }
                            <td>
                              <div className="table_action_group">
                                {/* <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editUser" onClick={() => { handleEditDetails(data); }} >
                                  Edit / View
                                </button> */}
                                <button type="btn" className="btn badge bg-secondary text-white rounded-pill" onClick={() => { Viewbankdetails(data?._id); }}>
                                  Bank
                                </button>
                                <button type="btn" className="btn badge bg-dark text-white rounded-pill" onClick={() => { ViewPANdetails(data?.id) }}>
                                  Pan Card
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                      : ""}
                  </tbody>
                </table>
                <ReactPaginate
                  pageCount={pageCount}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName={'customPagination'}
                  activeClassName={'active'}
                />
              </div>
            </div>
          </div>
        </main>
      </div>


      {/* Edit User Details */}
      <div className="modal" id="editUser" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: "none" }} aria-hidden="true">
        <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editwallrtlmodalTitle">
                Edit User Details
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"
              ></button>
              <hr />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> First Name </label>
                  <input type="text" name="first_name" maxLength="30" className="form-control" placeholder="First Name" id="first-name"
                    value={fName ? fName : ''} onChange={(e) => { setfName(e.target.value); }} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label htmlFor="first-name">Last Name </label>
                  <input type="text" name="first_name" maxLength="30" className="form-control" placeholder="First Name" id="first-name" value={lName ? lName : ''} onChange={(e) => { setlName(e.target.value); }} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label htmlFor="email">Email / Phone Number</label>
                  <input type="email" name="email" className="form-control" placeholder="Email / Phone Number" maxLength="255" id="email"
                    value={email ? email : ''} onChange={(e) => { setemail(e.target.value); }} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label htmlFor="userName">User Name</label>
                  <input type="text" name="userName" className="form-control" placeholder="User Name" id="userName" value={userName ? userName : ''} onChange={(e) => { setuserName(e.target.value); }} />
                </div>
                <div className="form-group  position-relative mt-4">
                  <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => { editUser(userId, fName, lName, email, userName); }}>Submit Details
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>


      {/* Edit Pan Details */}
      <div className="modal" id="editPancard" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: "none" }} aria-hidden="true"     >
        <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editwallrtlmodalTitle">
                Pan Details
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              <hr />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Pan Number </label>
                  <input type="text" name="panNumber" maxLength="30" className="form-control" id="first-name" value={panNumber} onChange={(e) => handlePanInput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Name </label>
                  <input type="text" name="panName" maxLength="30" className="form-control" id="first-name" value={nameOnPan} onChange={(e) => handlePanInput(e)} />
                </div>
                {/* <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> DOB </label>
                  <input type="date" name="panDob" maxLength="30" className="form-control" id="first-name" value={panDob} onChange={(e) => handlePanInput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <div className="vs_team" style={{ alignItems: 'center' }}>
                    <a href={ApiConfig?.appUrl + '/uploads/' + panImage} target='_blank' rel="noreferrer" >
                      <img src={panImage2 ? panImage2 : `${ApiConfig?.appUrl + '/uploads/' + panImage}`} width="50" height="50" alt="" /></a>
                    <label className="small mb-1">  </label>
                    <input type="file" name="panImage" maxLength="30" className="form-control" id="first-name" onChange={(e) => handlePanInput(e)} />
                  </div>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> State </label>
                  <input type="text" name="panState" maxLength="30" className="form-control" id="first-name" value={panState} onChange={(e) => handlePanInput(e)} />
                </div> */}
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Status </label>
                  <input type="text" name="panState" maxLength="30" className="form-control" id="first-name" disabled value={panStatus === 1 ? 'Active' : panStatus === 0 ? 'Inactive' : ''} />
                </div>
                <div className="form-group  position-relative mt-4">
                  {panStatus ? <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => { editPANdtl(panNumber, nameOnPan, panDob, panImage, panState); }}>Edit Pan</button> :
                    <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => { AddPan(panNumber, nameOnPan, panDob, panImage, panState); }} >Add Pan Details</button>}
                </div>
                <button className="btn btn-Danger btn-block w-100" type="button" onClick={() => { dltPandetails(userId); }}>
                  Delete
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Bank Details */}
      <div className="modal" id="editBank" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: "none" }} aria-hidden="true"      >
        <div className="modal-dialog  alert_modal modal-dialog-centered" role="document"        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editwallrtlmodalTitle">
                Bank Details
              </h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              <hr />
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Account Number </label>
                  <input type="number" name="bankNumber" maxLength="30" className="form-control" id="first-name" value={accNumber} onChange={(e) => handleBankImput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> IFSC Code </label>
                  <input type="text" name="bankCode" maxLength="30" className="form-control" id="first-name" value={ifsc} onChange={(e) => handleBankImput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Branch </label>
                  <input type="text" name="branch" maxLength="30" className="form-control" id="first-name" value={branch} onChange={(e) => handleBankImput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Bank Name </label>
                  <input type="text" name="bakName" maxLength="30" className="form-control" id="first-name" value={bankName} onChange={(e) => handleBankImput(e)} />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <div className="vs_team" style={{ alignItems: 'center' }}>
                    <a href={ApiConfig?.appUrl + '/uploads/' + bankImage} target='_blank' rel="noreferrer" >
                      <img src={bankImage2 ? bankImage2 : `${ApiConfig?.appUrl + '/uploads/' + bankImage}`} width="50" height="50" alt="" /></a>

                    <label className="small mb-1"></label>
                    <input type="file" name="bankImage" maxLength="30" className="form-control" id="first-name" onChange={(e) => handleBankImput(e)} />
                  </div>
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Status </label>
                  <input type="text" name="bankStatus" maxLength="30" className="form-control" id="first-name" disabled value={bankStatus === 1 ? 'Active' : bankStatus === 0 ? 'Inactive' : ''} />
                </div>
                {/* <div className="form-group  mb-3 position-relative ">
                  {bankStatus == 2 ?
                    <button type="btn" className="btn badge bg-success text-white rounded-pill"
                      onClick={() => { verifyBank(userId, '1', bankId) }}
                    >Approve</button>
                    : <p className='text-success'> Approved</p>
                  }
                </div> */}
                <div className="form-group  position-relative mt-4">
                  {bankStatus ? <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => { editbnkdtl(accNumber, ifsc, branch, bankName, bankImage); }}                  >
                    Edit Details

                  </button> :
                    <button className="btn btn-primary   btn-block w-100" type="button" onClick={() => { AddBankacc(accNumber, ifsc, branch, bankName, bankImage); }}                    >
                      Add Bank
                    </button>}

                </div>
                <button className="btn btn-Danger btn-block w-100" type="button" onClick={() => { dltbankdetails(userId); }}>
                  Delete
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>


      {/* Edit Bonus Modal */}

      <div className="modal" id="editbonusModal" tabIndex="-1" aria-labelledby="editbonusModalTitle" style={{ display: 'none' }} aria-hidden="true">
        <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="editbonusModal">Edit Details</h5>
              <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              <hr />
            </div>
            <div className="modal-body">

              <br />
              <form>
                <div className="form-group  mb-3 position-relative ">
                  <label className="small mb-1"> Amount </label>
                  <input className="form-control  input-copy" type="number" placeholder="Enter Amount"
                    onChange={(e) => setAmount(e.target.value)} value={amount} onWheel={(e) => e.target.blur()}
                  />
                </div>
                <div className="form-group  mb-3 position-relative ">
                  <select name="up_type" id="up_type" className="form-control form-select"
                    onChange={(e) => setTransType(e.target.value)} value={transType}
                  >
                    <option value="Add">Add</option>
                    <option value="Deduct">Deduct</option>
                  </select>
                </div>
                <div className="form-group  position-relative mt-4">
                  <button className="btn btn-primary   btn-block w-100" type="button"
                    onClick={handleBonusAmmount}
                  >Update Bonus Amount</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersList;
