import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import Header from '../Header'
import Sidebar from '../Sidebar'
import Swal from 'sweetalert2';

const PushNotification = () => {
    const [title, settitle] = useState();
    const [notification, setnotification] = useState();
    const [notificationList, setnotificationList] = useState([]);
    const [userId, setuserId] = useState();
    const [id, setId] = useState("");

    useEffect(() => {
        getNotificationList()
    }, []);

    const getNotificationList = async () => {
        await AuthService.getNotificationList().then((result) => {
            if (result?.success) {
                try {
                    setId(result?.data?._id)
                    setnotificationList(result?.data)
                } catch {
                    alertErrorMessage();
                }
            } else {
                alertErrorMessage(result?.message);
            }
        })
    };

    console.log(id, "id");
    const addNotification = async (title, notification) => {
        if (title && notification) {
            await AuthService.AddNotification(title, notification).then((result) => {
                if (result?.success) {
                    try {
                        alertSuccessMessage('Notification Added!!');
                        setnotification('')
                        settitle('')
                        getNotificationList()

                    } catch {
                        alertErrorMessage();
                    }
                } else {
                    alertErrorMessage(result?.message);
                }
            })
        }
        else {
            alertWarningMessage('Please all required fields')
        }
    };
    const sendNotification = async (id) => {
        try {
            await Swal.fire({
                title: 'Do you want to send notification?',
                icon: 'info',
                showDenyButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `Cancel`,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const result = await AuthService.sendNotification(id)
                    if (result?.success) {
                        alertSuccessMessage("Notification Sent!!")
                    } else {
                        alertErrorMessage(result?.message);
                    }
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'error')
                }
            })
        } catch {
            alertErrorMessage();
        }
    };

    /* custom pagination */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = Math.ceil(notificationList.length / itemsPerPage);
    const currentItems = notificationList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // custom paginatio ends

    const deleleteNotification = async (id) => {
        try {
            const result = await AuthService.deleteNotification(id)
            if (result?.success) {
                alertSuccessMessage("Notification Deleted!!")
                getNotificationList();
            } else {
                alertErrorMessage(result?.message);
            }
        } catch {
            alertErrorMessage();
        }
    }

    const handleEditNotification = async (id, title, notification) => {
        try {
            const result = await AuthService.editNotification(id, title, notification)
            if (result?.success) {
                alertSuccessMessage("Notification Updated!!")
                getNotificationList();
            } else {
                alertErrorMessage(result?.message);
            }
        } catch {
            alertErrorMessage();
        }
    }
    const handleEditDetails = (data) => {
        setId(data?._id)
        settitle(data?.Subject)
        setnotification(data?.notification)
    };

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-notification-3-line"></i></div>
                                            Push Notifications
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content--> */}
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-header">
                                <h3 className="card-title mb-0">Enter Details</h3> </div>

                            <form method="post" acceptCharset="utf-8" validaterset="validaterset" id="notificationForm" action="">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <div className="input Number">
                                                    <div className="input text required"><label for="title">Title <span className="required">*</span></label><input type="text" name="title" className="form-control" placeholder="Notification Title" required="required" maxLength="100" id="title" value={title} onChange={(e) => { settitle(e.target.value) }} /></div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="input text">
                                                    <div className="form-group">
                                                        <label>Notification <span className="required">*</span></label>
                                                        <div className="input textarea required">
                                                            <textarea name="notification" className="form-control" rows="3" required="required" maxLength="255" id="notification" value={notification} onChange={(e) => { setnotification(e.target.value) }}></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="button" className="btn btn-primary submit" onClick={() => { addNotification(title, notification) }}>Add Notification</button>
                                </div>
                            </form>

                        </div>
                        <div className="card mb-4">

                            <div className="card-body">

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Notification</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Title</th>
                                            <th>Notification</th>
                                            <th>Action</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {currentItems ? currentItems?.map((data, index) => {
                                            let objIndex;
                                            objIndex = notificationList.findIndex(check => check?._id == data._id);
                                            return (
                                                <tr>
                                                    <td>{objIndex + 1}</td>
                                                    <td>{data?.Title}</td>
                                                    <td>{data?.message}</td>
                                                    <td>
                                                        <div className='d-flex'>
                                                            <div className="table_action_group">
                                                                <button type="btn" className="btn badge bg-primary text-white rounded-pill"
                                                                    onClick={() => { sendNotification(data?._id) }}
                                                                >Send</button>
                                                            </div>
                                                            <div className="table_action_group">
                                                                <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editNotification" onClick={() => { handleEditDetails(data) }}  >Edit</button>
                                                            </div>
                                                            <div className="table_action_group">
                                                                <button type="btn" className="btn badge bg-danger text-white rounded-pill"
                                                                    onClick={() => { deleleteNotification(data?._id) }}
                                                                >Delete</button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : ''}

                                    </tbody>
                                </table>
                                <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    containerClassName={'customPagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>
                </main>
            </div>
            <div className="modal" id="editNotification" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">Edit User Details</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">

                            <form>

                                <div className="form-group  mb-3 position-relative ">
                                    <label for="title">Title </label><input type="text" name="title" className="form-control" placeholder="Notification Title" required="required" maxLength="100" id="title" value={title} onChange={(e) => { settitle(e.target.value) }} />

                                </div>

                                <div className="form-group  mb-3 position-relative ">
                                    <label>Notification </label>
                                    <div className="input textarea required">
                                        <textarea name="notification" className="form-control" rows="3" required="required" maxLength="255" id="notification" value={notification} onChange={(e) => { setnotification(e.target.value) }}></textarea>
                                    </div>

                                </div>

                                <div className="form-group  position-relative mt-4">
                                    <button data-bs-dismiss="modal" className="btn btn-primary   btn-block w-100" type="button" onClick={() => { handleEditNotification(id, title, notification) }} >Submit Details</button>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PushNotification
