import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const AddNewPayment = () => {
  return (
<>


    <div id="layoutSidenav_content">
        <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                <div className="container-xl px-4">
                    <div className="page-header-content pt-4">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-auto mt-4">
                                <h1 className="page-header-title">
                                    <div className="page-header-icon"><i className="ri-bank-card-line"></i></div>
                                  Payment
                                </h1>
                                {/* <!-- <div className="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* <!-- Main page content-->
            <!-- Example Colored Cards for Dashboard Demo--> */}
            <div className="container-xl px-4 mt-n10">

                <div className="row g-3">
                    <div className="col-xl-6">
                        <div className="card h-100">
                            <div className="card-body d-flex justify-content-start flex-column pt-5 ">
                                <div className="d-flex align-items-center justify-content-start mb-4 ">
                                    <div className="me-3"><span className="symbol-label"><img src="/assets/img/cashfree.png" className="h-75 align-self-center img-fluid" alt=""/></span></div>
                                    <h5 className="mb-0">Cashfree</h5>
                                </div>
                                <form>
                                    <div className="row">

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-access-key">Cashfree access key <span className="required">*</span></label><input type="text" name="cash_access_key" className="form-control cash_access_key" placeholder="Cashfree access key" maxLength="255" id="cash-access-key" value=""/></div>                                   </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-secret-key">Cashfree secret key <span className="required">*</span></label><input type="text" name="cash_secret_key" className="form-control cash_secret_key" placeholder="Cashfree secret key" maxLength="255" id="cash-secret-key" value=""/></div>                                   </div>
                                        </div>
                                        
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-access-token">Access Token <span className="required">*</span></label><input type="text" name="cash_access_token" className="form-control cash_access_token" placeholder="Access Token" maxLength="255" id="cash-access-token" value=""/></div>                                 </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                               <button className="btn btn-danger   btn-block w-100 mt-2" type="button" >Inactive</button>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card h-100">
                            <div className="card-body d-flex justify-content-start flex-column pt-5 ">
                                <div className="d-flex align-items-center justify-content-start mb-4 ">
                                    <div className="me-3"><span className="symbol-label"><img src="/assets/img/razorpay.png" className="h-75 align-self-center img-fluid" alt=""/></span></div>
                                    <h5 className="mb-0">Razorpay</h5>
                                </div>
                                
                                <form>
                                    <div className="row">

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-access-key">Razorpay access key <span className="required">*</span></label><input type="text" name="cash_access_key" className="form-control cash_access_key" placeholder="Razorpay access key" maxLength="255" id="Razorpay-access-key" value=""/></div>                                   </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-secret-key">Razorpay secret key <span className="required">*</span></label><input type="text" name="cash_secret_key" className="form-control cash_secret_key" placeholder="Razorpay secret key" maxLength="255" id="Razorpay-secret-key" value=""/></div>                                   </div>
                                        </div>
                                        
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-access-token">Razorpay Token <span className="required">*</span></label><input type="text" name="cash_access_token" className="form-control cash_access_token" placeholder="Access Token" maxLength="255" id="Razorpay-access-token" value=""/></div>                                 </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                               <button className="btn btn-danger   btn-block w-100 mt-2" type="button" >Inactive</button>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card h-100">
                            <div className="card-body d-flex justify-content-start flex-column pt-5 ">
                                <div className="d-flex align-items-center justify-content-start mb-4 ">
                                    <div className="me-3"><span className="symbol-label"><img src="/assets/img/paytm.png" className="h-75 align-self-center img-fluid" alt=""/></span></div>
                                    <h5 className="mb-0">PayTm</h5>
                                </div>
                                <form>
                                    <div className="row">

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-access-key">Razorpay access key <span className="required">*</span></label><input type="text" name="cash_access_key" className="form-control cash_access_key" placeholder="Razorpay access key" maxLength="255" id="Razorpay-access-key" value=""/></div>                                   </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-secret-key">Razorpay secret key <span className="required">*</span></label><input type="text" name="cash_secret_key" className="form-control cash_secret_key" placeholder="Razorpay secret key" maxLength="255" id="Razorpay-secret-key" value=""/></div>                                   </div>
                                        </div>
                                        
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-access-token">Razorpay Token <span className="required">*</span></label><input type="text" name="cash_access_token" className="form-control cash_access_token" placeholder="Access Token" maxLength="255" id="Razorpay-access-token" value=""/></div>                                 </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                               <button className="btn btn-danger   btn-block w-100 mt-2" type="button" >Inactive</button>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-6">
                        <div className="card h-100">
                            <div className="card-body d-flex justify-content-start flex-column pt-5 ">
                                <div className="d-flex align-items-center justify-content-start mb-4 ">
                                    <div className="me-3"><span className="symbol-label"><img src="/assets/img/payu.png" className="bg-dark h-75 align-self-center img-fluid" alt=""/></span></div>
                                    <h5 className="mb-0">Payu Money</h5>
                                </div>
                                <form>
                                    <div className="row">

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-access-key">Razorpay access key <span className="required">*</span></label><input type="text" name="cash_access_key" className="form-control cash_access_key" placeholder="Razorpay access key" maxLength="255" id="Razorpay-access-key" value="bkKCFw"/></div>                                   </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-secret-key">Razorpay secret key <span className="required">*</span></label><input type="text" name="cash_secret_key" className="form-control cash_secret_key" placeholder="Razorpay secret key" maxLength="255" id="Razorpay-secret-key" value="YIXqXysdsdpogrFZKhHdeDOeXULtx1l5uKS4"/></div>                                   </div>
                                        </div>
                                        
                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <div className="input text"><label for="cash-access-token">Razorpay Token <span className="required">*</span></label><input type="text" name="cash_access_token" className="form-control cash_access_token" placeholder="Access Token" maxLength="255" id="Razorpay-access-token" value="8232750857"/></div>                                 </div>
                                        </div>

                                        <div className="col-xs-12 col-sm-12 col-md-12">
                                            <div className="form-group">
                                               <button className="btn btn-success   btn-block w-100 mt-2" type="button" >Active</button>
                                            </div>
                                        </div>


                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>



            </div>


                
           
        </main>
    </div>




</>
  )
}

export default AddNewPayment
