import React, { useEffect, useState } from 'react'
import AuthService from '../../../api/services/HomeService';
import LoaderHelper from '../../../Components/Loading/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from '../../../Components/CustomAlertMessage';
import Moment from 'react-moment';

const ApprovedRequest = () => {
    const [panCard, setpanCard] = useState([]);
    const [panCard2, setpanCard2] = useState([]);

    useEffect(() => {
        listPan()
    }, []);

    function searchFilter(userInput) {
        const keysToSearch = ["NameOnPan", "UserId", "createdAt", "pan_number"];
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = panCard2.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setpanCard(matchingObjects);
    };

    const listPan = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.listPan(1).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setpanCard(result?.data)
                    setpanCard2(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };


    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="ri-group-line"></i></div>
                                        Approved Pancard Request
                                    </h1>
                                </div>

                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-body">
                            <div className="input text">
                                <input type="text" name="search" className="form-control" placeholder="Search name, username, user id, pan number" id="search"
                                    onChange={(e) => searchFilter(e.target.value)} />
                            </div>
                            <table id="datatablesSimple">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>UserId</th>
                                        <th>Name</th>
                                        <th>Pan Number</th>
                                        <th>Phone Number</th>
                                        <th>Registration Date</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {panCard ? panCard?.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{data?.UserId}</td>
                                                <td>{data?.NameOnPan}</td>
                                                <td>{data?.pan_number}</td>
                                                <td>{data?.data?.mobile_number}</td>
                                                <td> <Moment date={data?.createdAt} format="YYYY/MM/DD" /> </td>
                                                <td>
                                                    <button type="button" className="mt-2 btn badge text-white rounded-pill">
                                                        {data?.pan_verified == 1 ? <p className='text-success text-center'>Approved</p> : data?.pan_verified == 2 ? <p className='text-warning text-center'>Pending</p> : data?.pan_verified == 3 ? <p className='text-danger text-center'>Reject</p> : ''}
                                                    </button>

                                                </td>
                                            </tr>
                                        )
                                    }) : ''}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </main>
        </div>
    )
}

export default ApprovedRequest
