import { ApiConfig } from "../apiConfig/ApiConfig";
import { ApiCallDelete, ApiCallPost } from "../apiConfig/ApiCall";
import { ApiCallGet } from "../apiConfig/ApiCall"

const AuthService = {

  addSubadmin: async (formData) => {
    const { varfiyAdmin, addSubadmin } = ApiConfig;
    const url = varfiyAdmin + addSubadmin;
    const params = {

    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },

  getSubadmin: async () => {
    const { varfiyAdmin, getSubadmin } = ApiConfig;
    const url = varfiyAdmin + getSubadmin;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  deleteSubadmin: async (id) => {
    const { varfiyAdmin, deleteSubadmin } = ApiConfig;
    const url = varfiyAdmin + deleteSubadmin;
    const params = {
      _id: id
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  deleteCategory: async (id) => {
    const { varfiyAdmin, deleteCategory } = ApiConfig;
    const url = varfiyAdmin + deleteCategory;
    const params = {
      _id: id
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  deleteContest: async (id) => {
    const { varfiyAdmin, deleteContest } = ApiConfig;
    const url = varfiyAdmin + deleteContest;
    const params = {
      _id: id
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  EditSubadmin: async (formData) => {
    const { varfiyAdmin, EditSubadmin } = ApiConfig;
    const url = varfiyAdmin + EditSubadmin;
    const params = {

    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },

  addCategory: async (catogryName, description, seqNumber,) => {
    const { varfiyAdmin, addCategory } = ApiConfig;
    const url = varfiyAdmin + addCategory;
    const params = {
      'categoryName': catogryName,
      'Description': description,
      'sequence': seqNumber,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  setCatogery: async (userId, catogryName, description, seqNumber) => {
    const { varfiyAdmin, setCatogery } = ApiConfig;
    const url = varfiyAdmin + setCatogery;
    const params = {
      '_id': userId,
      'categoryName': catogryName,
      'Description': description,
      'sequence': seqNumber,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  Subadminstatus: async (id, status) => {
    const { varfiyAdmin, Subadminstatus } = ApiConfig;
    const url = varfiyAdmin + Subadminstatus;
    const params = {
      _id: id,
      status: status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  Setstatus: async (id, status) => {
    const { varfiyAdmin, Setstatus } = ApiConfig;
    const url = varfiyAdmin + Setstatus;
    const params = {
      _id: id,
      status: status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  SetContestStatus: async (id, status) => {
    const { varfiyAdmin, SetContestStatus } = ApiConfig;
    const url = varfiyAdmin + SetContestStatus;
    const params = {
      _id: id,
      status: status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  SetBannerStatus: async (id, status) => {
    const { varfiyAdmin, SetBannerStatus } = ApiConfig;
    const url = varfiyAdmin + SetBannerStatus;
    const params = {
      _id: id,
      status: status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  DltBnrbyId: async (id) => {
    const { varfiyAdmin, DltBnrbyId } = ApiConfig;
    const url = varfiyAdmin + DltBnrbyId;
    const params = {
      _id: id,

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  EditBanner: async (formData) => {
    const { varfiyAdmin, EditBanner } = ApiConfig;
    const url = varfiyAdmin + EditBanner;
    const params = {
    }
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },

  addUser: async (fName, lName, email, password, confPassword, userName) => {
    const { varfiyAdmin, addUser } = ApiConfig;
    const url = varfiyAdmin + addUser;
    const params = {
      "first_name": fName,
      "last_name": lName,
      "email_or_phone": email,
      "password": password,
      "confirm_password": confPassword,
      "username": userName,
      "verified": '1',
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  setBonus: async (amount, status, id) => {
    const { baseReports, setBonus } = ApiConfig;
    const url = baseReports + setBonus;
    const params = {
      "userid": id,
      "cash_bonus": +amount,
      "trnsStats": status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  editUser: async (userId, fName, lName, email, userName) => {
    const { varfiyAdmin, editUser } = ApiConfig;
    const url = varfiyAdmin + editUser;
    const params = {
      "_id": userId,
      "first_name": fName,
      "last_name": lName,
      "email_or_phone": email,
      "username": userName
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  AddNotification: async (title, notification) => {
    const { varfiyAdmin, AddNotification } = ApiConfig;
    const url = varfiyAdmin + AddNotification;
    const params = {
      Title: title,
      message: notification
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  EmailNotification: async (email, title, message) => {
    const { varfiyAdmin, EmailNotification } = ApiConfig;
    const url = varfiyAdmin + EmailNotification;
    const params = {
      Email: email,
      Subject: title,
      message: message
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  MobileNotification: async (mobile, title, message) => {
    const { varfiyAdmin, MobileNotification } = ApiConfig;
    const url = varfiyAdmin + MobileNotification;
    const params = {
      MobileNo: mobile,
      Subject: title,
      message: message
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  getNotificationList: async () => {
    const { varfiyAdmin, getNotificationList } = ApiConfig;
    const url = varfiyAdmin + getNotificationList;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  getEmailNotificationList: async () => {
    const { varfiyAdmin, getEmailNotificationList } = ApiConfig;
    const url = varfiyAdmin + getEmailNotificationList;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  getmobileNotificationList: async () => {
    const { varfiyAdmin, getmobileNotificationList } = ApiConfig;
    const url = varfiyAdmin + getmobileNotificationList;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  CreatedContest: async () => {
    const { varfiyAdmin, CreatedContest } = ApiConfig;
    const url = varfiyAdmin + CreatedContest;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  ViewActiveCont: async () => {
    const { varfiyAdmin, ViewActiveCont } = ApiConfig;
    const url = varfiyAdmin + ViewActiveCont;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  userCount: async () => {
    const { varfiyAdmin, userCount } = ApiConfig;
    const url = varfiyAdmin + userCount;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  InactvCont: async () => {
    const { varfiyAdmin, InactvCont } = ApiConfig;
    const url = varfiyAdmin + InactvCont;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  NotVerfied: async () => {
    const { appUrl, NotVerfied } = ApiConfig;
    const url = appUrl + NotVerfied;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  logs: async () => {
    const { appUrl, logs } = ApiConfig;
    const url = appUrl + logs;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  forgot: async (email) => {
    const { appUrl, forgot } = ApiConfig;
    const url = appUrl + forgot;
    const params = {
      Email: email
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  getTransaction: async () => {
    const { appUrl, getTransaction } = ApiConfig;
    const url = appUrl + getTransaction;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  sendNotification: async (id) => {
    const { varfiyAdmin2, sendnotification } = ApiConfig;
    const url = varfiyAdmin2 + sendnotification;
    const params = {
      _id: id,

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  EditEmailNoti: async (id, email, title, message) => {
    const { varfiyAdmin, EditEmailNoti } = ApiConfig;
    const url = varfiyAdmin + EditEmailNoti;
    const params = {
      _id: id,
      Email: email,
      Subject: title,
      message: message

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  EditMobileNoti: async (id, mobile, title, message) => {
    const { varfiyAdmin, EditMobileNoti } = ApiConfig;
    const url = varfiyAdmin + EditMobileNoti;
    const params = {
      _id: id,
      MobileNo: mobile,
      Subject: title,
      message: message

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  ContentEdit: async (id, title, content) => {
    const { varfiyAdmin, ContentEdit } = ApiConfig;
    const url = varfiyAdmin + ContentEdit;
    const params = {
      _id: id,
      "Title": title,
      "content": content

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  UpcmngMtchedt: async () => {
    const { TeamContest, UpcmngMtchedt } = ApiConfig;
    const url = TeamContest + UpcmngMtchedt;
    const params = {


    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  // EditEmailNoti: async (id,email,title,message) => {
  //   const { varfiyAdmin, EditEmailNoti } = ApiConfig;
  //   const url = varfiyAdmin + EditEmailNoti;
  //   const params = {
  //     _id:id,
  //     Email:email,
  //     Subject:title,
  //     message:message

  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   return ApiCallPost(url, params, headers);
  // },
  // EditMobileNoti: async (id,mobile,title,message) => {
  //   const { varfiyAdmin, EditMobileNoti } = ApiConfig;
  //   const url = varfiyAdmin + EditMobileNoti;
  //   const params = {
  //     _id:id,
  //     MobileNo:mobile,
  //     Subject:title,
  //     message:message

  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   return ApiCallPost(url, params, headers);
  // },
  // ContentEdit: async (id,title,content) => {
  //   const { varfiyAdmin, ContentEdit } = ApiConfig;
  //   const url = varfiyAdmin + ContentEdit;
  //   const params = {
  //     _id:id,
  //     "Title":title,
  //     "content":content

  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   return ApiCallPost(url, params, headers);
  // },
  // UpcmngMtchedt: async () => {
  //   const { TeamContest, UpcmngMtchedt } = ApiConfig;
  //   const url = TeamContest + UpcmngMtchedt;
  //   const params = {


  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   return ApiCallPost(url, params, headers);
  // },


  addContest: async (winningType, winAmount, catogName, adminCommision, contestSize, contestType, calcEntryFee, bonusPercentage, uniqueArr2, autoCreate, multTeam, confWin, multiple_team) => {
    const { varfiyAdmin, addContest } = ApiConfig;
    const url = varfiyAdmin + addContest;
    const params = {
      Rankdata: uniqueArr2,
      WinningAmount: +winAmount,
      categoryName: catogName,
      AdminCommission: adminCommision,
      Contestsize: contestSize,
      EnteryType: contestType,
      EnteryFee: calcEntryFee,
      ContestType: winningType,
      AutoCreate: autoCreate,
      JoinWithMULT: multTeam,
      ConfirmedWin: confWin,
      UsableBonusPercantage: bonusPercentage,
      teams: multiple_team,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  EditLiveMatches: async (winningType, winAmount, catogName, adminCommision, contestSize, contestType, calcEntryFee, bonusPercentage, uniqueArr2, autoCreate, multTeam, confWin, multiple_team, shadowID) => {
    const { appUrl, EditLiveMatches } = ApiConfig;
    const url = appUrl + EditLiveMatches;
    const params = {
      _id: shadowID,
      Rankdata: uniqueArr2,
      WinningAmount: +winAmount,
      categoryName: catogName,
      AdminCommission: adminCommision,
      Contestsize: contestSize,
      EnteryType: contestType,
      EnteryFee: calcEntryFee,
      ContestType: winningType,
      AutoCreate: autoCreate,
      JoinWithMULT: multTeam,
      ConfirmedWin: confWin,
      UsableBonusPercantage: bonusPercentage,
      teams: multiple_team,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  editContest: async (userId, winningType, winAmount, catogName, adminCommision, contestSize, contestType, calcEntryFee, bonusPercentage) => {
    const { varfiyAdmin, editContest } = ApiConfig;
    const url = varfiyAdmin + editContest;
    const params = {
      _id: userId,
      WinningAmount: winAmount,
      categoryName: catogName,
      AdminCommission: adminCommision,
      Contestsize: contestSize,
      EnteryType: winningType,
      EnteryFee: calcEntryFee,
      ContestType: contestType,
      UsableBonusPercantage: bonusPercentage
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  UserList: async () => {
    const { varfiyAdmin, UserList } = ApiConfig;
    const url = varfiyAdmin + UserList;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  ViewPANdetails: async (id) => {
    const { varfiyBank, ViewPANdetails } = ApiConfig;
    const url = varfiyBank + ViewPANdetails;
    const params = {
      UserId: id

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  SeriesName: async () => {
    const { appUrl, SeriesName } = ApiConfig;
    const url = appUrl + SeriesName;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  ContestList: async () => {
    const { varfiyAdmin, ContestList } = ApiConfig;
    const url = varfiyAdmin + ContestList;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  getCategorylist: async () => {
    const { varfiyAdmin, getCategorylist } = ApiConfig;
    const url = varfiyAdmin + getCategorylist;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  vcatogslist: async () => {
    const { varfiyAdmin2, vcatogslist } = ApiConfig;
    const url = varfiyAdmin2 + vcatogslist;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  UserStatus: async (status, id) => {
    const { varfiyAdmin, UserStatus } = ApiConfig;
    const url = varfiyAdmin + UserStatus;
    const params = {
      _id: id,
      status: status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  MatchesSeries: async (id) => {
    const { TeamContest, MatchesSeries } = ApiConfig;
    const url = TeamContest + MatchesSeries;
    const params = {
      cid: id

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  SeriesStats: async (id, status) => {
    const { baseManager, SeriesStats } = ApiConfig;
    const url = baseManager + SeriesStats;
    const params = {
      cid: id,
      is_active: status

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  SeriesShrt: async (id, name) => {
    const { TeamContest, SeriesShrt } = ApiConfig;
    const url = TeamContest + SeriesShrt;
    const params = {
      _id: id,
      abbr: name

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  MatchDate: async (id, matchId, data) => {
    const { TeamContest, MatchDate } = ApiConfig;
    const url = TeamContest + MatchDate;
    const params = {

      "_id": id,
      "MatchId": matchId,
      "Date": data
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  MatchEdit: async (matchId, data) => {
    const { TeamContest, MatchEdit } = ApiConfig;
    const url = TeamContest + MatchEdit;
    const params = {

      "match_id": matchId,
      "is_active": data
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getOfferlist: async () => {
    const { varfiyAdmin, getOfferlist } = ApiConfig;
    const url = varfiyAdmin + getOfferlist;
    const params = {


    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  viewMatchesCont: async () => {
    const { TeamContest, viewMatchesCont } = ApiConfig;
    const url = TeamContest + viewMatchesCont;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  ResultMtchedt: async () => {
    const { TeamContest, ResultMtchedt } = ApiConfig;
    const url = TeamContest + ResultMtchedt;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  GetMatchesData: async () => {
    const { TeamContest, GetMatchesData } = ApiConfig;
    const url = TeamContest + GetMatchesData;
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  GetliveMatchesdata: async () => {
    const { TeamContest, GetliveMatchesdata } = ApiConfig;
    const url = TeamContest + GetliveMatchesdata;
    const params = {


    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  ContestFields: async () => {
    const { TeamContest, ContestFields } = ApiConfig;
    const url = TeamContest + ContestFields;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  getAdconMtch: async (id) => {
    const { TeamContest, getAdconMtch } = ApiConfig;
    const url = TeamContest + getAdconMtch;
    const params = {
      match_id: id

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  ViewPerCon: async (matchId, contestId) => {
    const { TeamContest, ViewPerCon } = ApiConfig;
    const url = TeamContest + ViewPerCon;
    const params = {
      match_id: matchId,
      _id: contestId

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  UpcomingCont: async (id, data) => {
    const { TeamContest, UpcomingCont } = ApiConfig;
    const url = TeamContest + UpcomingCont;
    const params = {

      "match_id": id,
      "data": data

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  getContestDetails: async (id, contestId) => {
    const { TeamContest, getContestDetails } = ApiConfig;
    const url = TeamContest + getContestDetails;
    const params = {

      "match_id": id,
      "contest_category_id": contestId

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  addbanner: async (formData) => {
    const { varfiyAdmin, addbanner } = ApiConfig;
    const url = varfiyAdmin + addbanner;
    const params = {

    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },
  Setting: async (email, usableBonus, signupBonus, refBonus, refCommision, minWithdrwAmount, TDS, mindepositAmount) => {
    const { varfiyAdmin, Setting } = ApiConfig;
    const url = varfiyAdmin + Setting;
    const params = {
      _id: '63ef4487f10a8385a769e0f2',
      AdminEmail: email,
      UsableBonus: +usableBonus,
      SignupBonus: +signupBonus,
      RefralBonus: +refBonus,
      MiniMumDeposit: +mindepositAmount,
      ContactCommision: +refCommision,
      MinimumWithdraw: +minWithdrwAmount,
      TDS: +TDS,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  UpdateAdminPro: async (fName, lName, email, number, dob, gender, country, state, address, city, pinCode, teamName) => {
    const { varfiyAdmin, UpdateAdminPro } = ApiConfig;
    const url = varfiyAdmin + UpdateAdminPro;
    const params = {
      "_id": "641d6dc25f9df95261b29d03",
      "FirstName": fName,
      "LastName": lName,
      "TeamName": teamName,
      "PhoneNumber": +number,
      "adminemail": email,
      "dob": dob,
      "Gender": gender,
      "Country": country,
      "State": state,
      "Address": address,
      "City": city,
      "PinCode": +pinCode
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  ViewSetting: async () => {
    const { varfiyAdmin, ViewSetting } = ApiConfig;
    const url = varfiyAdmin + ViewSetting;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);

  },
  PreSquadMtch: async () => {
    const { appUrl, PreSquadMtch } = ApiConfig;
    const url = appUrl + PreSquadMtch;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);

  },
  TeamPlayers: async (id) => {
    const { appUrl, TeamPlayers } = ApiConfig;
    const url = appUrl + TeamPlayers;
    const params = {
      match_id: id
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  EditPlayers: async (id, data) => {
    const { appUrl, EditPlayers } = ApiConfig;
    const url = appUrl + EditPlayers;
    const params = {
      match_id: id,
      object: data
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  getPointstest: async () => {
    const { appUrl, getPointstest } = ApiConfig;
    const url = appUrl + getPointstest;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  getPoints: async () => {
    const { appUrl, getPoints } = ApiConfig;
    const url = appUrl + getPoints;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  getPointst20: async () => {
    const { appUrl, getPointst20 } = ApiConfig;
    const url = appUrl + getPointst20;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  getPointsODI: async () => {
    const { appUrl, getPointsODI } = ApiConfig;
    const url = appUrl + getPointsODI;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  EditPoints: async (data) => {
    const { appUrl, EditPoints } = ApiConfig;
    const url = appUrl + EditPoints;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, data, headers);

  },
  GetSeriesList: async () => {
    const { appUrl, GetSeriesList } = ApiConfig;
    const url = appUrl + GetSeriesList;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);

  },
  GetTeamsList: async (id) => {
    const { appUrl, GetTeamsList } = ApiConfig;
    const url = appUrl + GetTeamsList;
    const params = {
      cid: id
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  Getteamplayer: async (id, cid) => {
    const { appUrl, Getteamplayer } = ApiConfig;
    const url = appUrl + Getteamplayer;
    const params = {
      tid: +id,
      cid: +cid
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  editplayer: async (team_id, pid, playerRating, playerRole, playerImage) => {
    const { appUrl, editplayer } = ApiConfig;
    const url = appUrl + editplayer;
    const params = {
      team_id: team_id,
      pid: pid,
      fantasy_player_rating: playerRating,
      playing_role: playerRole,
      logo_url: playerImage,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },
  upload: async (formData) => {
    const { appUrl, upload } = ApiConfig;
    const url = appUrl + upload;
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);

  },
  changepass: async (inputData) => {
    const Email = sessionStorage.getItem('Email')

    const { appUrl, changepass } = ApiConfig;
    const url = appUrl + changepass;
    const params = {
      "Email": Email,
      "oldPassword": inputData?.oldPass,
      "newPassword": inputData?.newPass,
      "cPassword": inputData?.confPass
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);

  },

  Teamdata: async (formData) => {
    const { appUrl, Teamdata } = ApiConfig;
    const url = appUrl + Teamdata;
    const params = {

    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);

  },
  ViewAdminProfile: async () => {
    const { varfiyAdmin, ViewAdminProfile } = ApiConfig;
    const url = varfiyAdmin + ViewAdminProfile;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);

  },
  AddBankacc: async (formData) => {
    const { varfiyBank, AddBankacc } = ApiConfig;
    const url = varfiyBank + AddBankacc;
    const params = {

    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);

  },
  AddPan: async (formData) => {
    const { varfiyBank, AddPan } = ApiConfig;
    const url = varfiyBank + AddPan;
    const params = {

    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);

  },
  editPANdtl: async (formData) => {
    const { varfiyBank, editPANdtl } = ApiConfig;
    const url = varfiyBank + editPANdtl;
    const params = {

    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);

  },
  editbnkdtl: async (formData) => {
    const { varfiyBank, editbnkdtl } = ApiConfig;
    const url = varfiyBank + editbnkdtl;
    const params = {

    };
    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);

  },

  ViewBanner: async () => {
    const { varfiyAdmin, ViewBanner } = ApiConfig;
    const url = varfiyAdmin + ViewBanner;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  Viewbankdetails: async (id) => {
    const { varfiyBank, Viewbankdetails } = ApiConfig;
    const url = varfiyBank + Viewbankdetails;
    const params = {
      UserId: id
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  verifyPan: async (id, status, userid) => {
    const { varfiyBank, verifyPan } = ApiConfig;
    const url = varfiyBank + verifyPan;
    const params = {
      _id: id,
      UserId: userid,
      "panverified": status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  verifyBank: async (id, status, userid) => {
    const { varfiyBank, verifyBank } = ApiConfig;
    const url = varfiyBank + verifyBank;
    const params = {
      _id: id,
      UserId: userid,
      "bankverified": status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },


  listPan: async (status) => {
    const { varfiyBank, listPan } = ApiConfig;
    const url = varfiyBank + listPan;
    const params = { status };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },



  listBank: async () => {
    const { varfiyBank, listBank } = ApiConfig;
    const url = varfiyBank + listBank;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  ListAadhar: async (kycstatus) => {

    const url = ApiConfig.varfiyBank + `adharlist/${kycstatus}`;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  upilist: async () => {
    const { varfiyBank, upilist } = ApiConfig;
    const url = varfiyBank + upilist;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  dashboardData: async () => {
    const { appUrl, dashboardData } = ApiConfig;
    const url = appUrl + dashboardData;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  getWithdrawal: async (type) => {
    const { baseWithdraw, getWithdrawal } = ApiConfig;
    const url = baseWithdraw + getWithdrawal + `/${type}`;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },

  approveWithdrawal: async (withdrawalId, status) => {
    const { baseWithdraw, approveWithdrawal } = ApiConfig;
    const url = baseWithdraw + approveWithdrawal;
    const params = { withdrawalId, status };
    const headers = { "Content-Type": "application/json" };
    return ApiCallPost(url, params, headers);
  },

  getUserWithdrawal: async (user_id,) => {
    const { baseWithdraw, getUserWithdrawal } = ApiConfig;
    const url = baseWithdraw + getUserWithdrawal;
    const params = { user_id };
    const headers = { "Content-Type": "application/json" };
    return ApiCallPost(url, params, headers);
  },

  peruserDetails: async (id) => {
    const { baseReports, peruserDetails } = ApiConfig;
    const url = baseReports + peruserDetails + `/${id}`;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  walletManager: async (id) => {
    const { baseReports, walletManager } = ApiConfig;
    const url = baseReports + walletManager;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  AllTransactions: async (id, type, mobile) => {
    const { baseReports, AllTransactions } = ApiConfig;
    const url = baseReports + AllTransactions + `?transaction_type=${type}&user_id=${id}&mobile_number=${mobile}`

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },


  TDSList: async () => {
    const { baseReports, TDSList } = ApiConfig;
    const url = baseReports + TDSList

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },


  TDSFilled: async (_id) => {
    const { baseReports, TDSFilled } = ApiConfig;
    const url = baseReports + TDSFilled

    const params = { id: _id }

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  allnftlist: async () => {
    const { appUrl, allnftlist } = ApiConfig;
    const url = appUrl + allnftlist;
    const params = {

    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  collection: async () => {
    let userId = sessionStorage.getItem('userId')
    const { appUrl, collection } = ApiConfig;
    const url = appUrl + collection;
    const params = {
      _id: userId
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  createCollection: async (formData) => {
    const { appUrl, createCollection } = ApiConfig;
    const url = appUrl + createCollection;

    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    return ApiCallPost(url, formData, headers);
  },
  setnftstatus: async (id, status, email) => {
    const { appUrl, setnftstatus } = ApiConfig;
    const url = appUrl + setnftstatus;
    const params = {
      "NFT_Id": id,
      "status": status,
      "email_or_phone": email,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  inctvCollection: async (id, status, email) => {
    const { appUrl, inctvCollection } = ApiConfig;
    const url = appUrl + inctvCollection;
    const params = {
      "collection_id": id,
      "status": status,
      "email_or_phone": email,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  deleteNft: async (id, status) => {
    const { appUrl, deleteNft } = ApiConfig;
    const url = appUrl + deleteNft;
    const params = {
      "_id": id,
      "status": status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  wallet_Manager: async () => {
    const { appUrl, wallet_Manager } = ApiConfig;
    const url = appUrl + wallet_Manager;
    const params = {
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },
  setBonus: async (amount, status, id) => {
    const { baseReports, setBonus } = ApiConfig;
    const url = baseReports + setBonus;
    const params = {
      "userid": id,
      "cash_bonus": +amount,
      "trnsStats": status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  setWningamnt: async (amount, status, id) => {
    const { baseReports, setWningamnt } = ApiConfig;
    const url = baseReports + setWningamnt;
    const params = {
      "userid": id,
      "winning_amnt": +amount,
      "trnsStats": status
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  VerifyAadhar: async (id, status, userId) => {
    const { varfiyBank, VerifyAadhar } = ApiConfig;
    const url = varfiyBank + VerifyAadhar;
    const params = {
      _id: id,
      adhar_verified: status,
      UserId: userId,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },
  veriupi: async (id, status, userId) => {
    const { varfiyBank, veriupi } = ApiConfig;
    const url = varfiyBank + veriupi;
    const params = {
      _id: id,
      upi_verified: status,
      UserId: userId,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  dltbankdetails: async (id, userId) => {
    const { varfiyBank, dltbankdetails } = ApiConfig;
    const url = varfiyBank + dltbankdetails;
    const params = {
      _id: id,
      userId: userId,
      bankverified: '0'
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },



  ViewUser: async (userId, fName, lName, email, gender, number, address) => {
    const { varfiyAdmin, ViewUser } = ApiConfig;
    const url = varfiyAdmin + ViewUser;
    const params = {
      '_id': userId,
      "FirstName": fName,
      "LastName": lName,
      "Email": email,
      "Gender": gender,
      "MobileNo": number,
      "Address": address
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  Content: async (title, content) => {
    const { varfiyAdmin, Content } = ApiConfig;
    const url = varfiyAdmin + Content;
    const params = {
      "Title": title,
      "content": content
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },

  Findcontest: async () => {
    const { varfiyAdmin, Findcontest } = ApiConfig;
    const url = varfiyAdmin + Findcontest;

    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallGet(url, headers);
  },


  updateBannerImage: async (formData) => {
    const authorizationToken = sessionStorage.getItem("refreshToken");

    const { appUrl, uploads } = ApiConfig;

    const url = appUrl + uploads;

    const headers = {
      'Content-Type': 'multipart/form-data',
      'Authorization': authorizationToken
    };

    return ApiCallPost(url, formData, headers);
  },

  // updateBannerImage: async (formData) => {
  //   const authorizationToken = sessionStorage.getItem("refreshToken");

  //   const { appUrl, uploads } = ApiConfig;

  //   const url = appUrl + uploads;

  //   const headers = {
  //     'Content-Type': 'multipart/form-data',
  //     'Authorization': authorizationToken
  //   };

  //   return ApiCallPost(url, formData, headers);
  // },

  addIfpsDetails: async (description, PropertiesData, collectionName, featuredImage) => {
    const authorizationToken = sessionStorage.getItem("refreshToken");
    const { appUrl, addIfps } = ApiConfig;
    const url = appUrl + addIfps;

    function genHexString(len) {
      const hex = '0123456789ABCDEF';
      let output = '';
      for (let i = 0; i < len; ++i) {
        output += hex.charAt(Math.floor(Math.random() * hex.length));
      }
      return output;
    }

    let raj = genHexString(80)

    console.log(raj, ':RajAuth');
    const params = {
      data: [
        {
          path: `${raj}.json`,
          content: {
            name: collectionName,
            description: description,
            image: featuredImage,
            attributes: PropertiesData
          }
        }
      ]

    }
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': authorizationToken
    };
    return ApiCallPost(url, params, headers);
  },




  deleteNotification: async (id) => {
    const { varfiyAdmin, deleteNotification } = ApiConfig;
    const url = varfiyAdmin + deleteNotification;
    const params = {
      _id: id
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },


  createNft: async (walletNetwork, price, minimumBid, startingDate, expirationDate, totalExpirationDate, collectionName, collection, description, royalties, PropertiesData, fixedPrice, checkboxButton, featuredImage, tokenId, walletAddress, uploadIfpsData) => {
    const userId = sessionStorage.getItem('userId')
    const authorizationToken = sessionStorage.getItem("refreshToken");
    const { appUrl, nftCreate } = ApiConfig;
    const url = appUrl + nftCreate;
    const params = {
      user_id: userId,
      wallet_network: walletNetwork,
      price: +price,
      minimum_bid: minimumBid,
      auction_start_date: startingDate,
      auction_end_date: expirationDate,
      expiration_time: totalExpirationDate,
      name: collectionName,
      collection_id: collection,
      description: description,
      royality: royalties,
      attributes: PropertiesData,
      sell_type: fixedPrice,
      put_on_marketplace: checkboxButton,
      to_address: walletAddress,
      royalty_recipient_address: walletAddress,
      file: featuredImage,
      token_address: walletAddress,
      token_id: tokenId,
      metadata: uploadIfpsData,

    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': authorizationToken
    };
    return ApiCallPost(url, params, headers);
  },


  login: async (signId, password) => {
    const { appUrl, login } = ApiConfig;
    const url = appUrl + login;
    const params = {
      Email: signId,
      password: password
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  editNotification: async (id, title, notification) => {
    const { varfiyAdmin, editNotification } = ApiConfig;
    const url = varfiyAdmin + editNotification;
    const params = {
      _id: id,
      Title: title,
      message: notification
    };
    const headers = {
      'Content-Type': 'application/json',
    };
    return ApiCallPost(url, params, headers);
  },

  getCreatedNftData: async () => {
    const authorizationToken = sessionStorage.getItem("refreshToken");

    const { appUrl, allnftCreated } = ApiConfig;
    const url = appUrl + allnftCreated;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': authorizationToken,
    };
    return ApiCallGet(url, headers);
  },
  viewReportedNfts: async () => {
    const authorizationToken = sessionStorage.getItem("refreshToken");

    const { appUrl, viewReportedNfts } = ApiConfig;
    const url = appUrl + viewReportedNfts;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': authorizationToken,
    };
    return ApiCallGet(url, headers);
  },
  reportedcollection: async () => {
    const authorizationToken = sessionStorage.getItem("refreshToken");

    const { appUrl, reportedcollection } = ApiConfig;
    const url = appUrl + reportedcollection;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': authorizationToken,
    };
    return ApiCallGet(url, headers);
  },

  cancelMatch: async (id) => {
    const authorizationToken = sessionStorage.getItem("refreshToken");
    const { appUrl, cancelMatch } = ApiConfig;
    const url = appUrl + cancelMatch + `/${id}`;
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': authorizationToken,
    };
    return ApiCallPost(url, {}, headers);
  },

  delete_adhar: async (id, status, userId) => {
    const { varfiyBank, dltadhar } = ApiConfig;
    const url = varfiyBank + dltadhar;
    const params = {
      _id: id,
      adhar_verified: status,
      UserId: userId,
    };
    const headers = {
      "Content-Type": "application/json",
    };
    return ApiCallPost(url, params, headers);
  },






};











export default AuthService;
