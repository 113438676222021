import React, { useEffect, useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import { $ } from 'react-jquery-plugin';

const WalletManager = () => {
    const [walletTrans, setWalletTrans] = useState([]);
    const [walletTrans2, setWalletTrans2] = useState([]);
    const [userDetails, setUserDetails] = useState({});
    const [amount, setAmount] = useState("");
    const [transType, setTransType] = useState("Add");
    console.log(userDetails, 'userDetails');

    const getWalletTrans = async () => {
        await AuthService.walletManager().then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Wallet Trans Fetched Successfully!!')
                    setWalletTrans(result?.data)
                    setWalletTrans2(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    const handleWinningAmmount = async () => {
        await AuthService.setWningamnt(amount, transType, userDetails?._id).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Winning Amount updated Successfully!!')
                    setUserDetails({})
                    setAmount("")
                    setTransType("Add")
                    getWalletTrans()
                    $('#editwallrtlmodal').modal('hide');
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    const handleBonusAmmount = async () => {
        await AuthService.setBonus(amount, transType, userDetails?._id).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Winning Amount updated Successfully!!')
                    setUserDetails({})
                    setAmount("")
                    setTransType("Add")
                    getWalletTrans()
                    $('#editbonusModal').modal('hide');
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };



    function searchFilter(userInput) {
        const keysToSearch = ["_id", "mobile_number", "username"];
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = walletTrans2.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setWalletTrans(matchingObjects);
    };


    useEffect(() => {
        getWalletTrans()
    }, []);
    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-wallet-3-line"></i></div>
                                            Wallet Manager
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className=" filter_bar mb-4 mb-md-5">
                                    <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form" action="/admin/users/referraldata">
                                        <div className="row gx-2 align-items-center">
                                            <div className="form-group  mb-0 col-5">
                                                <div className="input text">
                                                    <input type="tel" name="phone" className="form-control" placeholder="Search UserId, User name, Mobile" id="search" onChange={(e) => searchFilter(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>User Id</th>
                                            <th>User name</th>
                                            <th>Mobile</th>
                                            <th>Total Amount</th>
                                            {/* <th>Total Balance</th> */}
                                            <th>Total Deposit</th>
                                            {/* <th>Total Trans Amount</th> */}
                                            <th>Cash Bonus</th>
                                            <th>Winning Amount</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {walletTrans?.map((data, index) => {
                                            const winningAmount = parseFloat(data?.winning_amount) || 0;
                                            const cashBonus = parseFloat(data?.cash_bonus) || 0;
                                            const totalDeposit = parseFloat(data?.totaldeposit) || 0;
                                            const totalAmount = (winningAmount + cashBonus + totalDeposit).toFixed(2);
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{data?._id}</td>
                                                    <td>{data?.username}</td>
                                                    <td>{data?.mobile_number}</td>
                                                    <td>{totalAmount}</td>
                                                    {/* <td>{parseFloat(data?.total_balance?.toFixed(2))}</td> */}
                                                    <td>{parseFloat(data?.totaldeposit?.toFixed(2))}</td>
                                                    {/* <td>{parseFloat(data?.total_Trans_amnt?.toFixed(2))}</td> */}
                                                    <td>  <div className=" d-flex justify-content-between align-items-center" >
                                                        {parseFloat(data?.cash_bonus?.toFixed(2))}
                                                        <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editbonusModal" onClick={() => setUserDetails(data)}>Edit</button>
                                                    </div></td>
                                                    <td>
                                                        <div className=" d-flex justify-content-between align-items-center" >
                                                            {data?.winning_amount.toFixed(2)}
                                                            <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editwallrtlmodal" onClick={() => setUserDetails(data)}>Edit</button>
                                                        </div>
                                                    </td>


                                                    {/* <td>
                                                        <div className=" d-flex justify-content-between align-items-center" >
                                                            0
                                                            <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editwallrtlmodal">Edit</button>
                                                        </div>
                                                    </td> */}
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>




                </main>
            </div>

            {/* Winning amount update modal */}
            <div className="modal" id="editwallrtlmodal" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">Update Winning Amount</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">

                            <div className="form-group row align-items-center   mb-1">
                                <div className="col-6">
                                    <label className="small  me-2">User Mobile : </label>
                                </div>
                                <div className="col-6">
                                    <strong>{userDetails?.mobile_number}</strong>
                                </div>
                            </div>
                            <div className="form-group row align-items-center   mb-1">
                                <div className="col-6">
                                    <label className="small  me-2">User Winning Amount : </label>
                                </div>
                                <div className="col-6">
                                    <strong>{userDetails?.winning_amount}</strong>
                                </div>
                            </div>
                            <br />
                            <form>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Amount </label>
                                    <input className="form-control  input-copy" type="number" placeholder="Enter Amount" onChange={(e) => setAmount(e.target.value)} value={amount} onWheel={(e) => e.target.blur()} />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <select name="up_type" id="up_type" className="form-control form-select" onChange={(e) => setTransType(e.target.value)} value={transType}>
                                        <option value="Add">Add</option>
                                        <option value="Deduct">Deduct</option>
                                    </select>
                                </div>
                                <div className="form-group  position-relative mt-4">
                                    <button className="btn btn-primary   btn-block w-100" type="button" onClick={handleWinningAmmount}>Update Winning Amount</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            {/* Bonus update modal */}
            <div className="modal" id="editbonusModal" tabIndex="-1" aria-labelledby="editbonusModalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editbonusModal">Update Bonus Amount</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">

                            <div className="form-group row align-items-center   mb-1">
                                <div className="col-6">
                                    <label className="small  me-2">User Mobile : </label>
                                </div>
                                <div className="col-6">
                                    <strong>{userDetails?.mobile_number}</strong>
                                </div>
                            </div>
                            <div className="form-group row align-items-center   mb-1">
                                <div className="col-6">
                                    <label className="small  me-2">User Cash Bonus : </label>
                                </div>
                                <div className="col-6">
                                    <strong>{userDetails?.cash_bonus}</strong>
                                </div>
                            </div>
                            <br />
                            <form>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Amount </label>
                                    <input className="form-control  input-copy" type="number" placeholder="Enter Amount" onChange={(e) => setAmount(e.target.value)} value={amount} onWheel={(e) => e.target.blur()} />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    <select name="up_type" id="up_type" className="form-control form-select" onChange={(e) => setTransType(e.target.value)} value={transType}>
                                        <option value="Add">Add</option>
                                        <option value="Deduct">Deduct</option>
                                    </select>
                                </div>
                                <div className="form-group  position-relative mt-4">
                                    <button className="btn btn-primary   btn-block w-100" type="button" onClick={handleBonusAmmount}>Update Bonus Amount</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}

export default WalletManager
