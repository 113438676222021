import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const List = () => {
    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-bank-card-line"></i></div>
                                            Payment Offers List
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div className="container-xl px-4 mt-n10">

                        <div className="card mb-4">

                            <div className="card-body">
                                <div className=" filter_bar mb-4 mb-md-5">
                                    <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form" action="/admin/users/referraldata">
                                        <div className="row gx-2 align-items-center">

                                            {/* <div className="form-group mb-0 col text-end">
                                    <div className="input text">
                                        <label className="me-3">Filter</label>
                                    </div>
                                </div> */}
                                            <div className="form-group  mb-0 col">
                                                <div className="input text">
                                                    <select name="series" className="form-control form-select series">
                                                        <option value="">Select Series</option>
                                                        <option value="126662">---</option>
                                                   
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group  mb-0 col">
                                                <div className="input text">
                                                    <input type="date" name="end_date" className="form-control datepicker-input end_date" placeholder="To" id="end-date" />
                                                </div>
                                            </div>
                                            <div className="form-group  mb-0 col">
                                                <div className="input text">
                                                    <input type="date" name="end_date" className="form-control datepicker-input end_date" placeholder="To" id="end-date" />
                                                </div>
                                            </div>
                                            <div className="form-group  mb-0 col">
                                                <div className="row gx-2" >
                                                    <div className="col" >
                                                        <button type="button" className="btn btn-primary submit px-5 w-100 btn-block">Search</button>
                                                    </div>
                                                    <div className="col" >
                                                        <a href="#" className="btn btn-dark px-4  w-100 btn-block"><i className="fa fa-undo me-2"></i> Reset</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Match Name <br /> Series Name</th>
                                            <th>Amount Collected <br /> (AC)</th>
                                            <th>Amount Used</th>
                                            <th>Winning Distribution (WD)</th>
                                            <th>Winning</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>User Mobile</th>
                                            <th>User Email</th>
                                            <th>Deposit Amount</th>
                                            <th>Bonus Amount</th>
                                            <th>Winning Amount</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td><a href="#">---</a></td>
                                            <td>---</td>
                                            <td>
                                                <div className=" d-flex justify-content-between align-items-center" >
                                                    0
                                                    <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editwallrtlmodal">Edit</button>
                                                </div>
                                            </td>

                                            <td>
                                                <div className=" d-flex justify-content-between align-items-center" >
                                                    0
                                                    <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editwallrtlmodal">Edit</button>
                                                </div>
                                            </td>

                                            <td>
                                                <div className=" d-flex justify-content-between align-items-center" >
                                                    0
                                                    <button type="btn" className="btn badge bg-success text-white rounded-pill" data-bs-toggle="modal" data-bs-target="#editwallrtlmodal">Edit</button>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>




                </main>
            </div>


            <div className="modal" id="editwallrtlmodal" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">Update Wallet</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">

                            <div className="form-group row align-items-center   mb-1">
                                <div className="col-6">
                                    <label className="small  me-2">User Mobile :</label>
                                </div>
                                <div className="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>
                            <div className="form-group row align-items-center   mb-1">
                                <div className="col-6">
                                    <label className="small  me-2">User Amount :</label>
                                </div>
                                <div className="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>
                            <div className="form-group row align-items-center  mb-1">
                                <div className="col-6">
                                    <label className="small  me-2">Wallet Type :</label>
                                </div>
                                <div className="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>

                            <br />


                            <form>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Amount </label>
                                    <input className="form-control  input-copy" type="text" placeholder="Enter Amount" value="" />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    {/* <!-- <label className="small mb-1"> Sponcer Username </label> --> */}
                                    <select name="up_type" id="up_type" className="form-control form-select"><option value="1">Add</option><option value="2">Deduct</option></select>
                                </div>
                                <div className="form-group  position-relative mt-4">
                                    <button className="btn btn-primary   btn-block w-100" type="button">Add Referal</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default List
