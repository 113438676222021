import React from 'react'
import Header from '../Header'
import Sidebar from '../Sidebar'

const AddNew = () => {
    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-bank-card-line"></i></div>
                                            Add Payment Offer
                                        </h1>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-header">
                                <h3 className="card-title mb-0">Enter Details</h3> </div>
                            <form enctype="multipart/form-data" method="post" acceptCharset="utf-8" validaterset="validaterset" id="offerForm" action="/admin/payment-offers/add">

                                <div className="card-body ">
                                    <div className="row">
                                        <div className=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div className="input text required">
                                                <label for="coupon-code">Coupon Code <span className="required">*</span></label>
                                                <input type="text" name="coupon_code" className="form-control" placeholder="Coupon Code" maxLength="15" required="required" id="coupon-code" />
                                            </div> <small>Provide 15-Digit coupon code.</small> </div>
                                        <div className=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div className="input text required">
                                                <label for="min-amount">Minimum Amount (INR) <span className="required">*</span></label>
                                                <input type="text" name="min_amount" className="form-control" placeholder="Minimum Amount" maxLength="10" required="required" id="min-amount" value="0" />
                                            </div> <small>Minimum amount to be available for the offer.</small> </div>
                                        <div className=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div className="input text required">
                                                <label for="max-cashback-amount">Maximum Cashback Amount (INR) <span className="required">*</span></label>
                                                <input type="text" name="max_cashback_amount" className="form-control" placeholder="Maximum Cashback Amount" maxLength="10" required="required" id="max-cashback-amount" value="0" />
                                            </div>
                                        </div>
                                        <div className=" col-xs-6 col-sm-6 col-md-4  form-group">
                                            <div className="input text required">
                                                <label for="max-cashback-percent">Cashback Percentage (%) <span className="required">*</span></label>
                                                <input type="text" name="max_cashback_percent" className="form-control" placeholder="Cashback Percentage" maxLength="5" required="required" id="max-cashback-percent" value="0" />
                                            </div>
                                        </div>
                                        <div className=" col-xs-6 col-sm-6 col-md-4  form-group">
                                            <div className="input text required">
                                                <label for="usage-limit">Usage Limit <span className="required">*</span></label>
                                                <input type="text" name="usage_limit" className="form-control usage_limit" placeholder="Usage Limit" maxLength="10" required="required" id="usage-limit" value="Unlimited" readonly="readonly" />
                                            </div>
                                            <div className="position-relative form-check">
                                                <input name="check" id="Check_use_limit" type="checkbox" className="form-check-input" checked="" />
                                                <label for="Check_use_limit" className="form-check-label">Unlimited</label>
                                            </div>
                                        </div>
                                        <div className=" col-xs-6 col-sm-6 col-md-4  form-group">
                                            <div className="input text required">
                                                <label for="per-user-limit">Limit per User <span className="required">*</span></label>
                                                <input type="text" name="per_user_limit" className="form-control" placeholder="Limit per User" maxLength="10" required="required" id="per-user-limit" />
                                            </div>
                                        </div>
                                        <div className=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div className="input text required">
                                                <label for="expiry-date">Expiry Date <span className="required">*</span></label>
                                                <input type="text" name="expiry_date" className="form-control expiry_date" placeholder="Expiry Date" readonly="readonly" required="required" id="expiry-date" />
                                            </div>
                                        </div>
                                        <div className=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div className="input select required">
                                                <label for="status">Status <span className="required">*</span></label>
                                                <select name="status" className="form-control form-select" required="required" id="status">
                                                    <option value="">Select Status</option>
                                                    <option value="1" selected="selected">Active</option>
                                                    <option value="0">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className=" col-xs-6 col-sm-6 col-md-4 form-group">
                                            <div className="input select">
                                                <label for="wallets">Wallet <span className="required">*</span></label>
                                                <select name="wallets" className="form-control  form-select" id="wallets">
                                                    <option value="">Select Walets</option>
                                                    <option value="1">Cash</option>
                                                    <option value="0" selected="selected">Winning</option>
                                                    <option value="2">Bonus</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="submit" className="btn btn-primary submit px-5">Submit</button>
                                </div>

                            </form>



                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}

export default AddNew
