import React, { useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import Header from '../Header'
import Sidebar from '../Sidebar'

const AddNewUser = () => {
    const [fName, setfName] = useState('');
    const [lName, setlName] = useState('');
    const [email, setemail] = useState('');
    const [number, setnumber] = useState('');
    const [password, setpassword] = useState('');
    const [confPassword, setconfPassword] = useState('');
    const [gender, setgender] = useState('');
    const [userName, setuserName] = useState('');


    const handleReset = () => {

        setfName('');
        setlName('');
        setemail('');
        setnumber('');
        setpassword('');
        setconfPassword('');
        setuserName('');
        setgender('');

    }

    const addUser = async (fName, lName, email, password, confPassword, userName) => {
        if (fName && lName && email && password && confPassword && userName) {
            await AuthService.addUser(fName, lName, email, password, confPassword, userName).then((result) => {
                if (result?.success) {
                    try {
                        alertSuccessMessage("User added successfully!!");
                        handleReset()
                    } catch {
                        alertErrorMessage();
                    }
                } else {
                    alertErrorMessage(result?.message);
                }
            })
        }
        else {
            alertWarningMessage('Please fill all the details')
        }
    };
    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-user-add-line"></i></div>
                                            Add New User
                                        </h1>
                                        {/* <!-- <div className="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content-->
                <!-- Example Colored Cards for Dashboard Demo--> */}
                    <div className="container-xl px-4 mt-n10">

                        <div className="card card-primary">
                            <div className="card-header">
                                <h3 className="card-title">Enter Details Form</h3> </div>
                            <form enctype="multipart/form-data" method="post" acceptCharset="utf-8" validaterset="validaterset" id="userForm" action="/admin/users/add">
                                <div style={{ display: 'none' }}>
                                    <input type="hidden" name="_method" value="POST" />
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <label for="userName">User Name</label>
                                                        <input type="text" name="userName" className="form-control" placeholder="User Name" id="userName" value={userName} onChange={(e) => { setuserName(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <label for="email">Email / Phone Number <span className="required">*</span></label>
                                                        <input type="email" name="email" className="form-control" placeholder="Email / Phone Number" maxLength="255" id="email" value={email} onChange={(e) => { setemail(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <label for="first-name">First Name <span className="required">*</span></label>
                                                        <input type="text" name="first_name" maxLength="30" className="form-control" placeholder="First Name" id="first-name" value={fName} onChange={(e) => { setfName(e.target.value) }} />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <label for="first-name">Last Name <span className="required">*</span></label>
                                                        <input type="text" name="first_name" maxLength="30" className="form-control" placeholder="First Name" id="first-name" value={lName} onChange={(e) => { setlName(e.target.value) }} />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <label for="password">Password <span className="required">*</span></label>
                                                        <input type="password" name="password" className="form-control" placeholder="Password" id="password" value={password} onChange={(e) => { setpassword(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-6">
                                                    <div className="form-group">
                                                        <label for="password">Confirm Password <span className="required">*</span></label>
                                                        <input type="password" name="password" className="form-control" placeholder="Password" id="password" value={confPassword} onChange={(e) => { setconfPassword(e.target.value) }} />
                                                    </div>
                                                </div>
                                                {/* <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <label for="gender">Gender</label>
                                                            <select name="gender" className="form-control form-select " id="gender" value={gender} onChange={(e) => {setgender(e.target.value) }}>
                                                                <option value="">Select Gender</option>
                                                                <option value="Male" selected="selected">Male</option>
                                                                <option value="Female">Female</option>
                                                            </select>
                                                        </div>
                                                    </div> */}

                                                {/* <div className="col-sm-6 col-md-6">
                                                        <div className="form-group">
                                                            <label for="phone">Phone Number <span className="required">*</span></label>
                                                            <input type="number" name="phone" className="form-control" placeholder="Phone Number" maxLength="20" id="phone" value={number} onChange={(e) => { setnumber(e.target.value) }} />
                                                        </div>
                                                    </div> */}


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="button" className="btn btn-primary submit px-5" onClick={() => { addUser(fName, lName, email, password, confPassword, userName) }}>Submit</button>
                                </div>
                            </form>
                        </div>

                    </div>




                </main>
            </div>
        </>
    )
}

export default AddNewUser
