import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import Header from '../Header'
import Sidebar from '../Sidebar'

const SmsNotification = () => {
    const [mobile, setMobile] = useState('');
    const [title, settitle] = useState('');
    const [message, setmessage] = useState('');
    const [userId, setuserId] = useState();
    const [notificationList, setnotificationList] = useState([]);

    useEffect(() => {

        getNotificationList()

    }, []);

    const addNotification = async (mobile, title, message) => {
        if (title && message && mobile) {
            await AuthService.MobileNotification(mobile, title, message).then((result) => {
                if (result?.success) {
                    try {
                        alertSuccessMessage('Notification Added!!');
                        setMobile('')
                        setmessage('')
                        settitle('')
                        getNotificationList()

                    } catch {
                        alertErrorMessage();
                    }
                } else {
                    alertErrorMessage(result?.message);
                }
            })
        }
        else {
            alertWarningMessage('Please fill all required fields')
        }
    };

    const getNotificationList = async () => {

        await AuthService.getmobileNotificationList().then((result) => {
            if (result?.success) {
                try {
                    setnotificationList(result?.data)

                } catch {
                    alertErrorMessage();
                }
            } else {
                alertErrorMessage(result?.message);
            }
        })
    };

    const handleEditDetails = (data) => {
        setuserId(data?._id)
        setMobile(data?.MobileNo)
        settitle(data?.Subject)
        setmessage(data?.message)
    };
    const EditNotification = async (id, mobile, title, message) => {

        await AuthService.EditMobileNoti(id, mobile, title, message).then((result) => {
            if (result?.success) {
                try {
                    getNotificationList()
                    setMobile('')
                    setmessage('')
                    settitle('')

                } catch {
                    alertErrorMessage();
                }
            } else {
                alertErrorMessage(result?.message);
            }
        })
    };
    /* custom pagination */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = Math.ceil(notificationList.length / itemsPerPage);
    const currentItems = notificationList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // custom paginatio ends
    return (
        <>

                <div id="layoutSidenav_content">
                    <main>
                        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div className="container-xl px-4">
                                <div className="page-header-content pt-4">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto mt-4">
                                            <h1 className="page-header-title">
                                                <div className="page-header-icon"><i className="ri-notification-3-line"></i></div>
                                                SMS Notifications
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main page content--> */}
                        <div className="container-xl px-4 mt-n10">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h3 className="card-title mb-0">Enter Details</h3> </div>

                                <form method="post" acceptCharset="utf-8" validaterset="validaterset" id="notificationForm" action="">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <div className="input Number">
                                                        <label for="mobile">To <span className="required">*</span></label>
                                                        <input type="tel" name="email" className="form-control" placeholder="Number" maxLength="255" id="Number" value={mobile} onChange={(e) => { setMobile(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input text">
                                                        <label for="subject">Subject <span className="required">*</span></label>
                                                        <input type="text" name="subject" className="form-control" placeholder="subject" maxLength="20" id="subject" value={title} onChange={(e) => { settitle(e.target.value) }} />
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label>Message <span className="required">*</span></label>
                                                    <div className="input textarea">
                                                        <textarea name="message" className="form-control" rows="3" maxLength="20" id="message" value={message} onChange={(e) => { setmessage(e.target.value) }} ></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="button" className="btn btn-primary submit" onClick={() => { addNotification(mobile, title, message) }}>Send SMS</button>
                                    </div>
                                </form>

                            </div>
                            <div className="card mb-4">

                                <div className="card-body">

                                    <table id="datatablesSimple">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>To</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                                <th>Date</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>#</th>
                                                <th>To</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                                <th>Date</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            {currentItems ? currentItems?.map((data, index) => {
                                                let objIndex;
                                                objIndex = notificationList.findIndex(check => check?._id == data._id);
                                                return (
                                                    <tr>
                                                        <td>{objIndex + 1}</td>
                                                        <td>{data?.MobileNo}</td>
                                                        <td>{data?.Subject}</td>
                                                        <td>{data?.message}</td>
                                                        <td><Moment date={data?.createdAt} format="YYYY/MM/DD HH:mm" /></td>
                                                        <td>
                                                            <div className="table_action_group">
                                                                <button type="btn" className="btn badge bg-success text-white rounded-pill"
                                                                    data-bs-toggle="modal" data-bs-target="#editNotification"
                                                                    onClick={() => { handleEditDetails(data) }}
                                                                >Edit</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''}
                                        </tbody>
                                    </table>
                                    {/* custom pagination */}
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        containerClassName={'customPagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </div>



                        </div>
                    </main>
                </div>

            <div className="modal" id="editNotification" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">Edit User Details</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">
                            <form method="post" acceptCharset="utf-8" validaterset="validaterset" id="notificationForm" action="">

                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card-body">
                                            <div className="form-group">
                                                <div className="input Number">
                                                    <label for="mobile">To </label>
                                                    <input type="tel" name="email" className="form-control" placeholder="Number" maxLength="255" id="Number" value={mobile} onChange={(e) => { setMobile(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="input text">
                                                    <label for="subject">Subject </label>
                                                    <input type="text" name="subject" className="form-control" placeholder="subject" maxLength="20" id="subject" value={title} onChange={(e) => { settitle(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Message </label>
                                                <div className="input textarea">
                                                    <textarea name="message" className="form-control" rows="3" maxLength="20" id="message" value={message} onChange={(e) => { setmessage(e.target.value) }} ></textarea>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="button" data-bs-dismiss="modal" className="btn btn-primary submit" onClick={() => { EditNotification(userId, mobile, title, message) }} >Edit SMS</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SmsNotification
