import React, { useEffect, useState } from 'react'
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';

const WithdrawDetails = () => {
    const { id, from } = useParams()
    console.log(id, from, 'fromfrom');
    const [withdrawList, setWithdrawList] = useState([]);
    const [withdrawList2, setWithdrawList2] = useState([]);
    const [userDetails, setUserDetails] = useState({});

    const getWithdrawDetails = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.getUserWithdrawal(id)
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                setWithdrawList(result?.data);
                setWithdrawList2(result?.data);
                setUserDetails(result?.data[0]?.UserData)
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error.message);
        }
    };


    const handleWithdStatus = async (id, status) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.approveWithdrawal(id, status)
            alertSuccessMessage(result?.message)
            getWithdrawDetails()
            LoaderHelper.loaderStatus(false);
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error.message);
        }
    };

    function searchFilter(userInput) {
        const keysToSearch = ["_id"];
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = withdrawList2.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setWithdrawList(matchingObjects);
    };


    useEffect(() => {
        getWithdrawDetails()
    }, []);


    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-arrow-up-down-line"></i></div>
                                            Withdraw Details
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className='card-header py-2 d-flex align-items-center justify-content-between' >
                                <div>
                                    <a className='btn btn-icon' href={`/${from}`}  > <i className="ri-arrow-left-line"></i> </a> Back
                                </div>
                            </div>
                            <div className="card-body">
                                <div className=" filter_bar mb-4 mb-md-5 ">
                                    <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form " action="/admin/users/referraldata">
                                        <div className="row gx-2 align-items-center">
                                            <div className="form-group  mb-0 col-5">
                                                <div className="input text">
                                                    <input type="tel" name="phone" className="form-control" placeholder="Withdrawal ID" id="search" onChange={(e) => searchFilter(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className='row mt-2'>
                                        <div >User Id : <strong>{id}</strong> </div>
                                        <div>User Mobile : <strong>{userDetails?.mobile_number}</strong> </div>
                                        <div>User Name : <strong>{userDetails?.username}</strong> </div>
                                    </div>
                                </div>

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Requested Date</th>
                                            <th> Withdrawal ID</th>
                                            <th>User name</th>
                                            <th>Phone</th>
                                            <th>Account Number</th>
                                            <th>IFSC</th>
                                            <th>Amount</th>
                                            <th>TDS Deduct</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {withdrawList?.map((data, index) => {
                                            return (
                                                <tr key={data?._id}>
                                                    <td>{index + 1}</td>
                                                    <td><Moment data={data?.createdAt} format="DD/MM/YY hh:mm" /></td>
                                                    <td> {data?._id}</td>
                                                    <td>{data?.UserData?.username}</td>
                                                    <td>{data?.UserData?.mobile_number}</td>
                                                    <td>{data?.accountnumber}</td>
                                                    <td>{data?.ifsc}</td>
                                                    <td>{data?.amount}</td>
                                                    <td>{data?.tdsdeducted}</td>
                                                    <td>
                                                        {data?.wihdrawstatus == 2 ?
                                                            <div className="table_action_group">
                                                                <button type="btn" className="btn badge bg-success text-white rounded-pill" onClick={() => handleWithdStatus(data?._id, 1)}>Approve </button>
                                                                <button type="btn" className="btn badge bg-danger text-white rounded-pill" onClick={() => handleWithdStatus(data?._id, 3)}>Reject</button>
                                                            </div> : data?.wihdrawstatus == 1 ? <span className='text-success'>Approved</span> : <span className='text-danger'>Rejected</span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}

export default WithdrawDetails
