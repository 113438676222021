import React, { useEffect, useState } from 'react'
import Swal from 'sweetalert2';
import { ApiConfig } from '../../api/apiConfig/ApiConfig';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import Header from '../Header'
import Sidebar from '../Sidebar'

const Profile = () => {
    const [fName, setfName] = useState('');
    const [lName, setlName] = useState('');
    const [email, setemail] = useState('');
    const [number, setnumber] = useState('');
    const [dob, setdob] = useState('');
    const [gender, setgender] = useState('');
    const [userId, setuserId] = useState('');
    const [profileImage, setprofileImage] = useState();
    const [image, setimage] = useState();

    useEffect(() => {
        ViewAdminProfile()
    }, []);

    const editProfile = async (fName, lName, email, number, dob, gender, profileImage) => {
        await Swal.fire({
            title: 'Do you want to save the changes?',
            icon: 'info',
            showDenyButton: true,
            confirmButtonText: 'Save',
            denyButtonText: `Don't save`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                var formData = new FormData();
                formData.append('_id', userId);
                formData.append('FirstName', fName);
                formData.append('LastName', lName);
                formData.append('Email', email);
                formData.append('MobileNo', number);
                formData.append('dob', dob);
                formData.append('Gender', gender);
                formData.append('imagepath', profileImage);
                await AuthService.EditSubadmin(formData).then((result) => {
                    if (result?.status) {
                        try {
                            alertSuccessMessage('Profile updated successfully!!');
                            ViewAdminProfile()
                            setimage()
                        } catch {
                            alertErrorMessage(result?.message)
                        }
                    } else {
                        alertErrorMessage(result?.message)
                    }
                })
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'error')
            }
        })

    };
    const ViewAdminProfile = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.ViewAdminProfile().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setemail(result?.data?.Email)
                    setfName(result?.data?.FirstName)
                    setgender(result?.data?.Gender)
                    setlName(result?.data?.LastName)
                    setnumber(result?.data?.MobileNo)
                    setdob(result?.data?.dob)
                    setuserId(result?.data?._id)
                    setprofileImage(result?.data?.imagepath)
                } catch {
                    alertErrorMessage(result?.message)
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result?.message)
            }
        })
    };
    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-user-smile-line"></i></div>
                                            Update Profile
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content--> */}
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">

                            <form enctype="multipart/form-data" method="post" acceptCharset="utf-8" validaterset="validaterset" action="/admin/users/profile">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input text">
                                                    <label for="first-name">First Name </label>
                                                    <input type="text" name="first_name" maxLength="40" className="form-control" placeholder="First Name" id="first-name" onChange={(e) => { setfName(e.target.value) }} value={fName} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input text">
                                                    <label for="last-name">Last Name </label>
                                                    <input type="text" name="last_name" maxLength="40" className="form-control" placeholder="Last Name " id="last-name" onChange={(e) => { setlName(e.target.value) }} value={lName} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input email">
                                                    <label for="email">Email </label>
                                                    <input type="email" name="email"
                                                        disabled className="form-control" placeholder="E-Mail Addeess" maxLength="255" id="email" onChange={(e) => { setemail(e.target.value) }} value={email} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input tel">
                                                    <label for="phone">Phone Number </label>
                                                    <input type="number" name="phone" className="form-control" placeholder="Phone Number" maxLength="20" id="phone" onChange={(e) => { setnumber(e.target.value) }} value={number} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input text">
                                                    <label for="date-of-bith">Date of Birth</label>
                                                    <input type="date" name="date_of_bith" className="form-control DOB" placeholder="Date of Birth" maxLength="50" id="date-of-bith" onChange={(e) => { setdob(e.target.value) }} value={dob} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">

                                                <div className="input text">
                                                    <label for="date-of-bith">Profile Image</label>
                                                    <div className="vs_team" style={{ alignItems: 'center', borderRadius: '50%' }}>

                                                        <input type="file" name="date_of_bith" className="form-control DOB" onChange={(e) => {
                                                            setprofileImage(e.target.files[0]);
                                                            setimage(URL.createObjectURL(e.target.files[0]))
                                                        }} />
                                                        <img className='mx-2'
                                                            style={{ borderRadius: '50%' }}
                                                            src={!image && !profileImage ? "/assets/img/logo.png" : image ? image : `${ApiConfig?.appUrl + 'uploads/' + profileImage}`}
                                                            width="50"
                                                            height="50"
                                                            alt=""

                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <div className="input select">
                                                    <label for="gender">Gender</label>
                                                    <select name="gender" className="form-control form-select" id="gender" onChange={(e) => { setgender(e.target.value) }} value={gender}>
                                                        <option value="" selected disabled>Select Gender</option>
                                                        <option value="Male" selected="selected">Male</option>
                                                        <option value="Female">Female</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className="card-footer">
                                    <button type="button" className="btn btn-primary" onClick={() => { editProfile(fName, lName, email, number, dob, gender, profileImage) }}>Submit</button>
                                </div>
                            </form>
                        </div>



                    </div>
                </main>
            </div>

        </>
    )
}

export default Profile
