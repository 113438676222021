
import React, { useEffect, useState } from 'react';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';

const UPIRequest = () => {
    const [list, setlist] = useState();
    const [list2, setlist2] = useState();

    useEffect(() => {
        UpiList()
    }, []);

    const UpiList = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.upilist().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setlist(result?.data)
                    setlist2(result?.data)
                } catch {
                    alertErrorMessage(result?.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const VerifyUPI = async (id, status, userid) => {
        await AuthService.veriupi(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("UPI Status Updated Successfully!!");
                    UpiList()

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                // alertErrorMessage(result.message);
            }
        });
    };

    const filter = (e) => {
        let filterd;
        if (e.target.checked) {
            filterd = list2?.filter((data) => {
                return data?.adhar_verified == 3
            })
            setlist(filterd)
        } else {
            setlist(list2)
        }
    };

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-group-line"></i></div>
                                            UPI Request
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">

                        <div className="card mb-4">

                            <div className="card-body">
                                <div className=" filter_bar mb-4 mb-md-5">
                                    <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form" action="/admin/users/referraldata">
                                        <div className="row gx-2 align-items-center">

                                            <div className="form-group mb-0 col-sm-6 col-md-3">
                                                <div className=" form-check ms-2  " >
                                                    <input className="form-check-input" type="checkbox" id="unverified" onChange={filter} />
                                                    <label className="form-check-label" for="unverified">
                                                        Show Unverified Users
                                                    </label>
                                                </div>
                                            </div>

                                        </div>
                                    </form>
                                </div>


                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Phone Number</th>
                                            <th>UPI ID</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Phone Number</th>
                                            <th>UPI ID</th>
                                            <th>Status</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {list ? list?.map((data, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{data?.data?.mobile_number}</td>
                                                    <td>{data?.upi_number}</td>
                                                    <td>{data?.upi_verified == 2 ?
                                                        <>
                                                            <button type="btn" className="btn badge bg-success text-white rounded-pill"
                                                                onClick={() => { VerifyUPI(data?._id, '1', data?.user_id) }}
                                                            >Approve</button>

                                                            <button type="button" className="mt-2 mx-2   btn badge bg-danger text-white rounded-pill"
                                                                onClick={() => { VerifyUPI(data?._id, '3', data?.user_id) }}> Reject</button>
                                                        </> :
                                                        data?.upi_verified == 1 ? <p className='text-success text-center'>Approved</p> : data?.upi_verified == 3 ? <p className='text-danger text-center'>Rejected</p> : ''

                                                    }</td>
                                                </tr>
                                            )
                                        }) : ''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </main>
            </div>

        </>
    )
}

export default UPIRequest
