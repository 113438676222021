import React, { useEffect, useState } from "react";
import AuthService from '../../api/services/HomeService';
import { Link, useNavigate } from "react-router-dom";
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from "../../Components/Loading/LoaderHelper";

const Login = () => {

    const navigate = useNavigate();
    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');

    const handleLogin = async (email, password) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.login(email, password)
            LoaderHelper.loaderStatus(false);
            if (result.success) {
                alertSuccessMessage(result.message)
                sessionStorage.setItem("refreshToken", result.data.refreshToken);
                sessionStorage.setItem("token", result.data.token);
                sessionStorage.setItem("permissions", result.data.permissions);
                sessionStorage.setItem("userType", result.data.userType);
                sessionStorage.setItem("Email", result.data.Email);
                navigate("/Dashboard");
                window.location.reload()
            } else {
                alertErrorMessage(result.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error);
        }
    }

    useEffect(() => {
        const enterToLogin = event => {
            if (event.key === 'Enter') {
                event.preventDefault();
                handleLogin(email, password);
            }
        };
        document.addEventListener('keydown', enterToLogin);
        return () => {
            document.removeEventListener('keydown', enterToLogin);
        };
    });
    return (
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main className="login-card" >
                    <div className="container-xl px-4">
                        {/* <div className="d-flex justify-content-end">
                            <a className="btn btn-primary btn-block w-20" href="https://fs11admin.fantasyscore11.com/downloads">Download APK</a>
                        </div> */}
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                <div className="card-body p-5 pb-4 text-center">
                                    <img src="/img/Logo.svg" className="img-fluid w-50"  />
                                </div>
                                <div className="card">
                                    <div className="card-body p-5">
                                        <h2 className=" fs-1 text-center"><strong>Sign in to account</strong></h2>
                                        <p className="text-center"><small>Enter your email &amp; password to login</small></p>
                                        <form className="mt-4" >
                                            <div className="mb-3">
                                                <label className="text-gray-600 small" for="emailExample">Email address</label>
                                                <input className="form-control form-control-solid" type="text" placeholder="" aria-label="Email Address" aria-describedby="emailExample" value={email} onChange={(e) => { setemail(e.target.value) }} />
                                            </div>
                                            <div className="mb-3">
                                                <label className="text-gray-600 small" for="passwordExample">Password</label>
                                                <input className="form-control form-control-solid" type="password" placeholder="" aria-label="Password" aria-describedby="passwordExample" value={password} onChange={(e) => { setpassword(e.target.value) }} />
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between mb-4 mt-3">
                                                <div className="form-check  mb-0">
                                                    {/* <input className="form-check-input" id="checkRememberPassword" type="checkbox" value="" /> */}
                                                    {/* <label className="form-check-label  mb-0" for="checkRememberPassword">Remember password</label> */}
                                                </div>
                                                <Link className="small" to="/ForgotPassword">Forgot your password?</Link>
                                            </div>
                                            <button className="btn btn-primary btn-block w-100" type="button" onClick={() => handleLogin(email, password)}>  Login
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default Login
