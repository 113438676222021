import React, { useEffect, useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import Header from '../Header'
import Sidebar from '../Sidebar'

const Settings = () => {
    const [email, setemail] = useState('');
    const [usableBonus, setusableBonus] = useState('');
    const [signupBonus, setsignupBonus] = useState('');
    const [refBonus, setrefBonus] = useState('');
    const [refCommision, setrefCommision] = useState('');
    const [minWithdrwAmount, setminWithdrwAmount] = useState('');
    const [mindepositAmount, setmindepositAmount] = useState('');
    const [TDS, setTDS] = useState('');

    useEffect(() => {
        ViewSetting();
    }, []);

    const editSettings = async (email, usableBonus, signupBonus, refBonus, refCommision, minWithdrwAmount, TDS, mindepositAmount) => {

        await AuthService.Setting(email, usableBonus, signupBonus, refBonus, refCommision, minWithdrwAmount, TDS, mindepositAmount).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Settings updated successfully!!');
                    ViewSetting();

                } catch {
                    alertWarningMessage(result?.message)
                    console.log(result?.msg);
                }
            } else {
                alertWarningMessage(result?.message)
                console.log('Something went wrong');
            }
        })
    };
    const ViewSetting = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.ViewSetting().then((result) => {
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                try {
                    setemail(result?.data?.[0]?.AdminEmail)
                    setrefCommision(result?.data?.[0]?.ContactCommision)
                    setmindepositAmount(result?.data?.[0]?.MiniMumDeposit)
                    setminWithdrwAmount(result?.data?.[0]?.MinimumWithdraw)
                    setrefBonus(result?.data?.[0]?.RefralBonus)
                    setsignupBonus(result?.data?.[0]?.SignupBonus)
                    setTDS(result?.data?.[0]?.TDS)
                    setusableBonus(result?.data?.[0]?.UsableBonus)
                } catch {
                    console.log(result?.msg);
                }
            } else {
                LoaderHelper.loaderStatus(false);
            }
        })
    };


    return (
        <>

                <div id="layoutSidenav_content">
                    <main>
                        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div className="container-xl px-4">
                                <div className="page-header-content pt-4">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto mt-4">
                                            <h1 className="page-header-title">
                                                <div className="page-header-icon"><i className="ri-article-line"></i></div>
                                                Add Content Template
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main page content--> */}
                        <div className="container-xl px-4 mt-n10">
                            <div className="card mb-4">

                                <form method="post" acceptCharset="utf-8" validaterset="validaterset" id="settingForm" action="">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-sm-6 col-md-6">
                                                <div className="input text required">
                                                    <label for="admin-email">Admin Email <span className="required">*</span></label>
                                                    <input type="text" name="admin_email" className="form-control" placeholder="Admin Email" required="required" maxLength="150" id="admin-email" onChange={(e) => { setemail(e.target.value) }} value={email} />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 col-md-6">
                                                <div className="input text">
                                                    <label for="admin-percentage">Usable Bonus Percentage</label>
                                                    <input type="text" name="admin_percentage" className="form-control" placeholder="Usable Bonus Percentage" maxLength="50" id="admin-percentage" onChange={(e) => { setusableBonus(e.target.value) }} value={usableBonus} />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 col-md-6">
                                                <div className="input text">
                                                    <label for="referral-bouns-amount">Signup Bouns Amount (INR)</label>
                                                    <input type="text" name="referral_bouns_amount" maxLength="8" className="form-control" id="referral-bouns-amount" onChange={(e) => { setsignupBonus(e.target.value) }} value={signupBonus} />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 col-md-6">
                                                <div className="input text">
                                                    <label for="referral-bouns-amount-referral">Referral Bouns Amount (INR)</label>
                                                    <input type="text" name="referral_bouns_amount_referral" maxLength="8" className="form-control" placeholder="Referral Bouns Amount" id="referral-bouns-amount-referral" onChange={(e) => { setrefBonus(e.target.value) }} value={refBonus} />
                                                </div>
                                            </div>

                                            <div className="form-group col-sm-6 col-md-6">
                                                <div className="input text">
                                                    <label for="contest-commission">Referral Commission (In percentage)</label>
                                                    <input type="text" name="contest_commission" maxLength="5" className="form-control" placeholder="Referral Commission Percentage" id="contest-commission" onChange={(e) => { setrefCommision(e.target.value) }} value={refCommision} />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 col-md-6">
                                                <div className="input text">
                                                    <label for="min-withdraw-amount">Minimum Withdraw Amount (INR)</label>
                                                    <input type="text" name="min_withdraw_amount" maxLength="7" className="form-control" placeholder="Minimum Withdraw Amount" id="min-withdraw-amount" onChange={(e) => { setminWithdrwAmount(e.target.value) }} value={minWithdrwAmount} />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 col-md-6">
                                                <div className="input text">
                                                    <label for="min-withdraw-amount">Minimum Deposit Amount (INR)</label>
                                                    <input type="text" name="min_withdraw_amount" maxLength="7" className="form-control" placeholder="Minimum Withdraw Amount" id="min-withdraw-amount" onChange={(e) => { setmindepositAmount(e.target.value) }} value={mindepositAmount} />
                                                </div>
                                            </div>
                                            <div className="form-group col-sm-6 col-md-6">
                                                <div className="input text">
                                                    <label for="tds">TDS (On minimum 10000 INR)</label>
                                                    <input type="text" name="tds" maxLength="5" className="form-control" placeholder="TDS" id="tds" onChange={(e) => { setTDS(e.target.value) }} value={TDS} />
                                                </div>
                                            </div>
                                            {/* <div className="form-group col-sm-6 col-md-6">
                                                <label for="popup-image">Popup Image</label>
                                                 <input type="file" name="popup_image" className="form-control" id="popup-image" onChange={(e)=>{setimage(e.target.files[0])}}/>
                                            </div> */}
                                            {/* <div className="form-group  col-sm-6 col-md-6">
                                                <label>Popup On</label>
                                                <select className="form-control  form-select" name="popup_on" onChange={(e)=>{setPopup(e.target.value)}} value={popup}>
                                                    <option value="true" selected="">True</option>
                                                    <option value="false">False</option>
                                                </select>
                                            </div> */}
                                            {/* <div className="form-group col-sm-6 col-md-6">
                                                <label>Notification Sound</label>
                                                <select className="form-control  form-select" name="sound_on" onChange={(e)=>{notificationSound(e.target.value)}} value={notificationSound}>
                                                    <option value="on" selected="">On</option>
                                                    <option value="off">Off</option>
                                                </select>
                                            </div> */}
                                            {/* <div className="form-group col-sm-6 col-md-6">
                                                <div className="input text">
                                                    <label for="popup-social-url">Popup Social Url</label>
                                                    <input type="text" name="popup_social_url" className="form-control" placeholder="Social Url" maxLength="255" id="popup-social-url" onChange={(e)=>{setsocialUrl(e.target.value)}} value={socialUrl}/>
                                                </div>
                                            </div> */}
                                            {/* <div className="form-group col-sm-6 col-md-6">
                                                <label>Force Update</label>
                                                <select className="form-control form-select" name="force_update" onChange={(e)=>{setforceUpdate(e.target.value)}} value={forceUpdate}>
                                                    <option value="1" selected="">Yes</option>
                                                    <option value="0">No</option>
                                                </select>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="button" className="btn btn-primary submit px-5" onClick={() => { editSettings(email, usableBonus, signupBonus, refBonus, refCommision, minWithdrwAmount, TDS, mindepositAmount) }}>Submit</button>
                                    </div>
                                </form>
                            </div>



                        </div>
                    </main>
                </div>
        </>
    )
}

export default Settings
