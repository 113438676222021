import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import Header from '../Header'
import Sidebar from '../Sidebar'

const PreSquadMathces = () => {

    const [preSquadList, setpreSquadList] = useState([]);
    const [showMainPage, setshowMainPage] = useState(true);
    const [playersData, setplayersData] = useState();
    const [editPlayers, seteditPlayers] = useState([]);
    const [matchId, setmatchId] = useState();

    useEffect(() => {
        PreSquadMtch()
    }, []);

    const PreSquadMtch = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.PreSquadMtch().then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setpreSquadList(result?.data)
                    window.scrollTo({ top: 0, behavior: 'smooth' });

                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    let uniqueArr = Array.from(new Set(editPlayers.map(item => item._id))).map(id => {
        return editPlayers.find(item => item._id === id)
    });
    console.log(uniqueArr, 'uniqueArr');
    const Editplayer = async () => {

        LoaderHelper.loaderStatus(true);
        await AuthService.EditPlayers(matchId, uniqueArr).then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    alertSuccessMessage("Players Updated Successfully")
                    // setshowMainPage(true)
                    // window.scrollTo({ top: 0, behavior: 'smooth' });
                    // window.location.reload()

                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };
    const TeamPlayers = async (id) => {
        setshowMainPage(false)
        seteditPlayers([])
        setmatchId(id)
        LoaderHelper.loaderStatus(true);
        await AuthService.TeamPlayers(id).then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setplayersData(result?.data)
                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };



    const handleEditInputs = (e, id) => {

        let objIndex = uniqueArr.findIndex(check => check?._id == id);
        switch (e.target.name) {
            case 'rating':
                uniqueArr[objIndex].fantasy_player_rating = +e.target.value
                break;
            case 'category':
                uniqueArr[objIndex].playing_role = e.target.value
                break;
            case 'tier':
                uniqueArr[objIndex].tier = e.target.value
                break;
            default:
                break;
        }

    }
    const handleId = (id, rating, role, tier, teamId) => {
        seteditPlayers((prev) => [...prev, { '_id': id, 'playing_role': role, 'fantasy_player_rating': rating, 'tier': tier, 'team_id': teamId }]);
    }

    /* custom pagination */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = Math.ceil(preSquadList.length / itemsPerPage);
    const currentItems = preSquadList.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // custom paginatio ends
    return (
        <>
            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-football-line"></i></div>
                                            Pre Squad Matches
                                        </h1>
                                        {/* <!-- <div className="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content--> */}

                    {/* PreSquad List */}
                    <div className="container-xl px-4 mt-n10" style={{ display: showMainPage ? "" : 'none' }}>

                        <div className="card mb-4">

                            <div className="card-body">

                                <table id=" " className='dataTable-table'>
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Team 1 vs Team 2</th>
                                            <th>Date</th>
                                            <th>Time </th>
                                            <th>Info</th>
                                            <th>Action</th>

                                        </tr>
                                    </thead>

                                    <tbody>
                                        {currentItems ? currentItems?.map((data, index) => {
                                            let objIndex;
                                            objIndex = preSquadList.findIndex(check => check?._id == data._id);
                                            let team = data?.title ? data?.title.split('vs') : ''
                                            let date = data?.date_start ? data?.date_start
                                                .split(' ') : ''
                                            return (

                                                <tr>
                                                    <td>{objIndex + 1}</td>
                                                    <td>
                                                        <div className='vs_vs' >
                                                            <div className='vs_team' >
                                                                {/* <img src={data?.PhotoA} width="30" height="30" alt="" /> */}
                                                                <span>{team[0]}</span>
                                                            </div>
                                                            <div className='vs_bar'>vs</div>
                                                            <div className='vs_team'>
                                                                {/* <img src={data?.PhotoB} width="30" height="30" alt="" /> */}
                                                                <span>{team[1]}</span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{date[0]}</td>
                                                    <td>{date[1]}</td>
                                                    <td>PreSquad : <span className='text-warning'> {data?.pre_squad}</span><br />Verified : <span className='text-danger mt-2'> {data?.Verified}</span>
                                                        <br />Status : <span className='text-primary'> {data?.status_str}</span>
                                                    </td>
                                                    <td>
                                                        <button type='button' className="btn btn-primary btn-sm" onClick={() => { TeamPlayers(data?.match_id) }}>Update Players List</button>
                                                    </td>
                                                </tr>
                                            )
                                        }) : ''}

                                    </tbody>
                                </table>
                                {/* custom pagination */}
                                <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    containerClassName={'customPagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>



                    {/* Players List */}
                    <div className="container-xl px-4 mt-n10" style={{ display: showMainPage ? "none" : '' }}>



                        <div className='row' >
                            <div className='col-md-12' >
                                <div className="d-flex align-items-center text-white mb-3  " onClick={() => { setshowMainPage(!showMainPage) }}><a className="btn text-white  btn-icon" href='#/'> <i className="ri-arrow-left-line"></i> </a> Back </div>
                            </div>
                            <div className='col-md-6' >
                                <div className="card mb-4">
                                    <div className='card-header bg-indigo' >
                                        <h5 className='mb-0 text-white' >Team 1 {playersData?.[0]?.title}</h5>
                                    </div>
                                    <div className="card-body">
                                        <table id=" " className='dataTable-table'>
                                            <thead>
                                                <tr>
                                                    <th>Player</th>
                                                    <th>Tier</th>
                                                    <th>Role</th>
                                                    <th>Rating </th>

                                                </tr>
                                            </thead>
                                            <tfoot>
                                            </tfoot>
                                            <tbody>
                                                {playersData?.[0]?.players ? playersData[0].players?.map((data) => {
                                                    return (
                                                        <tr>
                                                            <td className='vertical-align-middle' >{data?.first_name}</td>
                                                            <td><select className='form-select' name='tier' onChange={(e) => handleEditInputs(e, data?._id)}
                                                                onClick={() => handleId(data?._id, data?.fantasy_player_rating, data?.playing_role, data?.tier, playersData?.[0]?.team_id)} defaultValue={data?.tier}>
                                                                <option value='gold'>Gold</option>
                                                                <option value='silver'>Silver</option>
                                                                <option value='bronze'>Bronze</option>
                                                            </select></td>

                                                            <td><select className='form-select' name='category' onChange={(e) => handleEditInputs(e, data?._id)}
                                                                onClick={() => handleId(data?._id, data?.fantasy_player_rating, data?.playing_role, data?.tier, playersData?.[0]?.team_id)}>
                                                                <option selected disabled>{data?.playing_role}</option>
                                                                {data?.playing_role !== 'wk' && <option value='wk'>WicketKeeper</option>}
                                                                {data?.playing_role !== 'all' && <option value='all' >AllRounder</option>}
                                                                {data?.playing_role !== 'bat' && <option value='bat'>Batsman</option>}
                                                                {data?.playing_role !== 'bowl' && <option value='bowl'>Bowler</option>}
                                                            </select></td>

                                                            <td>
                                                                <input className='form-control check' type='number' name='rating' onChange={(e) => handleEditInputs(e, data?._id)} onSelect={() => handleId(data?._id, data?.fantasy_player_rating, data?.playing_role, data?.tier, playersData?.[0]?.team_id)} placeholder={data?.fantasy_player_rating} /></td>
                                                        </tr>
                                                    )
                                                }) : ''}

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>


                            <div className='col-md-6' >
                                <div className="card mb-4">
                                    <div className='card-header bg-indigo' >
                                        <h5 className='mb-0 text-white' >Team 2  {playersData?.[1]?.title} </h5>
                                    </div>
                                    <div className="card-body">
                                        <table id=" " className='dataTable-table'>
                                            <thead>
                                                <tr>
                                                    <th>Player</th>
                                                    <th>Tier</th>
                                                    <th>Role</th>
                                                    <th>Rating </th>
                                                </tr>
                                            </thead>
                                            <tfoot>
                                            </tfoot>
                                            <tbody>
                                                {playersData?.[1]?.players ? playersData[1].players?.map((data) => {
                                                    return (
                                                        <tr>
                                                            <td className='vertical-align-middle' >{data?.first_name}</td>
                                                            <td><select className='form-select' name='tier' defaultValue={data?.tier} onChange={(e) => handleEditInputs(e, data?._id)}
                                                                onClick={() => handleId(data?._id, data?.fantasy_player_rating, data?.playing_role, data?.tier, playersData?.[1]?.team_id)}>
                                                                <option value='gold'>Gold</option>
                                                                <option value='silver'>Silver</option>
                                                                <option value='bronze'>Bronze</option>
                                                            </select></td>
                                                            <td>
                                                                <select name='category' onChange={(e) => handleEditInputs(e, data?._id)}
                                                                    onClick={() => handleId(data?._id, data?.fantasy_player_rating, data?.playing_role, data?.tier, playersData?.[1]?.team_id)} className='form-select'  >
                                                                    <option selected disabled>{data?.playing_role}</option>
                                                                    {data?.playing_role !== 'wk' && <option value='wk'>WicketKeeper</option>}
                                                                    {data?.playing_role !== 'all' && <option value='all' >AllRounder</option>}
                                                                    {data?.playing_role !== 'bat' && <option value='bat'>Batsman</option>}
                                                                    {data?.playing_role !== 'bowl' && <option value='bowl'>Bowler</option>}
                                                                </select>
                                                            </td>
                                                            <td>
                                                                <input className='form-control check'
                                                                    type='number' name='rating' onChange={(e) => handleEditInputs(e, data?._id)} onSelect={() => handleId(data?._id, data?.fantasy_player_rating, data?.playing_role, data?.tier, playersData?.[1]?.team_id)} placeholder={data?.fantasy_player_rating} /></td>

                                                        </tr>
                                                    )
                                                }) : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button type='button' className="btn btn-primary btn-lg" onClick={() => { Editplayer() }} >Update Players List</button>

                    </div>


                </main>
            </div>



        </>
    )
}

export default PreSquadMathces
