import React, { useEffect, useState } from 'react'
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage } from '../../Components/CustomAlertMessage';
import Moment from 'react-moment';
import { CSVLink } from 'react-csv';

const Transaction = () => {
    const [transList, setTransList] = useState([]);
    const [userId, setuserId] = useState("");
    const [mobile, setMobile] = useState("");
    const [type, setType] = useState("winning_reverted");

    const getTransaction = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.AllTransactions(userId, type, mobile)
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                let filteredData = result?.data?.map((item) => { return { ...item, userId: item?.user_id?._id, userName: item?.user_id?.username, mobile: item?.user_id?.mobile_number } })
                setTransList(filteredData);
            } else {
                alertErrorMessage(result?.response?.data?.message)
            }

        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error?.message);
        }
    };

    useEffect(() => {
        getTransaction()
    }, []);


    
    const csvData = transList.map(item => ({
        "Date": item?.createdAt,
        "User Id": item?.user_id?._id,
        "User Name": item?.user_id?.username,
        "User Mobile": item?.user_id?.mobile_number,
        "Amount": item?.amount,
        "Transaction Type": item?.transaction_type,
        "Match": item?.match_id?.title,
        "Transaction Status": item?.transaction_status,
        "Description": item?.message,
    }));

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="mt-4 d-flex justify-content-between">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-arrow-up-down-line"></i></div>
                                            Transaction
                                        </h1>
                                        <div className="dropdown">
                                            <button className="btn btn-white btn-sm dropdown-toggle" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export</button>
                                            <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                <CSVLink data={csvData} className="dropdown-item">Export as CSV</CSVLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className=" filter_bar mb-4 mb-md-5">
                                    <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form" action="/admin/users/referraldata">
                                        <div className="row gx-2 mt-2 align-items-center">
                                            <div className="form-group  mb-0 col-4">
                                                <div className="input text">
                                                    <select className='form-control' value={type} onChange={(e) => setType(e.target.value)}>
                                                        <option value="winning_reverted">Winning Reverted</option>
                                                        <option value="contest_charge_deducted">Contest Charge Deducted</option>
                                                        <option value="prize_distributed">Prize Distributed</option>
                                                        <option value="contest_charge_refunded">Contest Charge Refunded</option>
                                                        <option value="withdrawl_request">Withdrawl Request</option>
                                                        <option value="entry_fee_refunded">Entry Fee Refunded</option>
                                                        <option value="referral_bonus_added">Referral Bonus Added</option>
                                                        <option value="bonus_removed_by_admin">Bonus Removed by Admin</option>
                                                        <option value="bonus_added_by_admin">Bonus Added by Admin</option>
                                                        <option value="referral_commission_added">Referral Commission Added</option>
                                                        <option value="referral_commission_added">Referral Commission Added</option>
                                                        <option value="UPI PAYMENT_SUCCESS">UPI Payment Success</option>
                                                        <option value="CARD PAYMENT_SUCCESS">CARD Payment Success</option>
                                                        <option value="NETBANKING PAYMENT_SUCCESS">NETBANKING Payment Success</option>
                                                        <option value="Payment Failed">Payment Failed</option>
                                                        <option value="pending">Pending</option>
                                                        <option value="cancelled_match_refund">Cancelled Match Refund</option>
                                                        <option value="processing">Processing</option>
                                                        <option value="processed">Processed</option>
                                                        <option value="cancelled">Cancelled</option>
                                                        <option value="">All</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group  mb-0 col-3">
                                                <div className="input text">
                                                    <input type="tel" name="phone" className="form-control" placeholder="Enter UserId" id="search" value={userId} onChange={(e) => setuserId(e.target.value)} />
                                                </div>
                                            </div>
                                            <div className="form-group  mb-0 col-3">
                                                <div className="input text">
                                                    <input type="number" name="phone" className="form-control" placeholder="Enter Mobile" id="search" value={mobile} onChange={(e) => setMobile(e.target.value)} onWheel={(e) => e.target.blur()} />
                                                </div>
                                            </div>
                                            <div className="form-group  mb-0 col-2">
                                                <div className="input text">
                                                    <button type="button" className="btn  bg-success text-white rounded-pill" onClick={getTransaction}>Search</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Date</th>
                                            <th>User Id</th>
                                            <th>User Name</th>
                                            <th>User Mobile</th>
                                            <th>Amount</th>
                                            <th>Transaction Type</th>
                                            <th>GST</th>
                                            <th>TDS</th>
                                            <th>Match</th>
                                            <th>Transaction Status</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transList?.map((item, index) => {
                                            return (
                                                <tr key={item?._id}>
                                                    <td>{index + 1}</td>
                                                    <td><Moment date={item?.createdAt} format='DD/MM/YY hh:mm A' /></td>
                                                    <td>{item?.user_id?._id}</td>
                                                    <td>{item?.user_id?.username}</td>
                                                    <td>{item?.user_id?.mobile_number}</td>
                                                    <td>{item?.amount}</td>
                                                    <td>{item?.transaction_type}</td>
                                                    <td>{item?.gstamount}</td>
                                                    <td>{item?.tdsamount}</td>
                                                    <td>{item?.match_id?.title}</td>
                                                    <td>{item?.transaction_status}</td>
                                                    <td>{item?.message}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}

export default Transaction
