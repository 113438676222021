import React, { useState } from 'react'
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import { Link } from 'react-router-dom';

const ForgotPassword = () => {
    const [email, setemail] = useState('');

    const handleforgotPass = async (email) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.forgot(email).then(async result => {
            if (result.success) {
                LoaderHelper.loaderStatus(false);
                alertSuccessMessage('New Password is sent to your registerd Email Id')
                setemail('')
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    }
    return (
        <div id="layoutAuthentication">
            <div id="layoutAuthentication_content">
                <main className="login-card" >
                    <div className="container-xl px-4">
                        <div className="row justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                                <div className="card-body p-5 pb-4 text-center">
                                    <img src="/img/Logo.svg" className="img-fluid w-50"  />
                                </div>
                                <div className="card my-5">
                                    <div className="card-body p-5">
                                        <h2 className=" fs-1 text-center"><strong>Forgot Password</strong></h2>
                                        <p className="text-center"><small>Enter your email address below and we will send you a link to reset your password.</small></p>
                                        <form className="mt-4" action="index.php" >
                                            <div className="mb-3">
                                                <label className="text-gray-600 small" for="emailExample">Email address</label>
                                                <input className="form-control form-control-solid" type="text" placeholder="Email Address" aria-label="Email Address" aria-describedby="emailExample" onChange={(e) => { setemail(e.target.value) }} value={email} />
                                            </div>
                                            <button className="btn btn-primary btn-block w-100 mt-3" type="button" onClick={() => { handleforgotPass(email) }}> Forgot Password</button>
                                        </form>
                                    </div>
                                    <hr className="my-0" />
                                    <div className="card-body px-5 py-4">
                                        <div className="small text-center">
                                            Back to -
                                            <Link to="/"> Login</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}

export default ForgotPassword
