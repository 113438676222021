import React, { useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import Header from '../Header'
import Sidebar from '../Sidebar'

const AddCategory = () => {
    const [catogryName, setcatogryName] = useState('');
    const [description, setdescription] = useState('');
    const [seqNumber, setseqNumber] = useState('');
    const [image, setimage] = useState();

  
    const handleReset = ()=>{

        setcatogryName('');
        setdescription('');
        setseqNumber('');
 
    }

    const addCategory = async (catogryName,description,seqNumber) => {
        if(catogryName && description && seqNumber )
        {
        await AuthService.addCategory(catogryName,description,seqNumber).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage('Category added successfully!!');
                    handleReset()
                } catch {
                    alertErrorMessage(result?.message)
                    console.log(result?.msg);

                }
            } else {
                alertErrorMessage(result?.message)
                console.log('Something went wrong');
            }
        })}
        else{
            alertWarningMessage('Please fill all mandetory fields')
        }
    };

    return (
        <>
                <div id="layoutSidenav_content">
                    <main>
                        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div className="container-xl px-4">
                                <div className="page-header-content pt-4">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto mt-4">
                                            <h1 className="page-header-title">
                                                <div className="page-header-icon"><i className="ri-list-check-2"></i></div>
                                                Add Category
                                            </h1>
                                            {/* <!-- <div className="page-header-subtitle">Example dashboard overview and content summary</div> --></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main page content--> */}
                        <div className="container-xl px-4 mt-n10">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h3 className="card-title mb-0">Enter Details</h3> </div>
                                <form enctype="" method="post" acceptCharset="utf-8" validaterset="validaterset" id="subAdminForm" action="">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-xs-6 col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input text">
                                                        <label for="category-name">Category Name <span className="required">*</span></label>
                                                        <input type="text" name="category_name" maxLength="25" className="form-control" placeholder="Category Name" id="category-name" 
                                                        onChange={(e)=>{setcatogryName(e.target.value)}}
                                                        value={catogryName}
                                                        />
                                                     
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-6 col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input text">
                                                        <label for="description">Description <span className="required">*</span></label>
                                                        <input type="text" name="description" maxLength="150" className="form-control" placeholder="Description" id="description"  onChange={(e)=>{setdescription(e.target.value)}}
                                                        value={description} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-6 col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input text">
                                                        <label for="sequence">Sequence Number <span className="required">*</span></label>
                                                        <input type="number" name="sequence" maxLength="5" className="form-control" placeholder="Sequence" id="sequence"  onChange={(e)=>{setseqNumber(e.target.value)}}
                                                        value={seqNumber} />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="col-xs-6 col-sm-6 col-md-6">
                                                <div className="form-group">
                                                    <div className="input file">
                                                        <label for="image">Upload Image <span className="required">*</span></label>
                                                        <input type="file" name="image" className="form-control" placeholder="Upload Image" id="image"  onChange={(e)=>{setimage(e.target.files[0])}}
                                                        />
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="button" className="btn btn-primary submit px-5" onClick={()=>{addCategory(catogryName,description,seqNumber)}}>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </main>
                </div>
        </>
    )
}

export default AddCategory
