import React, { useEffect, useState } from 'react';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import { ApiConfig } from '../../api/apiConfig/ApiConfig';

const AadharRequest = () => {
    const [list, setlist] = useState();
    const [list2, setlist2] = useState();

    const [kycStatus, setKycStatus] = useState('1');

    useEffect(() => {
        AadharList(kycStatus)
    }, []);


    function searchFilter(userInput) {
        const keysToSearch = ["adahr_name", "username", "user_id", "mobile_number", "username","adhar_no"];
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = list2.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setlist(matchingObjects);
    };

    const AadharList = async (kycStatus) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.ListAadhar(kycStatus).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setlist(result?.data)
                    setlist2(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        });
    };

    const VerifyAadhar = async (id, status, userid) => {
        await AuthService.VerifyAadhar(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage("Aadhar Status Updated Successfully!!");
                    AadharList(kycStatus)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                // alertErrorMessage(result.message);
            }
        });
    };

    const deleteAAdhar = async (id, status, userid) => {
        await AuthService.delete_adhar(id, status, userid).then((result) => {
            if (result?.success) {
                try {
                    alertSuccessMessage(result?.message);
                    AadharList(kycStatus)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        });
    };

    const filter = (e) => {
        let filterd;
        if (e.target.checked) {
            filterd = list2?.filter((data) => {
                return data?.adhar_verified === 3
            })
            setlist(filterd)
        } else {
            setlist(list2)
        }
    };

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-group-line"></i></div>
                                            Aadhar Request
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                                <div className="input-text mx-3 mt-3">
                                    <input type="text" name="search" className="form-control" placeholder="Search Aadhar name, user id, Aadhar number" id="search"
                                        onChange={(e) => searchFilter(e.target.value)} />
                                </div>
                            <div className="card-body">
                                <div className=" filter_bar mb-2 mb-md-5 w-25">
                                    <select onChange={(e) => {
                                        setKycStatus(e.target.value);
                                        AadharList(e.target.value);
                                    }} className='form-control'>
                                        <option value="1">Approved Request</option>
                                        <option value="2">Pending Request</option>
                                    </select>
                                </div>

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>UserId</th>
                                            <th>Aadhar Name</th>
                                            <th>User Name</th>
                                            <th>Phone Number</th>
                                            <th>Aadhar Number</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {list ? list?.map((data, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{data?.user_id}</td>
                                                    <td>{data?.adahr_name}</td>
                                                    <td>{data?.data?.username}</td>
                                                    <td>{data?.data?.mobile_number}</td>
                                                    <td>{data?.adhar_no}</td>

                                                    <td>{data?.adhar_verified === 1 ? <span className='text-success'>Approved</span> : <>
                                                        <button type="btn" className="btn badge bg-success text-white rounded-pill"
                                                            onClick={() => { VerifyAadhar(data?._id, '1', data?.user_id) }}
                                                        >Approve</button>

                                                        <button type="button" className="mt-2 mx-2   btn badge bg-danger text-white rounded-pill"
                                                            onClick={() => deleteAAdhar(data?._id, '0', data?.user_id)}> Delete</button>
                                                    </>
                                                    }
                                                    </td>
                                                </tr>
                                            )
                                        }) : ''}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}

export default AadharRequest
