import React, { useEffect, useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import Header from '../Header'
import Sidebar from '../Sidebar'
import LoaderHelper from '../../Components/Loading/LoaderHelper';

const AddBanner = () => {

    const [bannerType, setbannerType] = useState();
    const [bannerSeq, setbannerSeq] = useState();
    const [offerType, setofferType] = useState();
    const [offerList, setofferList] = useState();
    const [image, setimage] = useState();
    const [series, setseries] = useState();
    const [SeriesListName, setSeriesListName] = useState();
    const [match, setmatch] = useState();
    const [matchListName, setmatchListName] = useState();

    let seriesName = (series ? series.split('+')?.[1] : null);
    let seriesId = (series ? series.split('+')?.[0] : null);

    useEffect(() => {
        HandleSeriesName()
        getOfferlist()
    }, []);

    useEffect(() => {
        if (series) {
            HandleMatchName(seriesId)
        }
    }, [series]);

    const HandleSeriesName = async () => {
        LoaderHelper?.loaderStatus(true);
        await AuthService.SeriesName().then((result) => {
            LoaderHelper?.loaderStatus(false);
            if (result?.success) {
                try {
                    setSeriesListName(result?.data[0])
                } catch {
                }
            } else {
                LoaderHelper?.loaderStatus(false);
                console.log('Something went wrong');
            }
        })
    };

    const HandleMatchName = async (id) => {
        LoaderHelper?.loaderStatus(true);
        await AuthService.MatchesSeries(id).then((result) => {
            if (result?.success) {
                LoaderHelper?.loaderStatus(false);
                try {
                    setmatchListName(result?.data[0])
                } catch {
                }
            } else {
                LoaderHelper?.loaderStatus(false);
                console.log('Something went wrong');
            }
        })
    };
    const getOfferlist = async () => {
        await AuthService.getOfferlist().then((result) => {
            if (result?.success) {
                try {
                    setofferList(result?.data)
                } catch {
                }
            } else {
                console.log('Something went wrong');
            }
        })
    };

    const handleInput = (e) => {
        switch (e.target.name) {
            case 'banner_type':
                setbannerType(e.target.value);
                setbannerSeq('');
                setseries('');
                setmatch('');
                setofferType('')
                setimage()
                break;
            case 'image':
                setimage(e.target.files[0])
                break;
            case 'BannerSequence':
                setbannerSeq(e.target.value)
                break;
            case 'Offer':
                setofferType(e.target.value)
                break;
            case 'Series':
                setseries(e.target.value)
                break;
            case 'Match':
                setmatch(e.target.value)
                break;

            default:
                break;
        }
    };

    const addBanner = async (bannerType, image, bannerSeq, offerType, seriesName, match) => {
if(!bannerType){
    alertWarningMessage('Please select Banner')
}else if(!bannerSeq){
    alertWarningMessage('Please Fill Banner Sequence')
}else{


        var formData = new FormData();
        formData.append('bannerType', bannerType);
        formData.append('sequence', bannerSeq);
        formData.append('Offer', offerType);
        formData.append('SeriesList', seriesName);
        formData.append('MatchType', match);
        formData.append('bannerPath', image);

        await AuthService.addbanner(formData).then((result) => {
            if (result?.message === "Banner submitted successfully") {
                try {
                    alertSuccessMessage(result?.message)
                    setbannerSeq('');
                    setseries('');
                    setmatch('');
                    setofferType('')
                    setimage()
                } catch {
                    alertErrorMessage(result?.message)
                }
            } else {
                alertErrorMessage(result?.message)
            }
        })}
    }


    return (
        <>
                <div id="layoutSidenav_content">
                    <main>
                        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div className="container-xl px-4">
                                <div className="page-header-content pt-4">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto mt-4">
                                            <h1 className="page-header-title">
                                                <div className="page-header-icon"><i className="ri-image-line"></i></div>
                                                Add Banner
                                            </h1>
                                            {/* <!-- <div className="page-header-subtitle">Example dashboard overview and content summary</div> --></div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main page content--> */}
                        <div className="container-xl px-4 mt-n10">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h3 className="card-title mb-0">Enter Details</h3> </div>
                                <form enctype="multipart/form-data" method="post" acceptCharset="utf-8" validaterset="validaterset" id="offerForm" action="">

                                    <div className="card-body ">
                                        <div className="row">
                                            <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                                <div className="input select required">
                                                    <label for="banner-type">Banner type <span className="required">*</span></label>
                                                    <select name="banner_type" className="form-control form-select bannerType" id="banner-type" onChange={handleInput}>
                                                        <option value="" selected disabled>Select Type</option>
                                                        <option value="MatchType">Match Type</option>
                                                        <option value="InviteType">Invite Type</option>
                                                        <option value="OfferType">Offer Type</option>
                                                        <option value="SocialType">Social Type</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                                <div className="input select">
                                                    <label for="image">Image <span className="required">*</span></label>
                                                    <input type="file" name="image" id="image" className="form-control" accept="image/*" onChange={handleInput} />
                                                </div>
                                            </div>
                                            <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                                <div className="input select">
                                                    <label for="BannerSequence">BannerSequence <span className="required">*</span></label>
                                                    <input type="text" name="BannerSequence"
                                                        id="BannerSequence" className="form-control"
                                                        placeholder='BannerSequence ' value={bannerSeq} onChange={handleInput} />
                                                </div>
                                            </div>

                                            {bannerType === 'OfferType' ? <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                                <div className="input select required">
                                                    <label for="Offer">Offer type <span className="required">*</span></label>
                                                    <select name="Offer" className="form-control form-select bannerType" id="Offer" onChange={handleInput}>
                                                        <option value="" selected disabled>offer Type</option>

                                                        {offerList ? offerList?.map((data) => {
                                                            return (

                                                                <option value={data?.CouponCode}>Coupen : {data?.CouponCode}</option>
                                                            )

                                                        }) : <option value="">No data found</option>}

                                                    </select>
                                                </div>
                                            </div> : ''}
                                            {bannerType === 'MatchType' ? <>  <div className="row">  <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                                <div className="input select required">
                                                    <label for="Series">Series <span className="required">*</span></label>
                                                    <select name="Series" className="form-control form-select bannerType" id="Series" onChange={handleInput}>
                                                        <option value="" disabled selected>Select Series List</option>
                                                        {SeriesListName ? SeriesListName?.map((data) => {
                                                            return (

                                                                <option value={data?.cid + '+' + data?.SeriesName}>{data?.SeriesName}</option>
                                                            )
                                                        }) : <option value="" disabled>No data found</option>}

                                                    </select>
                                                </div>
                                            </div>
                                                <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                                    <div className="input select required">
                                                        <label for="Match">Match <span className="required">*</span></label>
                                                        <select name="Match" className="form-control form-select bannerType" id="Match" onChange={handleInput}>
                                                            <option value="" selected disabled>Select Type</option>
                                                            {matchListName ? matchListName?.map((data) => {
                                                                return (
                                                                    <option value={data?.title}>{data?.title}</option>
                                                                )
                                                            }) : <option value="">No Data Found</option>}


                                                        </select>
                                                    </div>
                                                </div></div> </> : ''}



                                            <div className="card-footer">
                                                <button type="button" className="btn btn-primary submit px-5" onClick={() => { addBanner(bannerType, image, bannerSeq, offerType, seriesName, match) }}>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>



                            </div>
                        </div>
                    </main>
                </div>
        </>
    )
}

export default AddBanner
