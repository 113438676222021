import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { ApiConfig } from '../../api/apiConfig/ApiConfig';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import Header from '../Header'
import Sidebar from '../Sidebar'

const BannerList = () => {
    const [banner, setbanner] = useState([]);
    const [bannerType, setbannerType] = useState();
    const [bannerSeq, setbannerSeq] = useState();
    const [offerType, setofferType] = useState();
    const [offerList, setofferList] = useState();
    const [image, setimage] = useState();
    const [series, setseries] = useState();
    const [SeriesListName, setSeriesListName] = useState();
    const [match, setmatch] = useState();
    const [matchListName, setmatchListName] = useState();
    const [userId, setuserId] = useState();

    let seriesName = (series ? series.split('+')?.[1] : null);
    let seriesId = (series ? series.split('+')?.[0] : null);


    const handleEditDetails = (data) => {
        setuserId(data?._id)
        setbannerType(data?.bannerType)
        setbannerSeq(data?.sequence)
        setofferType(data?.Offer)
        setmatch(data?.MatchType)
        setseries(data?.SeriesList)
    };

    useEffect(() => {
        handleBanner();
        HandleSeriesName();
        getOfferlist();
    }, []);

   

      
    useEffect(() => {
        if (series) {
            HandleMatchName(seriesId)
        }
    }, [series]);


    const HandleSeriesName = async () => {
        LoaderHelper?.loaderStatus(true);
        await AuthService.SeriesName().then((result) => {
            if (result?.success) {
                LoaderHelper?.loaderStatus(false);
                try {
                    setSeriesListName(result?.data[0])
                } catch {
                }
            } else {
                LoaderHelper?.loaderStatus(false);
                console.log('Something went wrong');
            }
        })
    };

    const HandleMatchName = async (id) => {
        await AuthService.MatchesSeries(id).then((result) => {
            if (result?.success) {
                try {
                    setmatchListName(result?.data?.[0])
                } catch {
                }
            } else {
                console.log('Something went wrong');
            }
        })
    };
    const getOfferlist = async () => {
        await AuthService.getOfferlist().then((result) => {
            if (result?.success) {
                try {
                    setofferList(result?.data)
                } catch {
                }
            } else {
                console.log('Something went wrong');
            }
        })
    };
    const handleBanner = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.ViewBanner().then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    setbanner(result?.data?.reverse())
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
                console.log('Something went wrong');
            }
        })
    };

    const deleteBanner = async (id) => {
        await AuthService.DltBnrbyId(id).then((result) => {
            if (result?.success) {
                try {
                    handleBanner()
                } catch {

                }
            } else {
                alertErrorMessage(result?.message)
            }
        })
    };

    const Bannerstatus = async (id, status) => {
        await AuthService.SetBannerStatus(id, status).then((result) => {
            if (result?.success) {
                try {
                    handleBanner()
                } catch {
                }
            } else {

                console.log('Something went wrong');
            }
        })
    };

    const handleInput = (e) => {
        switch (e.target.name) {
            case 'banner_type':
                setbannerType(e.target.value);
                setbannerSeq('');
                setseries('');
                setmatch('');
                setofferType('')
                setimage()
                break;
            case 'image':
                setimage(e.target.files[0])
                break;
            case 'BannerSequence':
                setbannerSeq(e.target.value)
                break;
            case 'Offer':
                setofferType(e.target.value)
                break;
            case 'Series':
                setseries(e.target.value)
                break;
            case 'Match':
                setmatch(e.target.value)
                break;

            default:
                break;
        }
    };


    const editBanner = async (userId, bannerType, image, bannerSeq, offerType, seriesName, match) => {

        var formData = new FormData();
        formData.append('_id', userId);
        formData.append('bannerType', bannerType);
        formData.append('bannerPath', image);
        formData.append('sequence', bannerSeq);
        formData.append('Offer', offerType);
        formData.append('SeriesList', seriesName);
        formData.append('MatchType', match);

        await AuthService.EditBanner(formData).then((result) => {
            if (result?.message === "Banner Edited successfully") {
                try {
                    handleBanner()
                    alertSuccessMessage('Banner Edited Successfully')
                } catch {
                    alertErrorMessage(result?.message)
                }
            } else {
                alertErrorMessage(result?.message)
            }
        })
    }
    /* custom pagination */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = Math.ceil(banner.length / itemsPerPage);
    const currentItems = banner.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // custom paginatio ends
    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-image-line"></i></div>
                                            Banner List
                                        </h1>
                                        {/* <!-- <div className="page-header-subtitle">Example dashboard overview and content summary</div> --> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content--> */}
                    {/* <!-- Example Colored Cards for Dashboard Demo--> */}
                    <div className="container-xl px-4 mt-n10">

                        <div className="card mb-4">

                            <div className="card-body">

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Banner Type</th>
                                            <th>sequence</th>
                                            <th>Image</th>
                                            <th>MatchType</th>
                                            <th>OfferType</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Banner Type</th>
                                            <th>Image</th>
                                            <th>Status</th>
                                            <th>Actions</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {currentItems ? currentItems?.map((data, index) => {
                                            let objIndex;
                                            objIndex = banner.findIndex(check => check?._id == data._id);
                                            return (
                                                <tr>
                                                    <td>{objIndex + 1}</td>
                                                    <td>{data?.bannerType}</td>
                                                    <td>{data?.sequence}</td>

                                                    <td className="text-start"> <img src={`${ApiConfig?.appUrl + 'uploads/' + data?.bannerPath}`} hight="150px" width="60px" alt="" /> </td>
                                                    <td>{data?.MatchType}</td>
                                                    <td>{data?.Offer}</td>
                                                    {data?.status === 'Active' ? <td><button type="btn" className="btn badge bg-success text-white rounded-pill" o onClick={() => { Bannerstatus(data?._id, 'Inactive') }}>{data?.status}</button></td> : <td><button type="btn" className="btn badge bg-danger text-white rounded-pill" onClick={() => { Bannerstatus(data?._id, 'Active') }}  >{data?.status}</button></td>}
                                                    <td>
                                                        <div className="table_action_group">
                                                            <button type="btn" className="btn badge bg-success text-white rounded-pill" onClick={() => { handleEditDetails(data) }}
                                                                data-bs-toggle="modal" data-bs-target="#editBanner"                                   >Edit</button>
                                                            {/* <button type="btn" className="btn badge bg-primary text-white rounded-pill">View</button> */}
                                                            <button type="btn" className="btn badge bg-danger text-white rounded-pill" onClick={() => { deleteBanner(data?._id) }}>Delete</button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }) : ''}

                                    </tbody>
                                </table>
                                <ReactPaginate
                                    pageCount={pageCount}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    onPageChange={handlePageChange}
                                    containerClassName={'customPagination'}
                                    activeClassName={'active'}
                                />
                            </div>
                        </div>
                    </div>




                </main>
            </div>

            <div className="modal" id="editBanner" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">Edit User Details</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">

                            <form enctype="multipart/form-data" method="post" acceptCharset="utf-8" validaterset="validaterset" id="offerForm" action="">

                                <div className="card-body ">
                                    <div className="row">
                                        <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                            <div className="input select required">
                                                <label for="banner-type">Banner type </label>
                                                <select name="banner_type" className="form-control form-select bannerType" id="banner-type" onChange={handleInput} value={bannerType}>
                                                    <option value="" selected disabled>Select Type</option>
                                                    <option value="MatchType">Match Type</option>
                                                    <option value="InviteType">Invite Type</option>
                                                    <option value="OfferType">Offer Type</option>
                                                    <option value="SocialType">Social Type</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                            <div className="input select">
                                                <label for="image">Image </label>
                                                <input type="file" name="image" id="image" className="form-control" accept="image/*" onChange={handleInput} />
                                            </div>
                                        </div>
                                        <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                            <div className="input select">
                                                <label for="BannerSequence">BannerSequence </label>
                                                <input type="text" name="BannerSequence"
                                                    id="BannerSequence" className="form-control"
                                                    placeholder='BannerSequence ' value={bannerSeq} onChange={handleInput} />
                                            </div>
                                        </div>

                                        {bannerType === 'OfferType' ? <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                            <div className="input select required">
                                                <label for="Offer">Offer type</label>
                                                <select name="Offer" className="form-control form-select bannerType" id="Offer" onChange={handleInput} value={offerType}>
                                                    <option value="" selected disabled>offer Type</option>

                                                    {offerList ? offerList?.map((data) => {
                                                        return (

                                                            <option value={data?.CouponCode}>Coupen : {data?.CouponCode}</option>
                                                        )

                                                    }) : <option value="">No data found</option>}

                                                </select>
                                            </div>
                                        </div> : ''}
                                  
                                        {bannerType === 'MatchType' ? <>  <div className="row">  <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                            <div className="input select required">
                                                <label for="Series">Series </label>
                                                <select name="Series" className="form-control form-select bannerType" id="Series" onChange={handleInput} >
                                                    <option value={!seriesId ? series : seriesName} selected disabled>{!seriesName ? series : seriesName} </option>
                                                    {SeriesListName ? SeriesListName?.map((data) => {

                                                        return (

                                                            <option value={data?.cid + '+' + data?.SeriesName}>{data?.SeriesName}</option>
                                                        )
                                                    }) : <option value="">No data found</option>}

                                                </select>
                                            </div>
                                        </div>
                                            <div className=" col-xs-6 col-sm-6 col-md-6 form-group">
                                                <div className="input select required">
                                                    <label for="Match">Match </label>
                                                    <select name="Match" className="form-control form-select bannerType" id="Match" onChange={handleInput} value={match}>
                                                        <option value={match} selected disabled>{match}</option>
                                                        {matchListName ? matchListName?.map((data) => {
                                                            return (
                                                                <option value={data?.title}>{data?.title}</option>
                                                            )
                                                        }) : <option value="">No Data Found</option>}


                                                    </select>
                                                </div>
                                            </div></div> </> : ''}



                                        <div className="card-footer">
                                            <button data-bs-dismiss="modal" aria-label="Close" type="button" className="btn btn-primary submit px-5" onClick={() => { editBanner(userId, bannerType, image, bannerSeq, offerType, !seriesName ? series : seriesName, match) }}>Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BannerList
