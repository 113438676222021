

import React, { useEffect, useState } from 'react'
import AuthService from '../../api/services/HomeService';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import { alertErrorMessage, alertSuccessMessage } from '../../Components/CustomAlertMessage';
import Moment from 'react-moment';

const WithdrawConfirmed = () => {
    const [withdrawalList, setWithdrawalList] = useState([]);
    const [withdrawalList2, setWithdrawalList2] = useState([]);

    const getWithdrawList = async (type) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.getWithdrawal(type)
            setWithdrawalList(result?.data)
            setWithdrawalList2(result?.data)
            LoaderHelper.loaderStatus(false);
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error.message);
        }
    };

    const handleWithdStatus = async (id, status) => {
        LoaderHelper.loaderStatus(true);
        try {
            const result = await AuthService.approveWithdrawal(id, status)
            alertSuccessMessage(result?.message)
            LoaderHelper.loaderStatus(false);
            getWithdrawList(1)
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error.message);
        }
    };

    function searchFilter(userInput) {
        const keysToSearch = ["user_id", "_id"];
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = withdrawalList2.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setWithdrawalList(matchingObjects);
    };


    useEffect(() => {
        getWithdrawList(1)
    }, []);


    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-wallet-3-line"></i></div>
                                            Withdraw List (Confirmed)
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className="card-body">
                                <div className=" filter_bar mb-4 mb-md-5">
                                    <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form " action="/admin/users/referraldata">
                                        <div className="row gx-2 align-items-center">
                                            <div className="form-group  mb-0 col-6">
                                                <div className="input text">
                                                    <input type="tel" name="phone" className="form-control" placeholder="User Id, Withdrawal ID" id="search" onChange={(e) => searchFilter(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Requested Date</th>
                                            <th>User ID / Withdrawal ID</th>
                                            <th>User name</th>
                                            <th>Phone</th>
                                            <th>Account Number</th>
                                            <th>IFSC</th>
                                            <th>Amount</th>
                                            <th>TDS Deduct</th>
                                            <th className="last_td">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {withdrawalList?.map((data, index) => {
                                            return (
                                                <tr key={data?._id}>
                                                    <td>{index + 1}</td>
                                                    <td><Moment date={data?.createdAt} format="DD/MM/YY hh:mm" /></td>
                                                    <td>User ID: {data?.user_id}<hr /> Withdrawal ID: {data?._id}</td>
                                                    <td>{data?.UserData?.username}</td>
                                                    <td>{data?.UserData?.mobile_number}</td>
                                                    <td>{data?.accountnumber}</td>
                                                    <td>{data?.ifsc}</td>
                                                    <td>{data?.amount}</td>
                                                    <td>{data?.tdsdeducted}</td>
                                                    <td>
                                                        <div className="table_action_group">
                                                            <button type="btn" className="btn badge bg-primary text-white rounded-pill"><a href={`/WithdrawDetails/${data?.user_id}/WithdrawConfirmed`} className='text-light'> View All</a></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>




                </main>
            </div>


            <div className="modal" id="editwallrtlmodal" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: 'none' }} aria-hidden="true">
                <div className="modal-dialog  alert_modal modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">Update Wallet</h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">

                            <div className="form-group row align-items-center   mb-1">
                                <div className="col-6">
                                    <label className="small  me-2">User Mobile :</label>
                                </div>
                                <div className="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>
                            <div className="form-group row align-items-center   mb-1">
                                <div className="col-6">
                                    <label className="small  me-2">User Amount :</label>
                                </div>
                                <div className="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>
                            <div className="form-group row align-items-center  mb-1">
                                <div className="col-6">
                                    <label className="small  me-2">Wallet Type :</label>
                                </div>
                                <div className="col-6">
                                    <strong>----</strong>
                                </div>
                            </div>

                            <br />


                            <form>
                                <div className="form-group  mb-3 position-relative ">
                                    <label className="small mb-1"> Amount </label>
                                    <input className="form-control  input-copy" type="text" placeholder="Enter Amount" value="" />
                                </div>
                                <div className="form-group  mb-3 position-relative ">
                                    {/* <!-- <label className="small mb-1"> Sponcer Username </label> --> */}
                                    <select name="up_type" id="up_type" className="form-control form-select"><option value="1">Add</option><option value="2">Deduct</option></select>
                                </div>
                                <div className="form-group  position-relative mt-4">
                                    <button className="btn btn-primary   btn-block w-100" type="button">Add Referal</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default WithdrawConfirmed

