
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage } from '../../Components/CustomAlertMessage';
import { $ } from 'react-jquery-plugin';


const ContestDetails = () => {
    const navigate = useNavigate()
    const [showPage, setshowPage] = useState(true);
    const { id, matchType } = useParams()
    const [contestDeatils, setContestDeatils] = useState([]);
    const [eachContestDetails, seteachContestDetails] = useState();
    const [joinedPlayersDetails, setJoinedPlayersDetails] = useState([]);
    const [playersList, setPlayersList] = useState([]);

    let contestCategory = []
    let uniqueContestCategory;

    for (let i = 0; i < contestDeatils?.length; i++) {
        contestCategory.push(contestDeatils[i]?.contest_category_details?.categoryName)
    }
    uniqueContestCategory = [...new Set(contestCategory)];

    const getAdconMtch = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.getAdconMtch(id)
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setContestDeatils(result?.data)
            }
        } catch (error) {
            alertErrorMessage(error.message);
        }
    };

    const getContestDetails = async (contestId) => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.getContestDetails(id, contestId)
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                const sortedData = [...result?.userJoinDetails].sort((a, b) => b.team_points - a.team_points);
                setJoinedPlayersDetails(sortedData)
            }
        } catch (error) {
            alertErrorMessage(error.message);
        }
    };

    useEffect(() => {
        getAdconMtch()
    }, [id]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });


    }, []);

    const PerContestDetails = async (data, otherData) => {
        getContestDetails(otherData?.contest_category_id)
        seteachContestDetails({ ...data, ...otherData })
        setshowPage(!showPage);
    };

    const showPlayers = (data) => {
        setPlayersList(data)
        $('#playersList').modal('show');
    }

    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-football-line"></i></div>
                                            Details For Added Contest
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content--> */}
                    <div className="container-xl px-4 mt-n10 " style={{ display: showPage ? '' : 'none' }}>
                        <div className="card mb-4">
                            <div className='card-header py-2 d-flex align-items-center justify-content-between' >
                                <div>
                                    <Link className='btn btn-icon' to='/ScheduleContest'  > <i className="ri-arrow-left-line"></i> </Link> Back
                                </div>
                            </div>
                            <div className="card-body">
                                <table id=" " className='dataTable-table'>
                                    <tbody>
                                        {uniqueContestCategory ? uniqueContestCategory?.map((data1) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td colspan="8" style={{ backgroundColor: 'rgb(204, 214, 245)' }}>
                                                            <div className='vs_team align-items-center' >
                                                                <h4 className='mb-0'>{data1}</h4>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    < tr >
                                                        <th>Winning Amount</th>
                                                        <th>Contest Size</th>
                                                        <th>Contest Type</th>
                                                        <th>Action</th>
                                                    </tr>

                                                    {contestDeatils ? contestDeatils?.map((data, index) => {
                                                        let otherData = data
                                                        data = data?.contest_category_details
                                                        return (
                                                            data1 === data?.categoryName ?
                                                                <tr>
                                                                    <td>{data?.WinningAmount}</td>
                                                                    <td>{data?.Contestsize}</td>
                                                                    <td>{data?.ContestType}</td>
                                                                    <td>
                                                                        <button className=" btn btn-sm btn-primary " type='button' title="View User" onClick={() => { PerContestDetails(data, otherData) }}> View </button>
                                                                    </td>
                                                                </tr> : ''
                                                        )
                                                    }) : <h4>No Data Found</h4>}
                                                </>
                                            )
                                        }) : <h4>No Data Found</h4>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    {/* Contest View Pgae */}
                    <div className="container-xl px-4 mt-n10 " style={{ display: showPage ? 'none' : '' }}>
                        <div className="card mb-4">
                            <div className='card-header py-2 d-flex align-items-center justify-content-between' >
                                <div>
                                    <button className='btn btn-icon' type='button' onClick={() => { setshowPage(!showPage) }} > <i className="ri-arrow-left-line" ></i> </button> Back
                                </div>
                            </div>
                            <div className="card-body">
                                {matchType !== "Completed" &&
                                    <div className="row mb-3">
                                        <div className="col-10 ">
                                        </div>
                                        <div className="col-2 ">
                                            <button onClick={() => navigate(`/AddContest/${id}/${eachContestDetails?.contest_category_details?._id}`)} className=" btn btn-sm btn-primary w-100 " type='button' title="View User"> Edit </button>
                                        </div>
                                    </div>}
                                <div className="card-deck row mb-4">
                                    <div className='col-md-6' >
                                        <div className="card card-border">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-6">Category:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.categoryName}</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Winning Prize:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.WinningAmount} INR</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Max Team Size:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.Contestsize}</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Winner Team Size:</div>
                                                    <div className="col-sm-6">
                                                        <strong>N/A</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Entry Fee:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.EnteryFee} INR</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Contest Type:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.ContestType}</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Entery Type:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.EnteryType}</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Usable Bonus Percantage:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.UsableBonusPercantage}</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Join With Mulitple Team:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.JoinWithMULT?.toString()}</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Auto CreateTeam:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.AutoCreate?.toString()}</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Confirmed Win:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.ConfirmedWin?.toString()}</strong>
                                                    </div>
                                                </div>
                                                <hr />
                                                {eachContestDetails?.Rankdata ? eachContestDetails?.Rankdata?.map((data) =>
                                                    <div className="row">
                                                        <div className="col-sm-6">{data?.StartRank} Rank - {data?.EndRank} Rank </div>
                                                        <div className="col-sm-6">
                                                            <strong>Each {data?.Price}rs.</strong>
                                                        </div>
                                                    </div>
                                                ) : ''}

                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6' >
                                        <div className="card card-border">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-sm-6">Entries Left:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.Contestsize - eachContestDetails?.joined}</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Teams Joined:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.joined?.toString()}</strong>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">Contest Status:</div>
                                                    <div className="col-sm-6">
                                                        <strong>{eachContestDetails?.status}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <table className='dataTable-table table-responsive ' >
                                    <tbody>
                                        <tr>
                                            <td colspan="8" >
                                                <div className='vs_team align-items-center' >
                                                    <h4 className='mb-0' >Joined Team</h4>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>Rank </th>
                                            <th>User Id </th>
                                            <th>Created By </th>
                                            <th>Mobile</th>
                                            <th>Team Name</th>
                                            <th>Points</th>
                                            <th>Action</th>
                                        </tr>
                                        {joinedPlayersDetails?.length === 0 ?
                                            <tr>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                                <td>--</td>
                                            </tr>
                                            :
                                            joinedPlayersDetails?.map((data, index) =>
                                                <tr>
                                                    <td>{index+1}</td>
                                                    <td>{data?.team_details?.user_id}</td>
                                                    <td>{data?.username}</td>
                                                    <td>{data?.mobile_number}</td>
                                                    <td>{data?.team_details?.name}</td>
                                                    <td>{data?.team_details?.total_points}</td>
                                                    <td><button className=" btn btn-sm btn-primary w-100 " type='button' onClick={() => showPlayers(data?.team_details?.players)}> View Team </button></td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

            {/* Contest Details */}
            <div className="modal" id="playersList" tabIndex="-1" aria-labelledby="editwallrtlmodalTitle" style={{ display: "none" }} aria-hidden="true"      >
                <div className="modal-dialog  alert_modal modal-lg modal-dialog-centered" role="document"        >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="editwallrtlmodalTitle">
                                Players List
                            </h5>
                            <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                            <hr />
                        </div>
                        <div className="modal-body">
                            <table className='dataTable-table table-responsive ' >
                                <tbody>
                                    <tr>
                                        <th>Serial No. </th>
                                        <th>Name </th>
                                        <th>Points</th>
                                        <th>Role</th>
                                        <th>Rating</th>
                                        <th>Country</th>
                                    </tr>
                                    {playersList?.length === 0 ?
                                        <tr>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                            <td>--</td>
                                        </tr>
                                        :
                                        playersList?.map((data, index) => <tr>
                                            <td>{index + 1}</td>
                                            <td>{data?.first_name}{data?.caption ? " (C)" : data?.vice_caption ? " (VC)" : ""}  </td>
                                            <td>{data?.points}</td>
                                            <td>{data?.playing_role}</td>
                                            <td>{data?.fantasy_player_rating}</td>
                                            <td>  <img src={data?.primary_team?.logo_url} width="30" height="30" alt="" className='mx-2' />{data?.nationality}</td>
                                        </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default ContestDetails
