import React, { useContext, useEffect, useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import { useNavigate } from 'react-router-dom';
import { context } from '../../Components/Context/ContextProvider';
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
const ScheduleContest = () => {
    const [upcomingMatch, setupcomingMatch] = useState([]);
    const [resultMatch, setresultMatch] = useState([]);
    const [showMainPage, setshowMainPage] = useState(true);
    const [liveMatch, setliveMatch] = useState([]);
    const [contestFields, setcontestFields] = useState();
    const [macthId, setmacthId] = useState();
    const [contestData, setcontestData] = useState([]);
    const [addedContest, setAddedContest] = useState([]);
    const navigate = useNavigate();

    let contestCategory = []
    let uniqueContestCategory;
    useEffect(() => {
        UpcmngMtchedt()
        ContestFields()
    }, []);


    for (let i = 0; i < contestFields?.length; i++) {
        contestCategory.push(contestFields[i]?.categoryName)
    }
    uniqueContestCategory = [...new Set(contestCategory)];


    const UpcmngMtchedt = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.viewMatchesCont().then((result) => {
            if (result?.success) {
                try {
                    LoaderHelper.loaderStatus(false);
                    setupcomingMatch(result?.data)
                } catch {
                    LoaderHelper.loaderStatus(false);
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };

    const handleCancelMatch = async (id) => {
        await Swal.fire({
            title: 'Do you want to cancel the match?',
            icon: 'info',
            showDenyButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `No`,
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    LoaderHelper.loaderStatus(true);
                    const result = await AuthService.cancelMatch(id)
                    LoaderHelper.loaderStatus(false);
                    if (result?.success) alertSuccessMessage(result?.message)
                    else alertErrorMessage(result.message)
                } catch (error) { alertErrorMessage(error.message) }
                finally { LoaderHelper.loaderStatus(false); }
            } else if (result.isDenied) {
                Swal.fire('Changes are not saved', '', 'error')
            }
        })
    };

    const ContestFields = async () => {
        await AuthService.ContestFields().then((result) => {
            if (result?.success) {
                try {
                    setcontestFields(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        })
    };

    const GetMatchesData = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.GetMatchesData().then((result) => {
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                try {
                    setliveMatch(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        })
    };

    const ResultMtchedt = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.ResultMtchedt().then((result) => {
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                try {
                    setresultMatch(result?.data)
                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                alertErrorMessage(result.message);
            }
        })
    };

    const showContest = async (data) => {
        const contestCategoryIds = data?.contest_details[0]?.data.map((contest) => contest.contest_category_id);
        setAddedContest(contestCategoryIds ? contestCategoryIds : [])
        setmacthId(data?._id)
        setshowMainPage(false)
        window.scrollTo({ top: 0, behavior: 'smooth' });


    };

    const handleSelectContest = (e, data) => {
        if (e.target.checked) {
            setcontestData((prev) => [...prev, { id: data?._id, contest_category_id: data?._id, contest_type: data?.categoryName, winning_amount: data?.WinningAmount }])
        } else {
            let filterd = contestData.filter((item) => {
                return item.id !== data?._id
            })
            setcontestData(filterd)
        }

    };

    const AddContest = async (id, data) => {
        LoaderHelper.loaderStatus(true);
        await AuthService.UpcomingCont(id, data).then((result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage('Contest Added Successfully')
                    deSelect()
                    setshowMainPage(true)
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                    UpcmngMtchedt()

                } catch {
                    alertErrorMessage(result.message);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage(result.message);
            }
        })
    };

    const getAdconMtch = async (id, matchType) => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.getAdconMtch(id)
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                if (result?.data?.length > 0) { navigate(`/ContestDetails/${id}/${matchType}`) }
                else { alertWarningMessage('No Contest Available') }
            };
        }
        catch (error) {
            alertErrorMessage(error.message);
        }
    };

    // Select / DeSelect Function
    let array = []

    const selects = () => {
        array = []
        var ele = document.getElementsByName('selectBox');
        for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == 'checkbox')
                ele[i].checked = true;
        }
        for (var i = 0; i < contestFields.length; i++) {


            array.push({ id: contestFields[i]?._id, contest_category_id: contestFields[i]?._id, contest_type: contestFields[i]?.categoryName, winning_amount: contestFields[i]?.WinningAmount })

        }
        setcontestData(array);
    };

    const deSelect = () => {
        array = []
        var ele = document.getElementsByName('selectBox');
        for (var i = 0; i < ele.length; i++) {
            if (ele[i].type == 'checkbox')
                ele[i].checked = false;

        }
        setcontestData(array);
    };

    const handleAllSelect = (e) => {
        if (e.target.checked) {
            selects()
        } else {
            deSelect()
        }
    };



    // Scroll Top Func
    const btnScrollToTop = document.querySelector("#btnScrollToTop");
    if (btnScrollToTop) {
        btnScrollToTop.addEventListener("click", e => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
        });
        window.addEventListener('scroll', e => {
            window.scrollY > 100 ? btnScrollToTop.style.display = "block" : btnScrollToTop.style.display = "none";
        });

    }

    /* custom pagination upcoming match */

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);


    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected + 1);
    };

    const pageCount = Math.ceil(upcomingMatch.length / itemsPerPage);
    const currentItems = upcomingMatch.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
    // custom paginatio ends

    /* custom pagination Live match */
    const [currentPage2, setCurrentPage2] = useState(1);
    const [itemsPerPage2, setItemsPerPage2] = useState(5);


    const handlePageChange2 = ({ selected }) => {
        setCurrentPage2(selected + 1);
    };

    const pageCount2 = Math.ceil(liveMatch.length / itemsPerPage);
    const currentItems2 = liveMatch.slice(
        (currentPage2 - 1) * itemsPerPage2,
        currentPage2 * itemsPerPage
    );
    // custom paginatio ends
    /* custom pagination Result match */

    const [currentPage3, setCurrentPage3] = useState(1);
    const [itemsPerPage3, setItemsPerPage3] = useState(5);


    const handlePageChange3 = ({ selected }) => {
        setCurrentPage3(selected + 1);
    };

    const pageCount3 = Math.ceil(resultMatch.length / itemsPerPage);
    const currentItems3 = resultMatch.slice(
        (currentPage3 - 1) * itemsPerPage3,
        currentPage3 * itemsPerPage3
    );
    // custom paginatio ends

    return (
        <>
            <div id="layoutSidenav_content" >
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-football-line"></i></div>
                                            <button id='btnScrollToTop' type='button'  ><i className="ri-arrow-up-line"></i></button>
                                            Schedule Contest
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    {/* <!-- Main page content-->

                        {/* main tabs */}
                    <div className="container-xl px-4 mt-n10" style={{ display: showMainPage ? "" : 'none' }}>
                        <div className="card mb-4">
                            <div className='card-header' >
                                <ul className="nav nav-pills mb-0" id="pills-tab" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-UpComing-tab" data-bs-toggle="pill" data-bs-target="#pills-UpComing" type="button" role="tab" aria-controls="pills-UpComing" aria-selected="true" onClick={UpcmngMtchedt}>UpComing</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-Live-tab" data-bs-toggle="pill" data-bs-target="#pills-Live" type="button" role="tab" aria-controls="pills-Live" aria-selected="false" onClick={GetMatchesData}>Live</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-Result-tab" data-bs-toggle="pill" data-bs-target="#pills-Result" type="button" role="tab" aria-controls="pills-Result" aria-selected="false" onClick={ResultMtchedt}>Result</button>
                                    </li>
                                </ul>
                            </div>
                            <div className="card-body tab-content" id="pills-tabContent">

                                {/* UpComing Tab */}
                                <div className="tab-pane fade show active" id="pills-UpComing" role="tabpanel" aria-labelledby="pills-UpComing-tab">
                                    <table className='dataTable-table' >
                                        <thead>
                                            <tr role="row">
                                                <th className="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >#</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Team 1 vs Team 2</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Series Name</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Date</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Time</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1" >No of Contest</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1" >Add Contest</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"   >  PreSquad </th>
                                            </tr>

                                        </thead>
                                        <tbody>
                                            {currentItems ? currentItems?.map((data, index) => {
                                                let objIndex;
                                                objIndex = upcomingMatch.findIndex(check => check?._id == data._id);
                                                let team = data?.Team1vsTeam2 ? data?.Team1vsTeam2.split('vs') : ''
                                                let date = data?.Date ? data?.Date.split(' ') : ''
                                                return (

                                                    <tr role="row" className="odd">
                                                        <td className="">{objIndex + 1}</td>

                                                        <td>
                                                            <div className='vs_vs' >
                                                                <div className='vs_team' >
                                                                    <img src={data?.PhotoA} width="30" height="30" alt="" />
                                                                    <span>{team[0]}</span>
                                                                </div>
                                                                <div className='vs_bar'>vs</div>
                                                                <div className='vs_team'>
                                                                    <img src={data?.PhotoB} width="30" height="30" alt="" />
                                                                    <span>{team[1]}</span>
                                                                </div>
                                                            </div>
                                                        </td>

                                                        <td>{data?.SeriesName}
                                                        </td>
                                                        <td>{date[0]}</td>
                                                        <td>{date[1]}</td>
                                                        <td>
                                                            <button type='button' className="btn btn-primary btn-sm" onClick={() => { getAdconMtch(data?._id, "Upcoming") }}>{data?.contestCount[0]?.contestCount ? `${data?.contestCount[0]?.contestCount} Contest` : 'No Contest'} </button>
                                                        </td>
                                                        <td>
                                                            <button onClick={() => showContest(data)} type='button' className="btn btn-primary btn-sm"  > Add Contests</button>
                                                        </td>
                                                        <td className="sorting_1">
                                                            <span  >
                                                                <b> {data?.pre_Squad} </b>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''}

                                        </tbody>
                                    </table>
                                    <ReactPaginate
                                        pageCount={pageCount}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange}
                                        containerClassName={'customPagination'}
                                        activeClassName={'active'}
                                    />
                                </div>

                                {/* Live Tab */}
                                <div className="tab-pane fade" id="pills-Live" role="tabpanel" aria-labelledby="pills-Live-tab">
                                    <table id=" " className='dataTable-table' >
                                        <thead>
                                            <tr role="row">
                                                <th className="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >#</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Team 1 vs Team 2</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Series Name</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Date</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Time</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1" >No of Contest</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1" >Action</th>

                                            </tr>

                                        </thead>
                                        <tbody>
                                            {currentItems2 ? currentItems2?.map((data, index) => {
                                                let objIndex;
                                                objIndex = liveMatch.findIndex(check => check?._id == data._id);
                                                let team = data?.Team1vsTeam2 ? data?.Team1vsTeam2.split('vs') : ''
                                                let date = data?.Date ? data?.Date.split(' ') : ''
                                                return (
                                                    <tr role="row" className="odd">
                                                        <td className="">{objIndex + 1}</td>
                                                        <td>
                                                            <div className='vs_vs' >
                                                                <div className='vs_team' >
                                                                    <img src={data?.PhotoA} width="30" height="30" alt=" " style={{ borderRadius: '50%' }} />
                                                                    <span>{team[0]}</span>
                                                                </div>
                                                                <div className='vs_bar'>vs</div>
                                                                <div className='vs_team'>
                                                                    <img src={data?.PhotoB} width="30" height="30" style={{ borderRadius: '50%' }} alt="" />
                                                                    <span>{team[1]}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{data?.SeriesName}</td>
                                                        <td>{date[0]}</td>
                                                        <td>{date[1]}</td>
                                                        <td>
                                                            <button type='button' className="btn btn-primary btn-sm" onClick={() => { getAdconMtch(data?._id, "Live") }} > {data?.contestCount[0]?.contestCount ? `${data?.contestCount[0]?.contestCount} Contest` : 'No Contest'}</button>
                                                        </td>
                                                        <td>
                                                            {data?.isCancel ? <button type='button' className="btn btn-danger btn-sm" disabled={data?.isCancel} > Cancelled</button>:
                                                            <button type='button' className="btn btn-danger btn-sm" disabled={data?.isCancel} onClick={() => { handleCancelMatch(data?._id) }} > Cancel Match</button>}
                                                            
                                                        </td>

                                                    </tr>
                                                )
                                            }) : ''}


                                        </tbody>
                                    </table>
                                    <ReactPaginate
                                        pageCount={pageCount2}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange2}
                                        containerClassName={'customPagination'}
                                        activeClassName={'active'}
                                    />
                                </div>


                                {/* Result Tab */}
                                <div className="tab-pane fade" id="pills-Result" role="tabpanel" aria-labelledby="pills-Result-tab">
                                    <table id=" " className='dataTable-table' >
                                        <thead>
                                            <tr role="row">
                                                <th className="sorting_asc" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >#</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Team 1 vs Team 2</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Series Name</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Date</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1"  >Time</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1" >No of Contest</th>
                                                <th className="sorting" tabIndex="0" aria-controls="example1" rowspan="1" colspan="1" >Action</th>

                                            </tr>

                                        </thead>
                                        <tbody>
                                            {currentItems3 ? currentItems3?.map((data, index) => {
                                                let objIndex;
                                                objIndex = resultMatch.findIndex(check => check?._id == data._id);
                                                let team = data?.Team1vsTeam2 ? data?.Team1vsTeam2.split('vs') : ''
                                                let date = data?.Date ? data?.Date.split(' ') : ''
                                                return (

                                                    <tr role="row" className="odd">
                                                        <td className="">{objIndex + 1}</td>
                                                        <td>
                                                            <div className='vs_vs' >
                                                                <div className='vs_team' >
                                                                    <img src={data?.PhotoA} width="30" height="30" alt=" " style={{ borderRadius: '50%' }} />
                                                                    <span>{team[0]}</span>
                                                                </div>
                                                                <div className='vs_bar'>vs</div>
                                                                <div className='vs_team'>
                                                                    <img src={data?.PhotoB} width="30" height="30" style={{ borderRadius: '50%' }} alt="" />
                                                                    <span>{team[1]}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td>{data?.SeriesName}
                                                        </td>
                                                        <td>{date?.[0]}</td>
                                                        <td>{date?.[1]}</td>
                                                        <td>
                                                            <button type='button' className="btn btn-primary btn-sm" onClick={() => { getAdconMtch(data?._id, "Completed") }} > {data?.contestCount[0]?.contestCount ? `${data?.contestCount[0]?.contestCount} Contest` : 'No Contest'}</button>
                                                        </td>
                                                        <td>
                                                            {data?.isCancel ? <button type='button' className="btn btn-danger btn-sm" disabled={data?.isCancel} > Cancelled</button>:
                                                            <button type='button' className="btn btn-danger btn-sm" disabled={data?.isCancel} onClick={() => { handleCancelMatch(data?._id) }} > Cancel Match</button>}
                                                            
                                                        </td>
                                                    </tr>
                                                )
                                            }) : ''}


                                        </tbody>
                                    </table>
                                    <ReactPaginate
                                        pageCount={pageCount3}
                                        pageRangeDisplayed={3}
                                        marginPagesDisplayed={2}
                                        onPageChange={handlePageChange3}
                                        containerClassName={'customPagination'}
                                        activeClassName={'active'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* add contest tab */}
                    <div className="container-xl px-4 mt-n10" id='nsdt_none' style={{ display: showMainPage ? 'none' : '' }}>

                        <div className="card mb-4">
                            <div className='card-header' >
                                <a className='btn btn-icon' onClick={() => { setshowMainPage(!showMainPage) }}> <i className="ri-arrow-left-line"></i> </a> Add Contest
                            </div>
                            <div className="card-body">


                                <table id=" " className='dataTable-table' >


                                    <tbody>
                                        <tr>
                                            <td colspan="8" >
                                                <div className=" form-check mb-0 align-items-center " >
                                                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => { handleAllSelect(e) }} />
                                                    <label className="form-check-label mb-0" for="flexCheckDefault" >
                                                        Select All
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>

                                        {uniqueContestCategory ? uniqueContestCategory?.map((data1) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td colspan="8" style={{ backgroundColor: 'rgb(204 214 245)' }}>
                                                            <div className='vs_team align-items-center' >
                                                                {/* <img src="http://3.110.208.7/uploads/team_flag/del-w.png" width="45" height="45" alt="Delhi Capitals Women" /> */}
                                                                <h4 style={{
                                                                    color: '#001050',
                                                                    fontWeight: '600'
                                                                }} className='mb-0' >{data1} </h4>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <th>Select</th>
                                                        <th>Winning Amount</th>
                                                        <th>Contest Size </th>
                                                        <th>Entry Fee</th>
                                                        <th>Contest Type</th>
                                                        <th>Entry Type</th>
                                                    </tr>
                                                    {contestFields ? contestFields?.map((data, index) => {

                                                        return (
                                                            data1 === data?.categoryName ? <tr>
                                                                <td className='text-center' >
                                                                    {addedContest?.includes(data?._id) ? <input onChange={(e) => { handleSelectContest(e, data) }} type="checkbox" checked={addedContest?.includes(data?._id)} disabled={addedContest?.includes(data?._id)} className="w-20" width="30" height="30" name='selectBox' /> : <input onChange={(e) => { handleSelectContest(e, data) }} type="checkbox" className="w-20" width="30" height="30" name='selectBox' />}
                                                                </td>
                                                                <td>{data?.WinningAmount}</td>
                                                                <td>{data?.Contestsize}</td>
                                                                <td>{data?.EnteryFee}</td>
                                                                <td>{data?.ContestType}</td>
                                                                <td>{data?.EnteryType}</td>
                                                            </tr> : ''
                                                        )

                                                    }) : ''}
                                                </>
                                            )
                                        }) : ''}





                                    </tbody>
                                </table>


                                <button className='btn btn-primary px-5 ' type='button' disabled={contestData?.length === 0} onClick={() => { AddContest(macthId, contestData) }} > Submit </button>



                            </div>
                        </div>
                    </div>



                </main>
            </div>
        </>
    )
}
export default ScheduleContest
