import Chart from 'chart.js/auto';

function getWeekOfMonth(date) {
    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const diff = date.getDate() + startOfMonth.getDay() - 1;
    return Math.ceil(diff / 7);
}

export async function createChart(data, id, type, label) {
    const userCountPerWeek = [];
    data.forEach(user => {
        const date = new Date(user.createdAt);
        const month = date.toLocaleString('default', { month: 'long' });
        const weekOfMonth = getWeekOfMonth(date);
        const key = `${month} ${weekOfMonth} week`;

        const existingWeek = userCountPerWeek.find(item => item.week === key);
        if (existingWeek) {
            existingWeek.users += 1;
        } else {
            userCountPerWeek.push({ week: key, users: 1 });
        }
    });
    return generateChart(userCountPerWeek, id, type, label)
}

const generateChart = (userCounts, id, type, label) => {
    const userData = userCounts;
    const labels = userData.map(data => data.week);
    const data = userData.map(data => data.users);
    const ctx = document.getElementById(id);
    return new Chart(ctx, {
        type: 'bar',
        data: {
            labels: labels,
            datasets: [{
                label: label,
                data: data,
                backgroundColor: 'rgba(75, 192, 192, 0.7)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
            }],
        },
        options: {
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    title: {
                        display: true,
                        text: `Number of ${type}`,
                        font: {
                            size: 14,
                            weight: 'bold'
                        }
                    },
                    grid: {
                        color: 'rgba(0, 0, 0, 0.1)',
                        borderColor: 'rgba(0, 0, 0, 0.1)',
                        borderDash: [5, 5],
                    },
                },
                x: {
                    title: {
                        display: true,
                        text: 'Week',
                        font: {
                            size: 14,
                            weight: 'bold'
                        }
                    },
                    grid: {
                        display: false,
                    },
                    ticks: {
                        autoSkip: false,
                        maxRotation: 45,
                        padding: 10,
                    }
                },
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        font: {
                            size: 14,
                            weight: 'bold'
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    titleColor: 'white',
                    bodyColor: 'white',
                    titleFont: {
                        size: 14,
                        weight: 'bold'
                    },
                    bodyFont: {
                        size: 14
                    },
                    callbacks: {
                        label: function (context) {
                            return `${type}: ${context.parsed.y}`;
                        },
                        title: function (context) {
                            return `Week ${context[0].label}`;
                        }
                    }
                },
            },
        },
    });
}

