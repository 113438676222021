import React, { useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';
import Header from '../Header'
import Sidebar from '../Sidebar'

const AddContent = () => {
    const [title, settitle] = useState();
    const [content, setcontent] = useState();

    const Content = async (title, content) => {
        if (title && content) {
            await AuthService.Content(title, content).then((result) => {
                if (result?.success) {
                    try {
                        alertSuccessMessage("Content added successfully!!");
                        setcontent('')
                        settitle('')
                    } catch {
                        alertErrorMessage();
                    }
                } else {
                    alertErrorMessage(result?.message);
                }
            })
        }
        else {
            alertWarningMessage('Please fill all the required fields')
        }
    };


    return (
        <>
                <div id="layoutSidenav_content">
                    <main>
                        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                            <div className="container-xl px-4">
                                <div className="page-header-content pt-4">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto mt-4">
                                            <h1 className="page-header-title">
                                                <div className="page-header-icon"><i className="ri-article-line"></i></div>
                                                Add Content Template
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </header>
                        {/* <!-- Main page content--> */}
                        <div className="container-xl px-4 mt-n10">
                            <div className="card mb-4">
                                <div className="card-header">
                                    <h3 className="card-title mb-0">Enter Details</h3> </div>

                                <form method="post" acceptCharset="utf-8" validaterset="validaterset" id="notificationForm" action="">

                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="card-body">
                                                <div className="form-group">
                                                    <div className="input Number">
                                                        <div className="input text required"><label for="title">Title <span className="required">*</span></label><input type="text" name="title" className="form-control" placeholder="Notification Title" required="required" maxLength="100" id="title" value={title} onChange={(e) => { settitle(e.target.value) }} /></div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <div className="input text">
                                                        <div className="form-group">
                                                            <label>Content <span className="required">*</span></label>
                                                            <div className="input textarea required">
                                                                <textarea name="notification" className="form-control" rows="3" required="required" maxLength="255" id="notification" value={content} onChange={(e) => { setcontent(e.target.value) }}></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="button" className="btn btn-primary submit" onClick={() => { Content(title, content) }}>Submit</button>
                                    </div>
                                </form>

                            </div>



                        </div>
                    </main>
                </div>
        </>
    )
}

export default AddContent
