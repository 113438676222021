import React, { useEffect, useState } from 'react'
import LoaderHelper from '../../Components/Loading/LoaderHelper';
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage } from '../../Components/CustomAlertMessage';
import { useParams } from 'react-router-dom';

const TransDetails = () => {
    const { id } = useParams()
    const [transList, setTransList] = useState([]);
    const [transList2, setTransList2] = useState([]);
    const [userDetails, setUserDetails] = useState({});

    const getTransaction = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.peruserDetails(id)
            LoaderHelper.loaderStatus(false);
            if (result?.success) {
                let filteredData = result?.data?.map((item) => { return { ...item, userId: item?.user_done_transaction[0]?._id, userName: item?.user_done_transaction[0]?.username, mobile: item?.user_done_transaction[0]?.mobile_number } })
                setTransList(filteredData?.reverse());
                setTransList2(filteredData);
                setUserDetails(filteredData[0])
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error.message);
        }
    };

    function searchFilter(userInput) {
        const keysToSearch = ["transaction_id"];
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = transList2.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setTransList(matchingObjects);
    };


    useEffect(() => {
        getTransaction()
    }, []);


    return (
        <>

            <div id="layoutSidenav_content">
                <main>
                    <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                        <div className="container-xl px-4">
                            <div className="page-header-content pt-4">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto mt-4">
                                        <h1 className="page-header-title">
                                            <div className="page-header-icon"><i className="ri-arrow-up-down-line"></i></div>
                                            Transaction Details
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="container-xl px-4 mt-n10">
                        <div className="card mb-4">
                            <div className='card-header py-2 d-flex align-items-center justify-content-between' >
                                <div>
                                    <a className='btn btn-icon' href='/Transaction'  > <i className="ri-arrow-left-line"></i> </a> Back
                                </div>
                            </div>
                            <div className="card-body">
                                <div className=" filter_bar mb-4 mb-md-5 ">
                                    <form method="get" acceptCharset="utf-8" validaterset="validaterset" className="form-inline search_form " action="/admin/users/referraldata">
                                        <div className="row gx-2 align-items-center">
                                            <div className="form-group  mb-0 col-5">
                                                <div className="input text">
                                                    <input type="tel" name="phone" className="form-control" placeholder="Transaction Id" id="search" onChange={(e) => searchFilter(e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                    <div className='row mt-2'>
                                        <div >User Id : <strong>{id}</strong> </div>
                                        <div>User Mobile : <strong>{userDetails?.mobile}</strong> </div>
                                        <div>User Name : <strong>{userDetails?.userName}</strong> </div>
                                    </div>
                                </div>

                                <table id="datatablesSimple">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Amount</th>
                                            <th>Transaction Type</th>
                                            <th>Transaction Status</th>
                                            <th>Transaction ID</th>
                                        </tr>
                                    </thead>
                                    <tfoot>
                                        <tr>
                                            <th>#</th>
                                            <th>Amount</th>
                                            <th>Transaction Type</th>
                                            <th>Transaction Status</th>
                                            <th>Transaction ID</th>
                                        </tr>
                                    </tfoot>
                                    <tbody>
                                        {transList?.map((item, index) => {
                                            return (
                                                <tr key={item?._id}>
                                                    <td>{index + 1}</td>
                                                    <td>{item?.amount}</td>
                                                    <td>{item?.transaction_type}</td>
                                                    <td>{item?.transaction_status}</td>
                                                    <td>{item?.transaction_id}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </main>
            </div>

        </>
    )
}

export default TransDetails
