import React, { useState } from 'react'
import AuthService from '../../api/services/HomeService';
import { alertErrorMessage, alertSuccessMessage, alertWarningMessage } from '../../Components/CustomAlertMessage';

const AddSubAdmin = () => {
    const [fName, setfName] = useState('');
    const [lName, setlName] = useState('');
    const [email, setemail] = useState('');
    const [number, setnumber] = useState('');
    const [password, setpassword] = useState('');
    const [dob, setdob] = useState();
    const [gender, setgender] = useState('Male');
    const [permission, setpermission] = useState([]);
    const [profilePic, setprofilePic] = useState();

    const addSubadmin = async (fName, lName, email, number, password, dob, gender, permission, profilePic) => {
        var formData = new FormData();
        formData.append('FirstName', fName);
        formData.append('LastName', lName);
        formData.append('Email', email);
        formData.append('MobileNo', number);
        formData.append('password', password);
        formData.append('dob', dob);
        formData.append('Gender', gender);
        formData.append('permissions', permission);
        formData.append('imagepath', profilePic);
        formData.append('userType', 1);

        if (fName && lName && email && number && password && dob && gender && permission && profilePic) {
            await AuthService.addSubadmin(formData).then((result) => {
                if (result?.success) {
                    try {
                        alertSuccessMessage(result.message);
                        window.location.reload()
                    } catch {
                        alertErrorMessage(result?.message)
                    }
                } else {
                    alertErrorMessage(result?.message)
                    console.log('Something went wrong');
                }
            })
        } else {
            alertWarningMessage('Please fill all mandetory fields')
        }
    };

    const filterPermission = (data) => {
        let filterd = permission?.filter((data1) => {
            return data1 !== data
        })
        setpermission(filterd)
    };

    const handlePermission = (e) => {
        if (e.target.checked) {
            setpermission([...permission, e.target.value])
        } else {
            filterPermission(e.target.value)
        }
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i data-feather="users"></i></div>
                                        Add new sub admin
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">
                            <h3 className="card-title mb-0">Enter Details</h3> </div>
                        <form encType="multipart/form-data" method="post" acceptCharset="utf-8" validaterset="validaterset" id="subAdminForm" action="/admin/sub-admins/add">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input text">
                                                <label htmlFor="first-name">First Name <span className="required">*</span></label>
                                                <input type="text" name="first_name" maxLength="30" className="form-control" placeholder="First Name" max="20" id="first-name"
                                                    value={fName} onChange={(e) => { setfName(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input text">
                                                <label htmlFor="last-name">Last Name <span className="required">*</span></label>
                                                <input type="text" name="last_name" maxLength="30" className="form-control" placeholder="Last Name " max="20" id="last-name" value={lName} onChange={(e) => { setlName(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input email">
                                                <label htmlFor="email">Email <span className="required">*</span></label>
                                                <input type="email" name="email" maxLength="50" className="form-control" placeholder="E-Mail Address" id="email" value={email} onChange={(e) => { setemail(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input tel">
                                                <label htmlFor="phone">Phone Number <span className="required">*</span></label>
                                                <input type="number" name="phone" maxLength="15" className="form-control" placeholder="Phone Number" id="phone" value={number} onChange={(e) => { setnumber(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input password">
                                                <label htmlFor="password">Password <span className="required">*</span></label>
                                                <input type="password" name="password" className="form-control" placeholder="Password" id="password" value={password} onChange={(e) => { setpassword(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input text">
                                                <label htmlFor="date-of-bith">Date of Birth <span className="required">*</span></label>
                                                <input type="date" name="date_of_bith" className="form-control DOB" placeholder="Date of Birth" maxLength="50" id="date-of-bith" value={dob} onChange={(e) => { setdob(e.target.value) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input select">
                                                <label htmlFor="gender">Gender <span className="required">*</span></label>
                                                <select name="gender" className="form-control form-select" id="gender" value={gender} onChange={(e) => { setgender(e.target.value) }} >
                                                    <option value="Male" >Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="profile-picture">Profile Picture <span className="required">*</span></label>
                                            <div className="input file">
                                                <input type="file" name="image" className="form-control" accept="image/*" id="image" onChange={(e) => { setprofilePic(e.target.files[0]) }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="form-group">
                                            <div className="input select">
                                                <label htmlFor="gender">Permission <span className="required">*</span> </label>
                                                <br />
                                                <div className='d-flex mx-2'>
                                                    <div>
                                                        <input type="checkbox" value="Users" id='Users' onChange={handlePermission} /><label for="Users"> Users</label><br />
                                                        <input type="checkbox" value="KYCRequest" id='KYCRequest' onChange={handlePermission} /> <label for="KYCRequest"> KYC Request</label><br />
                                                        <input type="checkbox" value="CategoryManager" id='CategoryManager' onChange={handlePermission} /> <label for="CategoryManager"> Category Manager</label><br />
                                                        <input type="checkbox" value="ContestManager" id='ContestManager' onChange={handlePermission} /><label for="ContestManager"> Contest Manager</label><br />
                                                        <input type="checkbox" value="CricketModules" id='CricketModules' onChange={handlePermission} /><label for="CricketModules"> Cricket Modules</label><br />
                                                        <input type="checkbox" value="PaymentMethod" id='PaymentMethod' onChange={handlePermission} /><label for="PaymentMethod"> Payment Method</label><br />
                                                        <input type="checkbox" value="TDS" id='TDS' onChange={handlePermission} /><label for="TDS"> TDS</label><br />
                                                        <input type="checkbox" value="Transaction" id='Transaction' onChange={handlePermission} /><label for="Transaction"> Transaction</label><br />
                                                    </div>
                                                    <div className=' mx-5'>
                                                        <input type="checkbox" value="WalletManager" id='WalletManager' onChange={handlePermission} /><label for="WalletManager"> Wallet Manager</label><br />
                                                        <input type="checkbox" value="Earning" id='Earning' onChange={handlePermission} /><label for="Earning"> Earning</label><br />
                                                        <input type="checkbox" value="PaymentOffers" id='PaymentOffers' onChange={handlePermission} /><label for="PaymentOffers"> Payment Offers</label><br />
                                                        <input type="checkbox" value="BannerManager" id='BannerManager' onChange={handlePermission} /><label for="BannerManager"> Banner Manager</label><br />
                                                        <input type="checkbox" value="WithdrawPending" id='WithdrawPending' onChange={handlePermission} /><label for="WithdrawPending"> Withdraw (Pending)</label><br />
                                                        <input type="checkbox" value="WithdrawConfirmed" id='WithdrawConfirmed' onChange={handlePermission} /><label for="WithdrawConfirmed"> Withdraw (Confirmed)</label><br />
                                                        <input type="checkbox" value="Notification" id='Notification' onChange={handlePermission} /><label for="Notification"> Notification</label><br />
                                                        <input type="checkbox" value="ContentManager" id='ContentManager' onChange={handlePermission} /><label for="ContentManager"> Content Manager</label><br />
                                                        <input type="checkbox" value="Settings" id='Settings' onChange={handlePermission} /><label for="Settings"> Settings</label><br />
                                                        <input type="checkbox" value="EmailTemplates" id='EmailTemplates' onChange={handlePermission} /><label for="EmailTemplates"> Email Templates</label><br />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <button type="button" className="btn btn-primary submit px-5" onClick={() => { addSubadmin(fName, lName, email, number, password, dob, gender, permission, profilePic) }}>Submit</button>
                            </div>
                        </form>
                    </div>
                </div >
            </main >
        </div >
    )
}

export default AddSubAdmin
